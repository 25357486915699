import { useState, useEffect, useContext } from "react";
import "./ResourceManagement.css";
import { CTX } from "../CMContext";
import { useMutation } from "@apollo/client";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Search, Cancel } from "@mui/icons-material";
import SEARCH_RESOURCES from "../../../mutations/searchResources";
import Picker from "./Picker";
import ResourceList from "./ResourceList";

interface IResource {
  title: string;
  id: string;
  source: string;
  homonyms: [string];
  author: string;
  deleted: boolean;
  feeling: boolean;
  noun: boolean;
  stopword: boolean;
  stopwordAlways: boolean;
  stopwordButAlsoName: boolean;
  word: boolean;
  genderedPronoun: boolean;
  malePronoun: boolean;
  femalePronoun: boolean;
  genderNeutralPronoun: boolean;
  placeNameSuffix: boolean;
  organizationNameSuffix: boolean;
}

const ResourceManagement = () => {
  const {
    languages,
    clusters,
    selectedLanguage,
    setSelectedLanguage,
    selectedCluster,
    setSelectedCluster,
  }: any = useContext(CTX);
  const [resourceList, setResourceList] = useState<IResource[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);
  const [searchResources, { loading, error }] = useMutation(SEARCH_RESOURCES);

  useEffect(() => {
    clearTimeouts(searchTagTimeouts);
    setResourceList([]);
    //##### CLICKS TRIGGERS THE SEARCH AFTER CHANGING CLUSTER OR LANGAUGE #####
    const inputField = document.getElementById("resourceSearchInput");
    if (inputField) {
      inputField.dispatchEvent(new Event("input", { bubbles: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCluster, selectedLanguage]);

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  const SearchResourceInput = (
    <TextField
      autoFocus
      autoComplete="off"
      id="resourceSearchInput"
      value={searchString}
      type="text"
      label={
        <span>
          <Search sx={{ mr: 0.5 }} />
          Search resources
        </span>
      }
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      size="small"
      fullWidth
      FormHelperTextProps={{
        sx: { position: "absolute", bottom: 0, zIndex: 3 },
      }}
      InputProps={{
        endAdornment: searchString ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                setSearchString("");
                $("#resourceSearchInput") && $("#resourceSearchInput").focus();
              }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onInput={(e: any) => {
        clearTimeouts(searchTagTimeouts);
        let inputString = e.target.value;
        setSearchString(inputString);
        if (!inputString) {
          setResourceList([]);
          return;
        }
        let searchTagTimeout = setTimeout(() => {
          setSearchTagTimeouts([]);
          if (!inputString) {
            setResourceList([]);
          } else {
            searchResources({
              variables: {
                freetext: inputString.trim(),
                cluster: selectedCluster,
                language: selectedLanguage,
              },
            })
              .then((data: any) => {
                setResourceList(data?.data?.searchResources?.result ?? []);
              })
              .catch((err: Error) => {
                setResourceList([]);
              });
          }
        }, 750);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }}
      sx={{ width: "33%" }}
    />
  );

  return (
    <Box>
      <Grid
        container
        sx={{
          backgroundColor: "rgba(255,255,255,0.9)",
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          zIndex: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 1.5,
            gap: 1,
          }}
        >
          {SearchResourceInput}

          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Picker
              label="Cluster"
              value={selectedCluster}
              onChange={setSelectedCluster}
              dataList={clusters}
            />

            <Picker
              label="Language"
              value={selectedLanguage}
              onChange={setSelectedLanguage}
              dataList={languages}
            />
          </Box>
        </Grid>
      </Grid>
      <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
      {error && (
        <Alert severity="error">
          <AlertTitle sx={{ fontWeight: 700 }}>
            Failed to fetch resources: {error.message}
          </AlertTitle>
          Does {selectedLanguage}-resources exist on cluster {selectedCluster}?
        </Alert>
      )}
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", flex: 1, py: 1, px: 2 }}
      >
        <ResourceList
          list={resourceList}
          listLabel="Resources"
          listType="resource"
          cluster={selectedCluster}
          language={selectedLanguage}
        />
      </Grid>
    </Box>
  );
};

export default ResourceManagement;

import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";

const ProductionOverviewChart = ({ data }: any) => {
  let numberOfTimestamps: any;
  let plotData = data;

  const binData = (inputData: any) => {
    if (inputData.length <= 7) return inputData;
    numberOfTimestamps = inputData.length;
    let newData: any[] = [];
    let listLength = 0;
    if (inputData.length > 32 && inputData.length <= 90) {
      // weekly bins
      for (let i = 0; i < inputData.length; i++) {
        if (i === 0) {
          newData.push(inputData[i]);
          listLength++;
        } else if (
          moment(newData[listLength - 1].x).week() !==
          moment(inputData[i].x).week()
        ) {
          newData.push(inputData[i]);
          listLength++;
        } else {
          newData[listLength - 1].y += inputData[i].y;
        }
      }
      return newData;
    } else if (inputData.length > 90) {
      // monthly bins
      for (let i = 0; i < inputData.length; i++) {
        if (i === 0) {
          newData.push(inputData[i]);
          listLength++;
        } else if (
          moment(newData[listLength - 1].x).format("YY-MM") !==
          moment(inputData[i].x).format("YY-MM")
        ) {
          newData.push(inputData[i]);
          listLength++;
        } else {
          newData[listLength - 1].y += inputData[i].y;
        }
      }
      return newData;
    } else {
      return inputData;
    }
  };

  if (plotData?.length > 0) {
    plotData = binData(plotData);
  }

  return (
    <Box sx={{ minHeight: 320, height: "30vh" }}>
      <ResponsiveBar
        data={plotData}
        keys={["articles"]}
        indexBy="date"
        margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
        padding={0.05}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "set2" }}
        layout={"vertical"}
        defs={[]}
        fill={[]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -25,
          legend: "Publication date",
          legendPosition: "middle",
          legendOffset: 40,
          truncateTickAt: 0,
          format: (value) => {
            const date = moment(value).format("DD");
            const firstTickIsEven =
              Number(moment(plotData[0].date).format("DD")) % 2 === 0;
            if (numberOfTimestamps > 32 && numberOfTimestamps <= 90) {
              return moment(value).format("[W]WW");
            } else if (numberOfTimestamps > 90) {
              return moment(value).format("MMM YY");
            } else if (
              numberOfTimestamps > 20 &&
              (firstTickIsEven
                ? Number(date) % 2 !== 0
                : Number(date) % 2 === 0)
            ) {
              return "";
            } else {
              return moment(value).format("Do");
            }
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Publications",
          legendPosition: "middle",
          legendOffset: -50,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        legends={[]}
      />
    </Box>
  );
};

export default ProductionOverviewChart;

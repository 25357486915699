import { Fragment, useState, useRef, useEffect, useContext } from "react";
import moment from "moment";
import ReactJson from "react-json-view";
import { Authenticator } from "../../utils/Authenticator";
import { useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";
import { CTX as CMContext } from "./CMContext";

import { Row, Col } from "react-bootstrap";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Avatar,
  CircularProgress,
  Switch,
  List,
  ListItem,
  IconButton,
  Tooltip,
  MenuList,
  MenuItem,
  Paper,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import {
  Person,
  AccountBalance,
  LocationOn,
  Check,
  Close,
  Delete,
  DeleteForever,
  Description,
  EventNote,
  Grain,
  Link,
  LinkOff,
} from "@mui/icons-material";
import { TiThLargeOutline } from "react-icons/ti";

import MapModal from "../Map/MapModal";
import SubTypesInput from "./SubtypesInput";
import AliasInput from "./AliasInput";
import BroaderInput from "./BroaderInput";
import WordsInput from "./WordsInput";
import GeoInput from "./GeoInput";
import DoubleSliderInput from "./DoubleSliderInput";
import RelationInput from "./RelationInput";
import RootIdInput from "./RootIdInput";
import StatsField from "./StatsField";
import LangLinkButtons from "./LangLinkButtons";
import GenderInput from "./GenderInput";
import TooltipLabel from "./TooltipLabel";
import RawKeywordDisplayer from "./RawKeywordDisplayer";
import ConnectedCategoryDisplayer from "./ConnectedCategoryDisplayer";
import LinkContent from "./LinkContent";

import EDIT_CONCEPT from "../../mutations/EDIT_CONCEPT";
import DELETE_CONCEPT from "../../mutations/DELETE_CONCEPT";
import CREATE_CONCEPT from "../../mutations/CREATE_CONCEPT";
import SEARCH_CONCEPTS from "../../mutations/searchConcepts";
import { getTagIcon } from "src/utils/helpers";

interface Concept {
  title: string;
  type: string;
  gender?: string;
  uuid: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus: boolean;
  ignore: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp: string;
  latestVersionTimestamp: string;
}

const theme = createTheme();

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    listStyleCreate: {
      height: "100%",
      marginTop: "8px",
      marginBottom: "168px",
    },
    listStyleEdit: { height: "100%", marginTop: "8px", marginBottom: "16px" },
    inactiveType: {
      color: "#fff",
      backgroundColor: "#DCDCDD",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    activeType: {
      color: "#fff",
      backgroundColor: "#56E39F",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    typePanel: {
      display: "flex",
    },
    aliasChip: {
      height: "2em",
      borderRadius: "1em",
    },
    listItem: {
      display: "flex",
      padding: "0.4rem",
      cursor: "default",
    },
    conceptText: {
      flexGrow: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    tooltip: {
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.25)",
      fontSize: "12px",
    },
  })
);

const greenTextFieldStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
  },
});

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface Props {
  concept: Concept | any;
  onHide: any;
  createConceptMode?: boolean;
  sourceType?: string;
  display?: boolean;
  showLinks?: boolean;
  viewOnly?: boolean;
  setModifiedConcept?: any;
  setAddedConcept?: any;
  setDeletedConcept?: any;
  isConceptChangeSuggestion?: boolean | undefined;
  mergedConcept?: Concept;
  error?: boolean;
}

const CMConceptModalContent = (props: Props) => {
  const classes = useStyles();
  const {
    concept = {},
    mergedConcept,
    createConceptMode = false,
    sourceType,
    display,
    showLinks,
    viewOnly = false,
    setModifiedConcept,
    onHide,
    setAddedConcept,
    setDeletedConcept,
    isConceptChangeSuggestion,
    error,
  } = props;

  const greenTextFieldClasses = greenTextFieldStyles();
  const hasPrivs: boolean = Authenticator.hasAuthority(["CONCEPT_MANAGEMENT"]);
  const {
    superAdminMode,
    isExtendedAdmin,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
    forceUpdateListener,
    setForceUpdateListener,
  }: any = useContext(CTX);
  const {
    updateConceptChangeSuggestions,
    setCurrentConcept,
    selectedSuggestion,
    setSelectedSuggestion,
    keepChanges,
  }: any = useContext(CMContext);

  const isEntity =
    concept.type === "person" ||
    concept.type === "place" ||
    concept.type === "organisation" ||
    concept.type === "event" ||
    concept.type === "object";

  const [titleError, setTitleError] = useState(false);

  const [deletingConcept, setDeletingConcept] = useState(false);
  const [mapModalShow, setMapModalShow] = useState(false);

  const [selectedUUID] = useState(concept.uuid ?? "");
  const [newTitle, setNewTitle] = useState(
    keepChanges?.title
      ? selectedSuggestion?.concept?.title ?? ""
      : concept.title ?? ""
  );
  const [newRootId, setNewRootId] = useState(concept.rootId ?? "");
  const [newType, setNewType] = useState(
    keepChanges?.type
      ? selectedSuggestion?.concept?.type ?? ""
      : concept.type ?? ""
  );
  const [gender, setGender] = useState<string>(
    keepChanges?.gender
      ? selectedSuggestion?.concept?.gender ?? ""
      : concept.gender ?? ""
  );
  const [newSubtypes, setNewSubtypes] = useState<any[]>(concept.subtypes ?? []);
  const [newAliases, setNewAliases] = useState(
    keepChanges?.aliases
      ? selectedSuggestion?.concept?.aliases ?? []
      : concept.aliases ?? []
  );
  const [keywords, setKeywords] = useState<Array<string>>(
    concept.keywords || []
  );
  const [keywordConcepts, setKeywordConcepts] = useState(
    concept.keywordConcepts || []
  );
  const [newLinks, setNewLinks] = useState<any[]>(
    concept.links?.map((link: any) => {
      for (let key in link) {
        if (link[key] === null || link[key] === undefined) {
          delete link[key];
        }
      }
      return link;
    }) || []
  );
  const [newMustConnectWords, setNewMustConnectWords] = useState<any>(
    concept.mustConnectWords
  );
  const [newMustNotConnectWords, setNewMustNotConnectWords] = useState<any>(
    concept.mustNotConnectWords
  );
  const [newMustBeMentioned, setNewMustBeMentioned] = useState<any>(
    concept.mustBeMentioned
  );
  const [newMustBeMentionedWords, setNewMustBeMentionedWords] = useState<any>(
    concept.mustBeMentionedWords
  );
  const [newMustNotBeMentionedWords, setNewMustNotBeMentionedWords] = useState<
    Array<any>
  >(concept.mustNotBeMentionedWords);

  const [newBroader, setNewBroader] = useState<string | null>(
    keepChanges?.broader
      ? selectedSuggestion?.concept?.broader ?? undefined
      : concept.broader
  );
  const [newBroaderConcept, setNewBroaderConcept] = useState<any>(
    concept.broaderConcept ?? undefined
  );
  const [newGeoJSON, setNewGeoJSON] = useState<string>(concept.geoJSON || "");
  const [selectedGeoJSON, setSelectedGeoJSON] = useState<string>("");

  const [newShortDescription, setNewShortDescription] = useState<string | null>(
    keepChanges?.shortDescription
      ? selectedSuggestion?.concept?.shortDescription
      : concept.shortDescription
  );

  const [newLongDescription, setNewLongDescription] = useState<string | null>(
    keepChanges?.longDescription
      ? selectedSuggestion?.concept?.longDescription
      : concept.longDescription
  );
  const [newAuthor] = useState(
    !createConceptMode
      ? concept.author ?? ""
      : Authenticator.getUsername() + " (Webportal)"
  );
  const [newSource] = useState(concept.source);
  const [newPubStatus, setNewPubStatus] = useState<boolean | null>(
    concept.pubStatus ?? true
  );
  const [ignoreStatus, setIgnoreStatus] = useState<boolean | null>(
    concept.ignore ?? false
  );
  const [packageGroup, setPackageGroup] = useState<string | undefined>(
    concept.package ?? undefined
  );
  const [newCorrectionWeight, setNewCorrectionWeight] = useState<number>(
    concept.correctionWeight === 0 ? 0.1 : concept.correctionWeight ?? 1
  );
  const [newMinimumWeight, setNewMinimumWeight] = useState<number>(
    !isEntity ? null : concept.minimumWeight
  );
  const [createdTimestamp] = useState<string>(concept.createdTimestamp);
  const [latestVersionTimestamp] = useState<string>(
    concept.latestVersionTimestamp
  );

  /** INHERITANCE STATES */
  const [checkboxShortDescription, setCheckboxShortDescription] = useState(
    createConceptMode
      ? false
      : (!isEntity && concept.shortDescription === null) ||
          (selectedSuggestion && keepChanges?.shortDescription === false)
  );
  const [checkboxLongDescription, setCheckboxLongDescription] = useState(
    createConceptMode
      ? false
      : (!isEntity && concept.longDescription === null) ||
          (selectedSuggestion && keepChanges?.longDescription === false)
  );
  const [checkboxBroader, setCheckboxBroader] = useState(
    createConceptMode
      ? false
      : (!isEntity && concept.broader === null) ||
          (selectedSuggestion && keepChanges?.broader === false)
  );
  const [checkboxPubStatus, setCheckboxPubStatus] = useState(
    !isEntity && concept.pubStatus === null
  );
  const [checkboxIgnoreStatus, setCheckboxIgnoreStatus] = useState(
    !isEntity && concept.ignore === null
  );
  const [checkboxKeywords, setCheckboxKeywords] = useState(
    !isEntity && concept.keywords === null
  );
  const [checkboxMustConnectWords, setCheckboxMustConnectWords] = useState(
    !isEntity && concept.mustConnectWords === null
  );
  const [checkboxMustNotConnectWords, setCheckboxMustNotConnectWords] =
    useState(!isEntity && concept.mustNotConnectWords === null);
  const [checkboxMustBeMentioned, setCheckboxMustBeMentioned] = useState(
    !isEntity && concept.mustBeMentioned === null
  );
  const [checkboxMustBeMentionedWords, setCheckboxMustBeMentionedWords] =
    useState(!isEntity && concept.mustBeMentionedWords === null);
  const [checkboxMustNotBeMentionedWords, setCheckboxMustNotBeMentionedWords] =
    useState(!isEntity && concept.mustNotBeMentionedWords === null);

  const [checkboxCorrectionWeight, setCheckboxCorrectionWeight] = useState(
    !isEntity && concept.correctionWeight === null
  );

  const isInitRender = useRef(true);

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);
  const [duplicateSuggestions, setDuplicateSuggestions] = useState<any[]>([]);
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false);
  const [isLoadingDuplicates, setIsLoadingDuplicates] =
    useState<boolean>(false);
  const [duplicatesTimeout, setDuplicatesTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const [editConcept, { loading: isLoadingEdit }] = useMutation(EDIT_CONCEPT);
  const [createConcept, { loading: isLoadingCreate }] =
    useMutation(CREATE_CONCEPT);
  const [deleteConcept, { loading: isLoadingDelete }] =
    useMutation(DELETE_CONCEPT);

  const handleToggleInheritance = (variable: string) => {
    switch (variable) {
      case "keywords":
        setCheckboxKeywords(!checkboxKeywords);
        break;
      case "mustConnectWords":
        setCheckboxMustConnectWords(!checkboxMustConnectWords);
        break;
      case "mustNotConnectWords":
        setCheckboxMustNotConnectWords(!checkboxMustNotConnectWords);
        break;
      case "mustBeMentioned":
        setCheckboxMustBeMentioned(!checkboxMustBeMentioned);
        break;
      case "mustBeMentionedWords":
        setCheckboxMustBeMentionedWords(!checkboxMustBeMentionedWords);
        break;
      case "mustNotBeMentionedWords":
        setCheckboxMustNotBeMentionedWords(!checkboxMustNotBeMentionedWords);
        break;
      case "broader":
        setCheckboxBroader(!checkboxBroader);
        break;
      case "correctionWeight":
        setCheckboxCorrectionWeight(!checkboxCorrectionWeight);
        break;
      case "shortDescription":
        setCheckboxShortDescription(!checkboxShortDescription);
        break;
      case "longDescription":
        setCheckboxLongDescription(!checkboxLongDescription);
        break;
      case "pubStatus":
        setCheckboxPubStatus(!checkboxPubStatus);
        break;
      case "ignore":
        setCheckboxIgnoreStatus(!checkboxIgnoreStatus);
        break;
      default:
    }
  };

  const TypeSwitch = withStyles({
    switchBase: {
      color: "#DCDCDD",
      "&$checked": {
        color: "#56E39F",
      },
      "&$checked + $track": {
        backgroundColor: "RGBA(87, 227, 158, 0.8)",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChangeType = (type: string) => {
    if (hasPrivs) setNewType(type);
  };

  const handleChangeGeo = (text: string) => {
    concept.geoJSON = text;
    setNewGeoJSON(concept.geoJSON);
  };

  const formatLinks = (links: any) => {
    if (links && links instanceof Array) {
      let formattedLinks: any = [];
      links.forEach((link: any) => {
        if (link && typeof link === "object") {
          let formattedLink = Object.fromEntries(
            Object.entries(link).filter(
              ([key]) =>
                key === "relationType" ||
                key === "id" ||
                key === "uuid" ||
                key === "source" ||
                key === "uri" ||
                key === "url"
            )
          );
          formattedLinks.push(formattedLink);
        }
      });
      return formattedLinks;
    }
    return null;
  };

  const buildUpdatedConcept = () => {
    let concept: any = {
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      title: newTitle,
      uuid: sourceType !== "global" && !createConceptMode ? selectedUUID : null,
      rootId: newRootId,
      type: newType,
      subtypes: newSubtypes,
      keywords: checkboxKeywords && superAdminMode ? null : keywords,
      mustConnectWords:
        checkboxMustConnectWords && superAdminMode ? null : newMustConnectWords,
      mustNotConnectWords:
        checkboxMustNotConnectWords && superAdminMode
          ? null
          : newMustNotConnectWords,
      mustBeMentioned:
        checkboxMustBeMentioned && superAdminMode ? null : newMustBeMentioned,
      mustBeMentionedWords:
        checkboxMustBeMentionedWords && superAdminMode
          ? null
          : newMustBeMentionedWords,
      mustNotBeMentionedWords:
        checkboxMustNotBeMentionedWords && superAdminMode
          ? null
          : newMustNotBeMentionedWords,
      correctionWeight:
        checkboxCorrectionWeight && superAdminMode ? null : newCorrectionWeight,
      minimumWeight: newMinimumWeight,
      broader: checkboxBroader && superAdminMode ? null : newBroader,
      shortDescription:
        checkboxShortDescription && superAdminMode ? null : newShortDescription,
      longDescription:
        checkboxLongDescription && superAdminMode ? null : newLongDescription,
      author: newAuthor ?? null,
      source: newSource ?? null,
      pubStatus: checkboxPubStatus && superAdminMode ? null : newPubStatus,
      ignore: checkboxIgnoreStatus && superAdminMode ? null : ignoreStatus,
      aliases: newAliases ?? null,
      geoJSON: newGeoJSON ?? null,
      global: props?.concept?.global ?? undefined,
      language: props?.concept?.language ?? undefined,
      links: formatLinks(newLinks),
      package: packageGroup,
    };
    if (newType.includes("person")) {
      concept.gender = gender;
    }
    if (isEntity) {
      concept.links = formatLinks(newLinks);
    }
    return concept;
  };

  const buildNewConcept = () => {
    let concept: any = {
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      title: newTitle,
      rootId: newRootId,
      type: newType,
      subtypes: newSubtypes,
      pubStatus: newPubStatus,
      shortDescription: newShortDescription,
      longDescription: newLongDescription,
      aliases: newAliases,
      broader: newBroader,
      author: newAuthor,
      ignore: false,
      geoJSON: newGeoJSON,
      sourceType: sourceType,
      keywords: !checkboxKeywords ? keywords : null,
      mustConnectWords: !checkboxMustConnectWords ? newMustConnectWords : null,
      mustNotConnectWords: !checkboxMustNotConnectWords
        ? newMustNotConnectWords
        : null,
      mustBeMentioned: !checkboxMustBeMentioned ? newMustBeMentioned : null,
      mustBeMentionedWords:
        !checkboxMustBeMentioned && !checkboxMustBeMentionedWords
          ? newMustBeMentionedWords
          : null,
      mustNotBeMentionedWords: !checkboxMustNotBeMentionedWords
        ? newMustNotBeMentionedWords
        : null,
      correctionWeight: !checkboxCorrectionWeight ? newCorrectionWeight : null,
      minimumWeight: newMinimumWeight || null,
      links: formatLinks(newLinks),
    };
    if (newType === "person") {
      concept.gender = gender;
    }
    return concept;
  };

  const EditConceptButton = () => (
    <Button
      disabled={!hasPrivs || isLoadingEdit ? true : false}
      variant="outlined"
      color="primary"
      onClick={() => {
        setSnackbarMessage(false);
        if (newTitle && newType) {
          if (newType === "person" && !gender) {
            setSnackbarMessage("Please, select a gender for the person");
            setSnackbarError(true);
            setShowSnackbar(true);
            return;
          }
          editConcept({
            variables: buildUpdatedConcept(),
          })
            .then((data: any) => {
              if (!data?.data?.editConcept?.error) {
                setModifiedConcept &&
                  setModifiedConcept({
                    uuid: selectedUUID,
                    title: newTitle,
                    type: newType,
                    pubStatus: newPubStatus,
                    ignore: ignoreStatus,
                    shortDescription: newShortDescription,
                    longDescription: newLongDescription,
                    aliases: newAliases,
                    broader: newBroader,
                    author: newAuthor,
                    source: newSource,
                  });
                onHide();
                let inputField = document.getElementById("conceptSearchInput");
                if (inputField) {
                  inputField.dispatchEvent(
                    new Event("input", { bubbles: true })
                  );
                }

                if (isConceptChangeSuggestion && selectedSuggestion?.id) {
                  updateConceptChangeSuggestions({
                    variables: {
                      id: selectedSuggestion.id,
                      handled: true,
                    },
                  })
                    .then(() => {
                      setTimeout(() => {
                        setForceUpdateListener(
                          forceUpdateListener === 0 ? 1 : 0
                        );
                      }, 250);
                    })
                    .catch((e: Error) => {})
                    .finally(() => {
                      setCurrentConcept(null);
                      setSelectedSuggestion(null);
                    });
                }
              }
              setSnackbarMessage(data.data.editConcept.response);
              setSnackbarError(data.data.editConcept.error);
              setShowSnackbar(true);
            })
            .catch((e) => {
              setSnackbarMessage(e);
              setSnackbarError(true);
              setShowSnackbar(true);
            });
        } else {
          setTitleError(true);
        }
      }}
      sx={{ float: "right", gap: 0.5 }}
    >
      {`Save${isExtendedAdmin ? " (admin)" : ""}`}
      <Check />
    </Button>
  );

  const CreateConceptButton = () => (
    <Button
      disabled={!hasPrivs || isLoadingCreate ? true : false}
      variant="outlined"
      color="primary"
      onClick={() => {
        setShowSnackbar(false);
        if (newTitle) {
          if (newType === "person" && !gender) {
            setSnackbarMessage("Please, select a gender for the person");
            setSnackbarError(true);
            setShowSnackbar(true);
            return;
          }
          createConcept({
            variables: buildNewConcept(),
          }).then((data: any) => {
            if (!data?.data?.createConcept?.error) {
              onHide();
              let inputField: any =
                document.getElementById("conceptSearchInput");
              if (inputField) {
                inputField.dispatchEvent(new Event("input", { bubbles: true }));
              }
              if (data?.data?.createConcept?.uuid) {
                if (setAddedConcept) {
                  let addedConcept: any = {
                    uuid: data.data.createConcept.uuid,
                    superAdmin: superAdminMode,
                    extendedAdmin: isExtendedAdmin,
                  };
                  if (data?.data?.createConcept?.concept?.global) {
                    addedConcept.global =
                      data.data.createConcept.concept.global;
                  }
                  if (data?.data?.createConcept?.concept?.language) {
                    addedConcept.language =
                      data.data.createConcept.concept.language;
                  }
                  setAddedConcept(addedConcept);
                }
              }

              if (isConceptChangeSuggestion && selectedSuggestion?.id) {
                updateConceptChangeSuggestions({
                  variables: {
                    id: selectedSuggestion.id,
                    handled: true,
                  },
                })
                  .then(() => {
                    setTimeout(() => {
                      setForceUpdateListener(forceUpdateListener === 0 ? 1 : 0);
                    }, 250);
                  })
                  .catch((e: Error) => {})
                  .finally(() => {
                    setCurrentConcept(null);
                    setSelectedSuggestion(null);
                  });
              }
            }
            setShowSnackbar(true);
            setSnackbarMessage(data.data.createConcept.response);
            setSnackbarError(data.data.createConcept.error);
          });
        } else {
          setTitleError(true);
        }
      }}
      sx={{ float: "right", gap: 0.5 }}
    >
      Create {superAdminMode && " (admin)"}
      <Check />
    </Button>
  );

  const DeleteConceptButton = () => (
    <Button
      disabled={!hasPrivs || isLoadingDelete ? true : false}
      variant="outlined"
      color="secondary"
      onClick={() => {
        setShowSnackbar(false);
        deleteConcept({
          variables: {
            superAdmin: superAdminMode,
            extendedAdmin: isExtendedAdmin,
            uuid: selectedUUID,
            global: props.concept.global,
            language: props.concept.language,
          },
        })
          .then((data: any) => {
            if (!data?.data?.deleteConcept?.error) {
              onHide();
              setTimeout(() => {
                setDeletedConcept && setDeletedConcept(selectedUUID);
              }, 250);
              let inputField = document.getElementById("conceptSearchInput");
              setTimeout(() => {
                if (inputField) {
                  inputField.dispatchEvent(
                    new Event("input", { bubbles: true })
                  );
                }
              }, 250);

              if (isConceptChangeSuggestion && selectedSuggestion?.id) {
                updateConceptChangeSuggestions({
                  variables: {
                    id: selectedSuggestion.id,
                    handled: true,
                  },
                })
                  .then(() => {
                    setTimeout(() => {
                      setForceUpdateListener(forceUpdateListener === 0 ? 1 : 0);
                    }, 250);
                  })
                  .catch((e: Error) => {})
                  .finally(() => {
                    setCurrentConcept(null);
                    setSelectedSuggestion(null);
                  });
              }
            }
            setShowSnackbar(true);
            setSnackbarError(data.data.deleteConcept.error);
            setSnackbarMessage("Deleted concept: " + selectedUUID);
            //setSnackbarMessage(data.data.deleteConcept.response);
          })
          .catch(() => {
            setShowSnackbar(true);
            setSnackbarError(true);
            setSnackbarMessage("Failed to delete concept. (Error from server)");
          })
          .finally(() => {});
      }}
      sx={{ float: "left", gap: 0.5 }}
    >
      Confirm
      <DeleteForever />
    </Button>
  );

  useEffect(() => {
    setCheckboxMustBeMentioned(checkboxMustBeMentionedWords);
    if (!newMustBeMentionedWords || newMustBeMentionedWords?.length === 0) {
      setNewMustBeMentioned(false);
    } else if (newMustBeMentionedWords?.length > 0) {
      setNewMustBeMentioned(true);
    }
  }, [newMustBeMentionedWords, checkboxMustBeMentionedWords]);

  useEffect(() => {
    if (createConceptMode) {
      duplicatesTimeout && clearTimeout(duplicatesTimeout);
      if (newTitle !== "") {
        setDuplicatesTimeout(
          setTimeout(() => {
            setIsLoadingDuplicates(true);
            searchConcepts({
              variables: {
                title: newTitle,
                type: newType,
                draft: false,
                size: 5,
              },
            })
              .then((data: any) => {
                if (!newTitle) setDuplicateSuggestions([]);
                data?.data?.searchConcepts?.result &&
                  setDuplicateSuggestions(data.data.searchConcepts.result);
              })
              .finally(() => setIsLoadingDuplicates(false));
          }, 500)
        );
      } else setDuplicateSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTitle, newType]);

  useEffect(() => {
    if (isInitRender?.current) {
      //Ensure focus on title when opening the modal
      $("#new-title-field").focus();
    } else isInitRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!display) {
    return null;
  }

  if (display && error) {
    return (
      <Box display="flex" mt={2}>
        <Alert severity="error" style={{ minWidth: "33%", maxWidth: "100%" }}>
          <AlertTitle>Error</AlertTitle>
          Failed to fetch.
        </Alert>
      </Box>
    );
  } else if (display && !concept) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Something went wrong. Concept not found, or invalid root ID
      </Alert>
    );
  } else if (!concept) {
    return null;
  }

  if (
    !createConceptMode &&
    ((sourceType !== "global" && !concept.uuid) ||
      (sourceType === "global" && !concept.rootId))
  ) {
    return (
      <Alert severity="error" sx={{ display: "inline-flex" }}>
        <AlertTitle>Error</AlertTitle>
        Concept UUID or root ID not found
      </Alert>
    );
  }

  return (
    <Fragment>
      <MapModal
        open={mapModalShow}
        onHide={() => {
          setMapModalShow(false);
          setSelectedGeoJSON("");
        }}
        concept={buildUpdatedConcept()}
        pointSelectedCallback={setSelectedGeoJSON}
        selectedGeoJSON={selectedGeoJSON}
        pointSelectedConfirm={() => setNewGeoJSON(selectedGeoJSON)}
      />
      {showLinks && (superAdminMode || isExtendedAdmin) ? (
        <LinkContent src={newLinks} setSrc={setNewLinks} />
      ) : (
        <Row noGutters>
          <Col sm={6}>
            <List
              className={
                createConceptMode
                  ? classes.listStyleCreate
                  : classes.listStyleEdit
              }
            >
              {!createConceptMode && sourceType !== "global" && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled
                    autoComplete="off"
                    label="UUID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={selectedUUID || undefined}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="uuid"
                  />
                </ListItem>
              )}
              {superAdminMode && !isEntity && (
                <ListItem className={classes.root}>
                  <RootIdInput
                    type={newType}
                    rootId={newRootId}
                    setRootId={setNewRootId}
                    viewOnly={viewOnly}
                    sourceType={sourceType}
                  />
                </ListItem>
              )}
              <ListItem className={classes.root}>
                <Box width="100%">
                  <TextField
                    id="new-title-field"
                    autoComplete="off"
                    className={
                      keepChanges?.title
                        ? greenTextFieldClasses.root
                        : undefined
                    }
                    disabled={viewOnly || (hasPrivs ? false : true)}
                    label="Title"
                    variant="outlined"
                    size="small"
                    name="title"
                    fullWidth
                    error={titleError}
                    value={newTitle || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => setNewTitle(e.target.value)}
                  />
                  <Box
                    zIndex={2}
                    position="relative"
                    width="100%"
                    style={{
                      margin: 0,
                      display: createConceptMode ? "block" : "none",
                    }}
                  >
                    <Box display="flex" alignItems="center" position="absolute">
                      <CircularProgress
                        size="1rem"
                        style={{
                          marginRight: "0.5rem",
                          visibility: isLoadingDuplicates
                            ? "visible"
                            : "hidden",
                        }}
                      />

                      {duplicateSuggestions.length < 1 ? (
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: "#56E39F",
                            display: "inline-block",
                          }}
                        >
                          No duplicates found for the selected type (
                          <b>{newType}</b>)
                        </Typography>
                      ) : (
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          onClick={() => setShowDuplicates(!showDuplicates)}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                        >
                          <b>{duplicateSuggestions.length}</b> possible
                          duplicates found for the selected type (
                          <b>{newType}</b>)
                        </Typography>
                      )}
                    </Box>
                    <Box
                      position="absolute"
                      left="0"
                      top="20px"
                      width="100%"
                      display={showDuplicates ? "block" : "none"}
                    >
                      <Paper
                        className="popover-index dropdown-transition"
                        elevation={3}
                        style={{
                          width: "40%",
                          display:
                            !isLoadingDuplicates &&
                            duplicateSuggestions?.length > 0
                              ? "block"
                              : "none",
                        }}
                      >
                        <MenuList id="possible-duplicates-list" dense>
                          {duplicateSuggestions.map((item: any) => (
                            <Tooltip
                              key={item.uuid}
                              title={
                                <Box py={0.4}>
                                  <Box>
                                    <b>{item.title}</b>
                                    {item.global && (
                                      <Box
                                        component="span"
                                        whiteSpace="nowrap"
                                        style={{ opacity: 0.8 }}
                                      >
                                        {" (global)"}
                                      </Box>
                                    )}
                                  </Box>

                                  <Box mt={0.4}>{item.shortDescription}</Box>
                                </Box>
                              }
                              aria-label="concept-description"
                              placement="right"
                              arrow
                              classes={{
                                tooltip: classes.tooltip,
                              }}
                            >
                              <MenuItem className={classes.listItem}>
                                <Box className={classes.conceptText}>
                                  {getTagIcon(item.type, undefined, undefined, {
                                    mr: 0.5,
                                  })}
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{ fontWeight: 700 }}
                                  >
                                    {item.title}
                                  </Typography>

                                  {item.shortDescription && (
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {" "}
                                      - {item.shortDescription}
                                    </Typography>
                                  )}
                                </Box>
                              </MenuItem>
                            </Tooltip>
                          ))}
                        </MenuList>
                      </Paper>
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              <ListItem className={classes.root}>
                <TextField
                  disabled={viewOnly || (hasPrivs ? false : true)}
                  autoComplete="off"
                  className={
                    keepChanges?.type ? greenTextFieldClasses.root : undefined
                  }
                  label="Type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={
                    newType.includes("organisation")
                      ? capitalizeFirstLetter("organization")
                      : capitalizeFirstLetter(newType)
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {newType.includes("category") ||
                        newType.includes("topic") ? (
                          <Box className={classes.typePanel}>
                            {newType.includes("category") && (
                              <Tooltip title="Set category" placement="top">
                                <Avatar
                                  className={
                                    newType.includes("category")
                                      ? classes.activeType
                                      : classes.inactiveType
                                  }
                                  onClick={() => handleChangeType("category")}
                                >
                                  <Description />
                                </Avatar>
                              </Tooltip>
                            )}
                            {newType.includes("topic") && (
                              <Tooltip title="Set topic" placement="top">
                                <Avatar
                                  className={
                                    newType.includes("topic")
                                      ? classes.activeType
                                      : classes.inactiveType
                                  }
                                  onClick={() => handleChangeType("topic")}
                                >
                                  <Grain />
                                </Avatar>
                              </Tooltip>
                            )}
                          </Box>
                        ) : (
                          <Box className={classes.typePanel}>
                            <Tooltip title="Set person" placement="top">
                              <Avatar
                                className={
                                  newType.includes("person")
                                    ? classes.activeType
                                    : classes.inactiveType
                                }
                                onClick={() => handleChangeType("person")}
                              >
                                <Person />
                              </Avatar>
                            </Tooltip>
                            <Tooltip title="Set place" placement="top">
                              <Avatar
                                className={
                                  newType.includes("place")
                                    ? classes.activeType
                                    : classes.inactiveType
                                }
                                onClick={() => handleChangeType("place")}
                              >
                                <LocationOn />
                              </Avatar>
                            </Tooltip>
                            <Tooltip title="Set organisation" placement="top">
                              <Avatar
                                className={
                                  newType.includes("organisation")
                                    ? classes.activeType
                                    : classes.inactiveType
                                }
                                onClick={() => handleChangeType("organisation")}
                              >
                                <AccountBalance />
                              </Avatar>
                            </Tooltip>
                            <Tooltip title="Set event" placement="top">
                              <Avatar
                                className={
                                  newType.includes("event")
                                    ? classes.activeType
                                    : classes.inactiveType
                                }
                                onClick={() => handleChangeType("event")}
                              >
                                <EventNote />
                              </Avatar>
                            </Tooltip>
                            <Tooltip title="Set object" placement="top">
                              <Avatar
                                className={
                                  newType.includes("object")
                                    ? classes.activeType
                                    : classes.inactiveType
                                }
                                onClick={() => handleChangeType("object")}
                              >
                                <TiThLargeOutline />
                              </Avatar>
                            </Tooltip>
                          </Box>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem
                className={classes.root}
                style={{ display: newType === "person" ? "flex" : "none" }}
              >
                <GenderInput
                  gender={gender}
                  setGender={setGender}
                  type={newType}
                  className={
                    keepChanges?.gender ? greenTextFieldClasses.root : undefined
                  }
                />
              </ListItem>
              {superAdminMode &&
                !newType.includes("category") &&
                !newType.includes("topic") && (
                  <ListItem className={classes.root}>
                    <SubTypesInput
                      subtypes={newSubtypes}
                      setSubtypes={setNewSubtypes}
                    />
                  </ListItem>
                )}
              {superAdminMode &&
                !createConceptMode &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <RawKeywordDisplayer
                      disabled={viewOnly}
                      title={newTitle}
                      type={newType}
                      keywords={
                        checkboxKeywords !== true
                          ? keywords
                          : mergedConcept?.keywords
                          ? mergedConcept?.keywords
                          : []
                      }
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                newType.includes("topic") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <AliasInput
                      id="keywords-input"
                      aliases={keywords}
                      setAliases={setKeywords}
                      mergedAliases={
                        mergedConcept?.keywords ? mergedConcept?.keywords : []
                      }
                      label={
                        <TooltipLabel
                          label="Keywords"
                          tooltipText="Words that extend the definition of the topic beyond the topic title. Case sensitive."
                        />
                      }
                      //disabled={checkboxKeywords}
                      isInheriting={checkboxKeywords}
                      setIsInheriting={setCheckboxKeywords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      adornment={
                        superAdminMode &&
                        !viewOnly && (
                          <Tooltip
                            title={
                              checkboxKeywords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              color={checkboxKeywords ? "success" : "primary"}
                              onClick={() => {
                                handleToggleInheritance("keywords");
                              }}
                            >
                              {checkboxKeywords ? <Link /> : <LinkOff />}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}

              <ListItem className={classes.root}>
                <AliasInput
                  id="alias-input"
                  className={
                    keepChanges?.aliases
                      ? greenTextFieldClasses.root
                      : undefined
                  }
                  aliases={newAliases || []}
                  setAliases={setNewAliases}
                  label="Aliases"
                />
              </ListItem>

              {superAdminMode && newType.includes("topic") && (
                <ListItem className={classes.root}>
                  <TextField
                    autoComplete="off"
                    label={
                      <TooltipLabel
                        label="Package group"
                        tooltipText="Only one topic from all topics with a unique group name will be tagged; called package in the data."
                      />
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={packageGroup}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setPackageGroup(e.target.value);
                    }}
                  />
                </ListItem>
              )}

              {(superAdminMode || isExtendedAdmin) &&
                !createConceptMode &&
                newType === "topic" &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <ConnectedCategoryDisplayer
                      disabled={viewOnly}
                      concept={concept}
                      sourceType={sourceType}
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                newType.includes("category") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <WordsInput
                      id="word-input"
                      wordConcepts={
                        keywordConcepts?.length > 0
                          ? keywordConcepts
                          : keywords.map((keyword: string) => {
                              return {
                                title: keyword,
                                uuid: keyword,
                                rootId: keyword,
                              };
                            })
                      }
                      words={keywords}
                      mergedWords={
                        mergedConcept?.keywords
                          ? mergedConcept?.keywords
                          : undefined
                      }
                      isInheriting={checkboxKeywords}
                      setIsInheriting={setCheckboxKeywords}
                      setWordConcepts={setKeywordConcepts}
                      disabled={viewOnly || checkboxKeywords}
                      setWordIds={setKeywords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      label={
                        <TooltipLabel
                          label="Connected Topics"
                          tooltipText="Relate topics to the category. Tagging of the connected topic will add relevance to the category."
                        />
                      }
                      adornment={
                        (superAdminMode && !viewOnly) === true && (
                          <Tooltip
                            title={
                              checkboxKeywords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              color={checkboxKeywords ? "success" : "primary"}
                              onClick={() => {
                                handleToggleInheritance("keywords");
                              }}
                            >
                              {checkboxKeywords ? <Link /> : <LinkOff />}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                newType.includes("topic") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <RelationInput
                      disabled={viewOnly}
                      words={newMustConnectWords}
                      setWords={setNewMustConnectWords}
                      label={
                        <TooltipLabel
                          label="Must connect words"
                          tooltipText="Word pairs where one complete word pair needs to have a similar significance of presence in order for the topic to be tagged. Entered words must be keywords."
                        />
                      }
                      id="must-connect-words"
                      isInheriting={checkboxMustConnectWords}
                      setIsInheriting={setCheckboxMustConnectWords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      mergedWords={
                        mergedConcept?.mustConnectWords
                          ? mergedConcept?.mustConnectWords
                          : []
                      }
                      adornment={
                        superAdminMode &&
                        !viewOnly && (
                          <Tooltip
                            title={
                              checkboxMustConnectWords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ ml: 0.5 }}
                              color={
                                checkboxMustConnectWords ? "success" : "primary"
                              }
                              onClick={() => {
                                handleToggleInheritance("mustConnectWords");
                              }}
                            >
                              {checkboxMustConnectWords ? (
                                <Link />
                              ) : (
                                <LinkOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                newType.includes("topic") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <RelationInput
                      disabled={viewOnly}
                      words={newMustNotConnectWords}
                      setWords={setNewMustNotConnectWords}
                      label={
                        <TooltipLabel
                          label="Must not connect words"
                          tooltipText="Word pairs where one complete word pair must not have a similar significance of presence in order for the topic to be tagged. Entered words must be keywords."
                        />
                      }
                      id="must-not-connect-words"
                      /*
                      disabled={viewOnly || checkboxMustNotConnectWords}
                      */
                      isInheriting={checkboxMustNotConnectWords}
                      setIsInheriting={setCheckboxMustNotConnectWords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      mergedWords={
                        mergedConcept && mergedConcept.mustNotConnectWords
                          ? mergedConcept.mustNotConnectWords
                          : []
                      }
                      adornment={
                        superAdminMode &&
                        !viewOnly && (
                          <Tooltip
                            title={
                              checkboxMustNotConnectWords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ ml: 0.5 }}
                              color={
                                checkboxMustNotConnectWords
                                  ? "success"
                                  : "primary"
                              }
                              onClick={() => {
                                handleToggleInheritance("mustNotConnectWords");
                              }}
                            >
                              {checkboxMustNotConnectWords ? (
                                <Link />
                              ) : (
                                <LinkOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                (newType === "topic" || newType === "x-im/topic") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <AliasInput
                      id="must-be-mentioned-words-input"
                      disabled={viewOnly || checkboxMustBeMentionedWords}
                      aliases={newMustBeMentionedWords}
                      setAliases={setNewMustBeMentionedWords}
                      label={
                        <TooltipLabel
                          label="Must be mentioned words"
                          tooltipText="Words where at least one must be present in the text for the topic to be tagged. Case sensitive."
                        />
                      }
                      isInheriting={checkboxMustBeMentionedWords}
                      setIsInheriting={setCheckboxMustBeMentionedWords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      mergedAliases={mergedConcept?.mustBeMentionedWords ?? []}
                      adornment={
                        (!viewOnly && superAdminMode) === true && (
                          <Tooltip
                            title={
                              checkboxMustBeMentionedWords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              color={
                                checkboxMustBeMentionedWords
                                  ? "success"
                                  : "primary"
                              }
                              onClick={() => {
                                handleToggleInheritance("mustBeMentionedWords");
                              }}
                            >
                              {checkboxMustBeMentionedWords ? (
                                <Link />
                              ) : (
                                <LinkOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}
              {isExtendedAdmin &&
                newType.includes("topic") &&
                sourceType !== "global" && (
                  <ListItem className={classes.root}>
                    <AliasInput
                      id="must-not-be-mentioned-words-input"
                      disabled={viewOnly || checkboxMustNotBeMentionedWords}
                      aliases={newMustNotBeMentionedWords}
                      setAliases={setNewMustNotBeMentionedWords}
                      label={
                        <TooltipLabel
                          label="Must not be mentioned words"
                          tooltipText="Words where at least one must not be present in the text for the topic to be tagged. Case sensitive."
                        />
                      }
                      isInheriting={checkboxMustNotBeMentionedWords}
                      setIsInheriting={setCheckboxMustNotBeMentionedWords}
                      isSuperAdmin={superAdminMode}
                      isExtendedAdmin={isExtendedAdmin}
                      mergedAliases={
                        mergedConcept?.mustNotBeMentionedWords ?? []
                      }
                      adornment={
                        (!viewOnly && superAdminMode) === true && (
                          <Tooltip
                            title={
                              checkboxMustNotBeMentionedWords
                                ? "Deactivate inheritance"
                                : "Activate inheritance"
                            }
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              color={
                                checkboxMustNotBeMentionedWords
                                  ? "success"
                                  : "primary"
                              }
                              onClick={() => {
                                handleToggleInheritance(
                                  "mustNotBeMentionedWords"
                                );
                              }}
                            >
                              {checkboxMustNotBeMentionedWords ? (
                                <Link />
                              ) : (
                                <LinkOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                  </ListItem>
                )}
              <ListItem className={classes.root} style={{ zIndex: 2 }}>
                <BroaderInput
                  disabled={
                    viewOnly || (superAdminMode && checkboxBroader)
                      ? true
                      : false
                  }
                  type={newType}
                  isEntity={isEntity}
                  broader={
                    checkboxBroader ? mergedConcept?.broader : newBroader
                  }
                  setBroader={setNewBroader}
                  broaderConcept={
                    checkboxBroader
                      ? mergedConcept?.broaderConcept
                      : newBroaderConcept
                  }
                  setBroaderConcept={setNewBroaderConcept}
                  viewOnly={viewOnly}
                  superAdminMode={superAdminMode}
                  isInheriting={checkboxBroader}
                  setIsInheriting={setCheckboxBroader}
                  handleToggleInheritance={handleToggleInheritance}
                  className={
                    keepChanges?.broader
                      ? greenTextFieldClasses.root
                      : undefined
                  }
                />
              </ListItem>
              {(newType === "place" ||
                newType === "organisation" ||
                newType === "x-im/place" ||
                newType === "x-im/organisation") && (
                <ListItem className={classes.root}>
                  <GeoInput
                    superAdminMode={superAdminMode}
                    newGeoJSON={newGeoJSON}
                    setNewGeoJSON={handleChangeGeo}
                    setMapModalShow={setMapModalShow}
                  />
                </ListItem>
              )}
              {!createConceptMode && (
                <ListItem className={classes.root}>
                  <StatsField uuid={concept.uuid} show={hasPrivs} />
                </ListItem>
              )}
            </List>
          </Col>
          <Col sm={6}>
            <List
              className={
                createConceptMode
                  ? classes.listStyleCreate
                  : classes.listStyleEdit
              }
            >
              {sourceType !== "global" && (
                <ListItem className={classes.root}>
                  <TextField
                    autoComplete="off"
                    className={
                      keepChanges?.shortDescription
                        ? greenTextFieldClasses.root
                        : undefined
                    }
                    disabled={
                      viewOnly || (superAdminMode && checkboxShortDescription)
                        ? true
                        : false
                    }
                    size="small"
                    label="Short description (required)"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      checkboxShortDescription === true
                        ? mergedConcept?.shortDescription ?? ""
                        : newShortDescription ?? ""
                    }
                    onChange={(e) => {
                      checkboxShortDescription &&
                        setCheckboxShortDescription(false);
                      setNewShortDescription(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: superAdminMode &&
                        !viewOnly &&
                        !isEntity && (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                checkboxShortDescription
                                  ? "Deactivate inheritance"
                                  : "Activate inheritance"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                color={
                                  checkboxShortDescription
                                    ? "success"
                                    : "primary"
                                }
                                onClick={() => {
                                  handleToggleInheritance("shortDescription");
                                }}
                              >
                                {checkboxShortDescription ? (
                                  <Link />
                                ) : (
                                  <LinkOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                    }}
                  />
                </ListItem>
              )}
              {sourceType !== "global" && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled={
                      viewOnly || (superAdminMode && checkboxLongDescription)
                    }
                    autoComplete="off"
                    className={
                      keepChanges?.longDescription
                        ? greenTextFieldClasses.root
                        : undefined
                    }
                    size="small"
                    fullWidth
                    label="Long description"
                    multiline
                    rows={5}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      checkboxLongDescription === true
                        ? mergedConcept?.longDescription ?? ""
                        : newLongDescription ?? ""
                    }
                    onChange={(e) => {
                      checkboxLongDescription &&
                        setCheckboxLongDescription(false);
                      setNewLongDescription(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: superAdminMode &&
                        !viewOnly &&
                        !isEntity && (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                checkboxLongDescription
                                  ? "Deactivate inheritance"
                                  : "Activate inheritance"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                color={
                                  checkboxLongDescription
                                    ? "success"
                                    : "primary"
                                }
                                onClick={() => {
                                  handleToggleInheritance("longDescription");
                                }}
                              >
                                {checkboxLongDescription ? (
                                  <Link />
                                ) : (
                                  <LinkOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                    }}
                  />
                </ListItem>
              )}
              {sourceType !== "global" && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled
                    autoComplete="off"
                    label="Author"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newAuthor || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ListItem>
              )}
              {!createConcept &&
                sourceType !== "global" &&
                sourceType !== "language" &&
                sourceType !== "customer" && (
                  <ListItem className={classes.root}>
                    <TextField
                      disabled
                      autoComplete="off"
                      label="Source"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newSource || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ListItem>
                )}
              {sourceType !== "global" && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled={viewOnly}
                    autoComplete="off"
                    label="Publication status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    value={
                      checkboxPubStatus
                        ? mergedConcept?.pubStatus
                          ? "On"
                          : "Off"
                        : newPubStatus
                        ? "On"
                        : "Off"
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              checkboxPubStatus
                                ? mergedConcept?.pubStatus
                                  ? "Toggle off"
                                  : "Toggle on"
                                : newPubStatus
                                ? "Toggle off"
                                : "Toggle on"
                            }
                            placement="top"
                          >
                            <TypeSwitch
                              disabled={viewOnly || checkboxPubStatus}
                              edge="end"
                              checked={
                                checkboxPubStatus
                                  ? mergedConcept?.pubStatus
                                    ? true
                                    : false
                                  : newPubStatus
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (hasPrivs) setNewPubStatus(!newPubStatus);
                              }}
                            />
                          </Tooltip>
                          {superAdminMode && !viewOnly && !isEntity && (
                            <Tooltip
                              title={
                                checkboxPubStatus
                                  ? "Deactivate inheritance"
                                  : "Activate inheritance"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                color={
                                  checkboxPubStatus ? "success" : "primary"
                                }
                                onClick={() => {
                                  handleToggleInheritance("pubStatus");
                                }}
                              >
                                {checkboxPubStatus ? <Link /> : <LinkOff />}
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>
              )}

              {!createConceptMode && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled={viewOnly}
                    autoComplete="off"
                    label="Ignore in autotagging"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    value={
                      checkboxIgnoreStatus
                        ? mergedConcept?.ignore
                          ? "On"
                          : "Off"
                        : ignoreStatus
                        ? "On"
                        : "Off"
                    }
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              checkboxIgnoreStatus
                                ? mergedConcept?.ignore
                                  ? "Toggle off"
                                  : "Toggle on"
                                : ignoreStatus
                                ? "Toggle off"
                                : "Toggle on"
                            }
                            placement="top"
                          >
                            <TypeSwitch
                              disabled={
                                checkboxIgnoreStatus || viewOnly ? true : false
                              }
                              edge="end"
                              checked={
                                checkboxIgnoreStatus
                                  ? mergedConcept?.ignore
                                    ? true
                                    : false
                                  : ignoreStatus
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                if (hasPrivs) setIgnoreStatus(!ignoreStatus);
                              }}
                            />
                          </Tooltip>
                          {(superAdminMode && !viewOnly && !isEntity) ===
                            true && (
                            <Tooltip
                              title={
                                checkboxIgnoreStatus
                                  ? "Deactivate inheritance"
                                  : "Activate inheritance"
                              }
                              placement="top"
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                color={
                                  checkboxIgnoreStatus ? "success" : "primary"
                                }
                                onClick={() => {
                                  handleToggleInheritance("ignore");
                                }}
                              >
                                {checkboxIgnoreStatus ? <Link /> : <LinkOff />}
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>
              )}

              {!createConceptMode && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled
                    autoComplete="off"
                    label="Created timestamp"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      !createdTimestamp
                        ? ""
                        : moment(createdTimestamp).format("YYYY-MM-DD")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    disabled
                    autoComplete="off"
                    label="Latest version timestamp"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      !latestVersionTimestamp
                        ? ""
                        : moment(latestVersionTimestamp).format("YYYY-MM-DD")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ListItem>
              )}

              {isExtendedAdmin && isEntity && (
                <ListItem className={classes.root}>
                  <TextField
                    disabled={
                      (viewOnly ? true : false) &&
                      (isExtendedAdmin && !superAdminMode ? false : true)
                    }
                    autoComplete="off"
                    type="number"
                    onWheel={(e: any) => e?.target?.blur()}
                    label={
                      <TooltipLabel
                        label="Minimum weight"
                        tooltipText="Used to restrict entities in auto-tagging to only those with a weight greater than or equal to the minimum weight value."
                      />
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newMinimumWeight}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => {
                      setNewMinimumWeight(parseInt(e.target.value));
                    }}
                  />
                </ListItem>
              )}

              {(concept.language ||
                concept.global ||
                newLinks.find((link) => link?.source === "wikipedia") ||
                newLinks.find((link) => link?.source === "wikidata") ||
                concept.openStreetMap) && (
                <ListItem className={classes.root}>
                  <LangLinkButtons
                    language={concept.language || ""}
                    global={concept.global || ""}
                    wikipedia={
                      newLinks.find((link) => link?.source === "wikipedia")
                        ? newLinks.find((link) => link?.source === "wikipedia")
                            ?.url
                        : ""
                    }
                    wikidata={
                      newLinks.find((link) => link?.source === "wikidata")
                        ? newLinks.find((link) => link?.source === "wikidata")
                            ?.url
                        : ""
                    }
                    osm={concept.openStreetMap || ""}
                    concept={buildUpdatedConcept()}
                    createConceptMode={createConceptMode}
                  />
                </ListItem>
              )}

              {isExtendedAdmin && newType.includes("topic") && (
                <Box padding="0 14px">
                  <ListItem className={classes.root}>
                    <DoubleSliderInput
                      disabled={
                        (viewOnly || checkboxCorrectionWeight ? true : false) &&
                        (isExtendedAdmin && !superAdminMode ? false : true)
                      }
                      label={
                        <TooltipLabel
                          label="Correction weight"
                          tooltipText="Used to boost or penalize the weight of the topic."
                        />
                      }
                      doubleValue={
                        checkboxCorrectionWeight
                          ? mergedConcept?.correctionWeight
                          : newCorrectionWeight
                      }
                      min={0}
                      max={2}
                      step={0.01}
                      onChange={(event: any, newValue: any) => {
                        checkboxCorrectionWeight &&
                          setCheckboxCorrectionWeight(false);
                        setNewCorrectionWeight(newValue);
                      }}
                    />
                    {!viewOnly && superAdminMode && (
                      <Tooltip
                        title={
                          checkboxCorrectionWeight
                            ? "Deactivate inheritance"
                            : "Activate inheritance"
                        }
                        placement="top"
                      >
                        <IconButton
                          size="small"
                          color={
                            checkboxCorrectionWeight ? "success" : "primary"
                          }
                          onClick={() => {
                            handleToggleInheritance("correctionWeight");
                          }}
                        >
                          {checkboxCorrectionWeight ? <Link /> : <LinkOff />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListItem>
                </Box>
              )}

              {sourceType === "global" && (
                <ListItem className={classes.root}>
                  <h5>Links</h5>
                  <ReactJson src={newLinks} />
                </ListItem>
              )}
            </List>
          </Col>
        </Row>
      )}
      {!viewOnly ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: "-1rem",
            mr: "-1rem",
            pt: 2,
            pl: 2,
            pr: 2,
            borderTop: "1px solid #e9ecef",
          }}
        >
          <Box>
            {!createConceptMode &&
              (deletingConcept ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    disabled
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeletingConcept(true)}
                    sx={{ float: "left", gap: 0.5 }}
                  >
                    Delete Concept
                    <Delete />
                  </Button>
                  <DeleteConceptButton />
                  {(isLoadingCreate || isLoadingEdit || isLoadingDelete) && (
                    <CircularProgress size="1.8em" sx={{ marginLeft: "2em" }} />
                  )}
                </Box>
              ) : (
                <Box>
                  <Button
                    disabled={hasPrivs ? false : true}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDeletingConcept(true)}
                    sx={{ float: "left", gap: 0.5 }}
                  >
                    Delete Concept
                    <Delete />
                  </Button>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onHide()}
              sx={{ float: "right", gap: 0.5 }}
            >
              Cancel
              <Close />
            </Button>
            {!createConceptMode && <EditConceptButton />}
            {createConceptMode && <CreateConceptButton />}
          </Box>
        </Box>
      ) : null}
    </Fragment>
  );
};

export default CMConceptModalContent;

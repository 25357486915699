import { useState, useMemo, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import { CTX as mainCTX } from "../../utils/ContextStore";
import { getTagIcon } from "src/utils/helpers";

import {
  Box,
  Typography,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  ChevronRight,
  Clear,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import { FaWikipediaW, FaBarcode, FaMapMarkedAlt } from "react-icons/fa";

import CMMap from "../Map/CMMap";

interface IProps {
  tags: any[];
  getConcept: any;
  deleteTag: (item: any) => void;
  setChosenConcept: (item: any) => void;
  setEditConceptModalShow: (item: any) => void;
  disabled?: boolean;
  type?: string;
  isEntity?: boolean;
  tagQualityAssurance?: boolean;
  tagQueueArticle?: any;
  tagQueueProps?: any;
}

const getTagColor = (weight: number, alpha: number = 1) => {
  return weight >= 0.9
    ? `rgba(87, 227, 158, ${alpha})`
    : weight < 0.9 && weight >= 0.8
    ? `rgba(177, 227, 87, ${alpha})`
    : weight < 0.8 && weight >= 0.7
    ? `rgba(255, 237, 115, ${alpha})`
    : weight < 0.7 && weight >= 0.6
    ? `rgba(255, 173, 117, ${alpha})`
    : `rgba(255, 133, 117, ${alpha})`;
};

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: theme.shadows[4],
  },
}))(Tooltip);

const TagDisplayer = (props: IProps) => {
  const {
    disabled,
    tags,
    tagQueueArticle,
    tagQueueProps = {},
    getConcept,
    deleteTag,
    setChosenConcept,
    type,
    isEntity,
    tagQualityAssurance,
  } = props;

  const {
    categories,
    topics,
    entities,
    recentConcepts,
    setRecentConcepts,
    setEditConceptModalShow,
  }: any = useContext(tagCTX);
  const { enableTagGrouping } = useContext(mainCTX);

  const [isLoadingBroader, setIsLoadingBroader] = useState<boolean>(false);
  const [hoveredBroader, setHoveredBroader] = useState<any[]>([]);

  const getBroaderConceptsFallback = async (concept: any) => {
    let targetUuid = concept.broader;
    if (!targetUuid) {
      return [];
    }
    setIsLoadingBroader(true);
    let res: any[] = [];
    let targetRes: any = await getConcept({
      variables: { uuid: targetUuid },
    });
    let target: any = null;
    if (
      !targetRes?.data?.getConcept?.error &&
      targetRes?.data?.getConcept?.result?.length > 0
    ) {
      target = targetRes.data.getConcept.result[0];
      res.push(target);
    }
    while (target?.broader) {
      targetRes = await getConcept({
        variables: { uuid: target.broader },
      });
      if (
        !targetRes?.data?.getConcept?.error &&
        targetRes?.data?.getConcept?.result?.length > 0
      ) {
        target = targetRes.data.getConcept.result[0];
        res.push(target);
      } else {
        break;
      }
    }
    setIsLoadingBroader(false);
    return res;
  };

  const getBroaderConcepts = async (concept: any) => {
    if (!concept.broader) return [];
    let res: any[] = [];

    let target = recentConcepts.find(
      (broader: any) => broader.uuid === concept.broader
    );

    if (target) {
      res.push(target);
      while (target?.broader) {
        target = recentConcepts.find(
          (broader: any) => broader.uuid === target.broader // eslint-disable-line no-loop-func
        );
        if (target) {
          res.push(target);
        } else {
          break;
        }
      }
    } else {
      res = await getBroaderConceptsFallback(concept);
      // add to recent concepts if not already there
      if (res.length > 0) {
        res.forEach((broader: any) => {
          if (!recentConcepts.some((c: any) => c.uuid === broader.uuid)) {
            setRecentConcepts((prev: any) => [...prev, broader]);
          }
        });
      }
    }
    return res;
  };

  const mergeGroups = (groups: string[][]): string[][] => {
    const merged: Set<string>[] = [];
    groups.forEach((group) => {
      let mergedIndexes: number[] = [];
      let newGroup: Set<string> = new Set(group);
      merged.forEach((existingGroup, index) => {
        if ([...existingGroup].some((item) => newGroup.has(item))) {
          mergedIndexes.push(index);
          existingGroup.forEach((item) => newGroup.add(item));
        }
      });
      mergedIndexes.reverse().forEach((index) => merged.splice(index, 1));
      merged.push(newGroup);
    });
    return merged.map((set) => [...set]);
  };

  const sortedTagGroups = useMemo(() => {
    if (!tags || tags.length < 1) return [];
    if (!enableTagGrouping) {
      return tags.map((tag: any) => [tag]);
    }
    const allowedTypes =
      isEntity || type === "entity"
        ? ["person", "place", "organisation", "event", "object"]
        : type === "category" || type === "topic"
        ? [type]
        : [tags[0].type];
    const tagGroupsUuid: any = tags.map((tag: any) => {
      if (tag.broader && tags.some((t: any) => t.uuid === tag.broader)) {
        return [tag.uuid, tag.broader];
      }
      return [tag.uuid];
    });
    const mergedGroups: any = mergeGroups(tagGroupsUuid);
    const res: any = mergedGroups.map((group: any) => {
      return group
        .map((uuid: any) => tags.find((tag: any) => tag.uuid === uuid))
        .filter((tag: any) =>
          allowedTypes.includes(tag.type.replace("x-im/", ""))
        );
    });

    res.forEach((group: any) => {
      group
        .sort((a: any, b: any) => {
          if (a.broader === b.uuid) return 1;
          if (b.broader === a.uuid) return -1;
          return 0;
        })
        .reverse();
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, enableTagGrouping]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, pb: 2 }}>
      {sortedTagGroups.map((group: any, i: number) => (
        <Box
          key={i}
          component={group.length < 2 ? "span" : Paper}
          elevation={3}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5,
            boxSizing: "border-box",
            p: group.length < 2 ? undefined : 0.4,
            borderBottom:
              group.length < 2
                ? undefined
                : "2px solid rgba(89, 107, 191, 0.6)",
            borderRadius: group.length < 2 ? undefined : "0.25rem",
            bgcolor: group.length < 2 ? undefined : "rgba(89, 107, 191, 0.15)",
          }}
        >
          {group?.map((tag: any, i: number) => (
            <Box
              key={tag.uuid}
              component="span"
              sx={{ bgcolor: "#fff", borderRadius: "0.25rem" }}
            >
              <LightTooltip
                placement="top"
                arrow
                disableInteractive
                title={
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 4,
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        {tag.title}
                      </Typography>
                      <Typography
                        sx={{
                          borderBottom: !isEntity
                            ? `2px solid ${getTagColor(tag.weight, 1)}`
                            : undefined,
                        }}
                      >
                        {`(${
                          !isEntity
                            ? (100 * tag.weight).toFixed(0) + "%"
                            : tag.weight
                        })`}
                      </Typography>
                    </Box>

                    {isLoadingBroader ? <CircularProgress size="1em" /> : null}

                    {hoveredBroader.length > 0 && (
                      <Box>
                        {hoveredBroader.map((broader: any, i: number) => {
                          return i === 0 ? (
                            <Typography
                              key={i}
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                fontSize: "0.8rem",
                                color: "#555",
                                fontWeight: 700,
                                gap: 0.5,
                              }}
                            >
                              <SubdirectoryArrowRight
                                sx={{
                                  mb: 0.5,
                                }}
                                fontSize="small"
                              />
                              <Box
                                component="span"
                                sx={{
                                  display: "flex",
                                  gap: 0.25,
                                  alignItems: "center",
                                }}
                              >
                                {getTagIcon(broader.type, undefined, "1rem")}
                                {broader.title}
                              </Box>
                            </Typography>
                          ) : (
                            <Typography
                              key={i}
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                fontSize: "0.8rem",
                                color: "#555",
                                fontWeight: 700,
                                gap: 0.5,
                              }}
                            >
                              <ChevronRight fontSize="small" />
                              <Box
                                component="span"
                                sx={{
                                  display: "flex",
                                  gap: 0.25,
                                  alignItems: "center",
                                }}
                              >
                                {getTagIcon(broader.type, undefined, "1rem")}
                                {broader.title}
                              </Box>
                            </Typography>
                          );
                        })}
                      </Box>
                    )}
                    {tag.shortDescription && (
                      <Typography component="div" variant="subtitle2">
                        <Box mt={0.5}>{tag.shortDescription}</Box>
                      </Typography>
                    )}
                    {tag.aliases?.length > 0 && (
                      <Typography
                        component="div"
                        variant="caption"
                        sx={{ color: "#666", mt: 0.5 }}
                      >
                        <Box component="span" sx={{ fontWeight: 700 }}>
                          Aliases:{" "}
                        </Box>
                        {tag.aliases.map((alias: String, index: Number) => {
                          return index !== 0 ? ", " + alias : alias;
                        })}
                      </Typography>
                    )}
                    {tag.source && (
                      <Typography
                        component="div"
                        variant="caption"
                        sx={{ color: "#666", mt: 0.5 }}
                      >
                        <Box component="span" sx={{ fontWeight: 700 }}>
                          Source:{" "}
                        </Box>
                        {tag.source}
                      </Typography>
                    )}
                    {tag.geoJSON &&
                    tag.geoJSON !== "{}" &&
                    (tag.type.includes("place") ||
                      tag.type.includes("organisation")) ? (
                      <Box sx={{ width: "100%", mt: 0.5 }}>
                        <CMMap concept={tag} size="small" />
                      </Box>
                    ) : null}
                  </Box>
                }
              >
                <Chip
                  clickable
                  deleteIcon={
                    <IconButton
                      disabled={disabled}
                      size="small"
                      sx={{ p: 0.2 }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                  label={
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          fontWeight: 700,
                        }}
                      >
                        {getTagIcon(tag.type)}
                        {tag.title}
                      </Box>

                      {isEntity && (
                        <Box pt={0.5}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "23px",
                              width: "100%",
                              gap: 0.5,
                              color: "#777",
                              fontWeight: 700,
                              fontSize: "10px",
                            }}
                          >
                            {tag.wikipedia && (
                              <Tooltip
                                title="Visit Wikipedia"
                                placement="top"
                                disableInteractive
                              >
                                <IconButton
                                  color="primary"
                                  className="interactive"
                                  size="small"
                                  sx={{
                                    color: "rgb(55, 55, 55)",
                                    backgroundColor: "#fff",
                                    border: "1px solid rgba(0,0,0,0.125)",
                                  }}
                                  href={
                                    tag.wikipedia.startsWith("https://")
                                      ? tag.wikipedia
                                      : "https://" + tag.wikipedia
                                  }
                                  target="_blank"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <FaWikipediaW size="0.75em" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {tag.wikidata && (
                              <Tooltip
                                title="Visit Wikidata"
                                placement="top"
                                disableInteractive
                              >
                                <IconButton
                                  className="interactive"
                                  size="small"
                                  sx={{
                                    color: "rgb(55, 55, 55)",
                                    backgroundColor: "#fff",
                                    border: "1px solid rgba(0,0,0,0.125)",
                                  }}
                                  href={
                                    "https://www.wikidata.org/wiki/" +
                                    tag.wikidata
                                  }
                                  target="_blank"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <FaBarcode size="0.75em" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {tag.openStreetMap && (
                              <Tooltip
                                title="Visit OpenStreetMap"
                                placement="top"
                                disableInteractive
                              >
                                <IconButton
                                  className="interactive"
                                  size="small"
                                  sx={{
                                    color: "rgb(55, 55, 55)",
                                    backgroundColor: "#fff",
                                    border: "1px solid rgba(0,0,0,0.125)",
                                  }}
                                  href={tag.openStreetMap}
                                  target="_blank"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <FaMapMarkedAlt size="0.75em" />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Box component="span">
                              weight:
                              <Box
                                component="span"
                                sx={{
                                  ml: 0.4,
                                  color: "#000",
                                  fontWeight: 700,
                                }}
                              >
                                {tag.weight}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  }
                  onMouseEnter={async () => {
                    const broaders = await getBroaderConcepts(tag);
                    setHoveredBroader(broaders);
                  }}
                  onMouseLeave={() => {
                    setHoveredBroader([]);
                  }}
                  onClick={() => {
                    if (disabled) return;
                    setChosenConcept(tag);
                    setEditConceptModalShow(true);
                  }}
                  onDelete={
                    disabled
                      ? undefined
                      : () => {
                          if (tagQualityAssurance)
                            tagQueueArticle &&
                              tagQueueArticle({
                                variables: {
                                  query: {
                                    finished: false,
                                    concepts: [
                                      ...categories,
                                      ...topics,
                                      ...entities,
                                    ].filter(
                                      (concept: any) =>
                                        concept.uuid !== tag.uuid
                                    ),
                                    customQueue: false,
                                    queueName: undefined,
                                    queueNr: undefined,
                                    ...tagQueueProps,
                                  },
                                },
                                onCompleted: () => {
                                  deleteTag(tag);
                                },
                              });
                          else deleteTag(tag);
                        }
                  }
                  sx={{
                    "& .MuiChip-label": {
                      pl: 1,
                    },
                    py: 0.5,
                    height: "100%",
                    borderRadius: "0.25rem",
                    boxShadow: "2px 2px 6px -4px rgba(77, 92, 105)",
                    bgcolor: !isEntity
                      ? getTagColor(tag.weight, 0.25)
                      : "rgba(232, 237, 242, 1)",
                    "&:hover": {
                      bgcolor: !isEntity
                        ? getTagColor(tag.weight, 0.5)
                        : "rgba(232, 237, 242, 0.5)",
                    },
                  }}
                />
              </LightTooltip>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TagDisplayer;

import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { IExportQueueData } from "src/utils/typeDefs";

import { Button, Tooltip } from "@mui/material";
import { FileDownload } from "@mui/icons-material";

import EXPORT_ARTICLE_QUEUE from "src/queries/EXPORT_ARTICLE_QUEUE";

interface IProps {
  queueName: string | undefined;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  callback?: () => void;
}

const ExportJsonButton = (props: IProps) => {
  const { queueName, size, fullWidth, callback } = props;

  const [exportArticleQueue] = useLazyQuery(EXPORT_ARTICLE_QUEUE, {
    fetchPolicy: "network-only",
  });

  const exportData = () => {
    exportArticleQueue({
      variables: {
        query: { customQueue: queueName ? true : false, queueName: queueName },
      },
      onCompleted: (data: { exportArticleQueue: IExportQueueData }) => {
        const exportData = data?.exportArticleQueue;

        if (!exportData) return;
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(exportData.queue)
        )}`;
        const link = document.createElement("a");
        const date = moment().format("YYYYMMDD");
        const channels = exportData?.channels?.join("-") ?? "";
        link.href = jsonString;
        link.download = `PAT_${date}${channels ? "_" + channels : ""}_${
          exportData.queueName
        }.json`;
        link.click();
      },
      onError: (err) => {
        alert("Failed to export articles: " + err.message);
      },
    });
    callback && callback();
  };

  return (
    <Tooltip
      arrow
      disableInteractive
      title="Download the current queue as JSON file"
      enterDelay={500}
    >
      <span>
        <Button
          disabled={!queueName}
          variant="contained"
          size={size}
          fullWidth={fullWidth}
          color="success"
          onClick={exportData}
          sx={{ gap: 0.5 }}
        >
          <FileDownload /> Export JSON
        </Button>
      </span>
    </Tooltip>
  );
};

export default ExportJsonButton;

import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import moment from "moment";
import { IArticle } from "src/utils/typeDefs";

interface IProps {
  article?: IArticle | null;
}

const ArticleDisplayer = (props: IProps) => {
  const { article } = props;

  if (!article) return <Box sx={{ px: 2, py: 1 }} />;

  return (
    <Box sx={{ px: 2, py: 0.5 }}>
      {(article.authors?.length > 0 || article.channels?.length > 0) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {article.authors?.length > 0 && (
              <>
                <AvatarGroup
                  max={2}
                  total={article.authors.length}
                  sx={{ mr: 0.5 }}
                >
                  {article.authors.map((author: string, i: number) => (
                    <Avatar
                      key={i}
                      alt=""
                      src=""
                      sx={{ width: 22, height: 22 }}
                    />
                  ))}
                </AvatarGroup>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    borderRight:
                      article.channels?.length > 0
                        ? "2px solid rgba(0,0,0,0.125)"
                        : "none",
                    pr: article.channels?.length > 0 ? 1.5 : 0,
                    mr: article.channels?.length > 0 ? 1.5 : 0,
                  }}
                >
                  {article.authors.join(", ")}
                </Typography>
              </>
            )}
            {article.channels?.length > 0 && (
              <Typography variant="caption" sx={{ fontWeight: 700 }}>
                {article.channels.join(", ")}
              </Typography>
            )}
          </Box>
          {article?.queueNr && (
            <Avatar
              sx={{
                height: 30,
                width: 30,
                ml: 1.5,
                mr: -1,
                bgcolor: "primary.main",
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              <span style={{ fontWeight: 500, fontSize: 8 }}>#</span>
              {article.queueNr}
            </Avatar>
          )}
        </Box>
      )}

      {article.publicationTimestamp && (
        <Typography component="div" variant="caption" sx={{ mt: 0.5 }}>
          {moment
            .parseZone(article.publicationTimestamp)
            .format("YYYY-MM-DD, HH:mm z")}
        </Typography>
      )}

      <Typography
        variant="h1"
        sx={{
          fontFamily: "inherit",
          fontSize: "36px",
          fontWeight: 700,
          mb: 2,
          mt: 1,
        }}
      >
        {article.headline}
      </Typography>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "inherit",
          fontSize: "24px",
          mb: 2,
        }}
      >
        {article.preamble}
      </Typography>
      {article.body?.map((text: string, i: number) => (
        <Typography
          key={i}
          variant="body1"
          sx={{ fontFamily: "inherit", fontSize: "16px", mb: 1.5 }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
};

export default ArticleDisplayer;

import { Fragment, useState, useEffect, useContext } from "react";
import "./AutoTaggingDemo.css";
import { Authenticator } from "../../utils/Authenticator";
import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";
import { CTX as tagCTX } from "./TagContext";
import { IUserQueueData } from "../../utils/typeDefs";

import { Table, Modal } from "react-bootstrap";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Alert,
  CircularProgress,
  LinearProgress,
  Chip,
  Paper,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  GridView,
  ExpandMore,
  ExpandLess,
  Check,
  Delete,
  Search,
  NavigateNext,
  Description,
  Grain,
  CheckCircle,
  Close,
} from "@mui/icons-material";

import SEARCH_ARTICLES from "../../mutations/searchArticlesMutation";
import GET_QUEUE_ARTICLE from "../../queries/GET_QUEUE_ARTICLE";
import UPDATE_QUEUE_ARTICLE from "../../mutations/UPDATE_QUEUE_ARTICLE";
import GET_CONCEPT from "../../mutations/GET_CONCEPT";

import Editor from "./Editor";
import CMConceptModal from "../ConceptManagement/CMConceptModal";
import DatasetPicker from "../PanelComponents/DatasetPicker";
import TagInfo from "./TagInfo";
import SearchTagsComponent from "./SearchTagsComponent";
import GenderDisplayer from "./GenderDisplayer";
import ImageDisplayer from "./ImageDisplayer";
import QueueStatisticsDisplayer from "./TagQualityAssurance/QueueStatisticsDisplayer";
import SkipButton from "./TagQualityAssurance/SkipButton";
import PlayDemoButton from "./PlayDemoButton";
import LimitSelector from "./LimitSelector";
import AdvancedSettingComponent from "./AdvancedSettingComponent";
import TagDisplayer from "./TagDisplayer";
import ArticleDisplayer from "../PAT/ArticleDisplayer";
import MultiChannelInput from "../components/MultiChannelInput";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    maxHeight: "400px",
  },
});

interface IProps {
  tagQualityAssurance?: boolean;
}

const AutotaggingDemo = (props: IProps) => {
  const { tagQualityAssurance } = props;

  const { activeEndpoint, prevEndpoint, showImageDisplayer }: any =
    useContext(CTX);

  const [searchShow, setSearchShow] = useState<boolean>(false);

  const [searchArticleString, setSearchArticleString] = useState<string>("");

  const [chosenConcept, setChosenConcept] = useState<any>({});
  const [addedConcept, setAddedConcept] = useState<any>(undefined);
  const [modifiedConcept, setModifiedConcept] = useState<any>(undefined);
  const [deletedConcept, setDeletedConcept] = useState<any>(undefined);

  const [retrievedArticles, setRetrievedArticles] = useState([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const [submittedSearchString, setSubmittedSearchString] = useState("");

  const [userQueueData, setUserQueueData] = useState<IUserQueueData>({
    customQueue: false,
    queueName: undefined,
    queueNr: undefined,
    articleUuid: "",
    articleFinished: undefined,
  });

  const [getConcept] = useMutation(GET_CONCEPT);
  const [getQueueArticle, { loading: loadingNextArticle }] = useLazyQuery(
    GET_QUEUE_ARTICLE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const article = data.getQueueArticle;
        setUserQueueData({
          ...userQueueData,
          queueNr: data.getQueueArticle.queueNr,
          articleUuid: data.getQueueArticle.uuid,
          article: article,
          articleFinished: data.getQueueArticle.finished,
        });
        let categoryList: any = [];
        let topicList: any = [];
        let entityList: any = [];
        if (article?.concepts && article.concepts.length > 0) {
          article.concepts.forEach((item: any) => {
            if (item.type === "category" || item.type === "x-im/category") {
              categoryList = [...categoryList, formatTag(item)];
            } else if (item.type === "topic" || item.type === "x-im/topic") {
              topicList = [...topicList, formatTag(item)];
            } else {
              entityList = [...entityList, formatTag(item)];
            }
          });
        }
        JSON.stringify(categoryList) !== JSON.stringify(categories) &&
          setCategories(categoryList);
        JSON.stringify(topicList) !== JSON.stringify(topics) &&
          setTopics(topicList);
        JSON.stringify(entityList) !== JSON.stringify(entities) &&
          setEntities(entityList);
      },
      onError: (e: Error) => {
        setUserQueueData({
          ...userQueueData,
          queueNr: undefined,
          articleUuid: undefined,
          article: undefined,
          articleFinished: undefined,
        });
        setTagError({
          error: true,
          message:
            e?.message ?? "Failed to fetch article, please contact iMatrics",
        });
      },
    }
  );

  const [updateQueueArticle, { loading: loadingUpdateArticle }] = useMutation(
    UPDATE_QUEUE_ARTICLE,
    {
      onError: (e: Error) => {
        setTagError({
          error: true,
          message:
            e?.message ?? "Failed to update article, please contact iMatrics",
        });
      },
    }
  );
  const [searchArticles] = useMutation(SEARCH_ARTICLES);

  const newEntity = {
    uuid: "",
    title: "",
    type: "person",
    shortDescription: "",
    longDescription: "",
    aliases: [],
    broader: "",
  };

  const newTopic = {
    uuid: "",
    title: "",
    type: "topic",
    shortDescription: "",
    longDescription: "",
    broader: "",
  };

  const slideSpeed = 400;

  const {
    categories,
    setCategories,
    topics,
    setTopics,
    entities,

    setEntities,
    editorText,
    setEditorText,
    textEditor,

    tagError,
    setTagError,

    tagSettings,
    setTagSettings,
    articleFormat,

    formatTag,
    clearTags,
    setTagQualityAssurance,
    isPlaying,
    setIsPlaying,
    taggingTimeout,

    hideCategories,
    setHideCategories,
    hideTopics,
    setHideTopics,
    hideEntities,
    setHideEntities,

    addConcept,
    deleteCategory,
    deleteTopic,
    deleteEntity,

    editConceptModalShow,
    setEditConceptModalShow,

    createTopicModalShow,
    setCreateTopicModalShow,
    createConceptModalShow,
    setCreateConceptModalShow,

    selectedDemoArticle,

    channels,
    setChannels,
  }: any = useContext(tagCTX);

  useEffect(() => {
    setTagQualityAssurance(tagQualityAssurance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //##### TRIGGER RETAG AFTER SWITCHING ENDPOINT #####
    if (activeEndpoint?.url === prevEndpoint?.url) {
      return;
    }
    clearTags();
    if (!isPlaying)
      textEditor?.onEditorChange(
        editorText,
        undefined,
        100,
        {
          ...tagSettings,
        },
        { channels: channels }
      );
    if (tagQualityAssurance) {
      setEditorText("");
      stopDemo();
      setUserQueueData({
        ...userQueueData,
        queueNr: undefined,
        articleUuid: undefined,
        article: undefined,
        articleFinished: undefined,
      });
      clearTags();
    }
    if (tagError.error) {
      setTagError({
        error: false,
        message: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  useEffect(() => {
    //##### TRIGGER RETAG AFTER CHANGING TAG SETTINGS OR CHANNEL #####
    if (!isPlaying)
      textEditor?.onEditorChange(
        editorText,
        null,
        100,
        {
          ...tagSettings,
        },
        { channels: channels }
      );
    if (tagError.error) {
      setTagError({
        error: false,
        message: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSettings, channels]);

  useEffect(() => {
    hideCategories
      ? $("#categories").slideUp(slideSpeed)
      : $("#categories").slideDown(slideSpeed);
    hideTopics
      ? $("#topics").slideUp(slideSpeed)
      : $("#topics").slideDown(slideSpeed);
    hideEntities
      ? $("#entities").slideUp(slideSpeed)
      : $("#entities").slideDown(slideSpeed);
  }, [hideCategories, hideTopics, hideEntities]);

  useEffect(() => {
    // Triggers when Play Demo is pressed
    const demoTitle: string = selectedDemoArticle?.headline || "";
    const demoText: string[] = selectedDemoArticle?.body || [];
    if (isPlaying) {
      clearTags();
      let i = 0;
      let j = 0;
      let textIndex = 0;
      let textInterval: any;
      let titleInterval: any;
      let intervalTimeout: any;
      let latestTaggedText = "";
      clearTimeout(taggingTimeout.current);
      titleInterval = setInterval(() => {
        if (demoTitle && i !== demoTitle.length) {
          setEditorText("<h1>" + demoTitle.substring(0, i) + "</h1>");
          i++;
        } else {
          latestTaggedText = "<h1>" + demoTitle + "</h1>";
          setEditorText(latestTaggedText);
          textEditor?.onEditorChange(latestTaggedText, null, 10);
          clearInterval(titleInterval);

          intervalTimeout = setTimeout(() => {
            textInterval = setInterval(() => {
              if (demoText?.length && textIndex > demoText.length - 1) {
                setIsPlaying(false);
                clearInterval(textInterval);
              } else if (
                demoText?.length !== 0 &&
                demoText[textIndex]?.length > 0 &&
                j <= demoText[textIndex]?.length
              ) {
                if (j === demoText[textIndex]?.length) {
                  latestTaggedText =
                    latestTaggedText + "<p>" + demoText[textIndex] + "</p>";
                  setEditorText(latestTaggedText);
                  textEditor?.onEditorChange(latestTaggedText, null, 10);
                  textIndex++;
                  j = 0;
                  return;
                }
                setEditorText(
                  latestTaggedText +
                    "<p>" +
                    demoText[textIndex].substring(0, j) +
                    "</p>"
                );
                j++;
              }
            }, 15);
          }, 1000);
        }
      }, 40);
      return () => {
        clearInterval(titleInterval);
        clearInterval(textInterval);
        clearTimeout(intervalTimeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const stopDemo = () => {
    clearTimeout(taggingTimeout.current);
    setIsPlaying(false);
    setEditorText("");
    clearTags();
    textEditor?.focus();
  };

  const clearAll = () => {
    clearTimeout(taggingTimeout.current);
    setIsPlaying(false);
    setEditorText("");
    setChannels([]);
    clearTags();
    textEditor?.focus();
  };

  const searchArticlesInput = (
    <form
      autoComplete="off"
      onSubmit={(e: any) => {
        e.preventDefault();
        setSubmittedSearchString("");
        if (!searchArticleString) {
          return;
        }
        setIsLoadingArticles(true);
        searchArticles({
          variables: {
            searchString: searchArticleString,
            returnSize: 20,
          },
        })
          .then((data: any) => {
            data?.data?.searchArticlesMutation &&
              setRetrievedArticles(data.data.searchArticlesMutation);
          })
          .catch((err: any) => {
            setRetrievedArticles([]);
          })
          .finally(() => {
            setSubmittedSearchString(searchArticleString);
            setIsLoadingArticles(false);
          });
      }}
      style={{ display: "inline-block", width: "50%", padding: "0 1rem" }}
    >
      <TextField
        autoComplete="off"
        autoFocus
        fullWidth
        value={searchArticleString}
        label="Search article"
        type="text"
        variant="filled"
        size="small"
        InputLabelProps={{
          shrink: true,
          sx: { fontWeight: 700 },
        }}
        onChange={(e) => setSearchArticleString(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Search articles"
                placement="top"
                arrow
                disableInteractive
                enterDelay={500}
              >
                <IconButton type="submit" edge="end">
                  <Search />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );

  const TagDisplayers = () => (
    <Box>
      <Paper sx={{ mb: 2, width: "100%", zIndex: 5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 0.5,
            px: 1,
            bgcolor: "#f5f5f5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", fontWeight: 700 }}>
            <Description sx={{ mr: 1 }} />
            Categories {categories.length > 0 && `(${categories.length})`}
          </Box>
          {!tagQualityAssurance && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LimitSelector
                value={
                  tagSettings.categories === -1 ||
                  !Number.isInteger(tagSettings.categories)
                    ? ""
                    : tagSettings.categories
                }
                onChange={(e: any) => {
                  setTagSettings({
                    ...tagSettings,
                    categories: parseInt(e.target.value),
                  });
                }}
                options={[
                  { label: "Default", value: -1 },
                  { label: "0", value: 0 },
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
              />

              {hideCategories ? (
                <Tooltip
                  title="Show categories"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideCategories(false);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Hide categories"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideCategories(true);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandLess />
                  </IconButton>
                </Tooltip>
              )}
              <TagInfo type="category" />
            </Box>
          )}
        </Box>

        <Box id="categories" sx={{ p: 1 }}>
          <TagDisplayer
            tags={categories}
            getConcept={getConcept}
            deleteTag={deleteCategory}
            setChosenConcept={setChosenConcept}
            setEditConceptModalShow={setEditConceptModalShow}
            type="category"
            isEntity={false}
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
          <SearchTagsComponent
            type="category"
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
        </Box>
      </Paper>

      <Paper sx={{ mb: 2, width: "100%", zIndex: 4 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 0.5,
            px: 1.5,
            bgcolor: "#f5f5f5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", fontWeight: 700 }}>
            <Grain sx={{ mr: 1 }} />
            Topics {topics.length > 0 && `(${topics.length})`}
          </Box>
          {!tagQualityAssurance && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LimitSelector
                value={
                  tagSettings.topics === -1 ||
                  !Number.isInteger(tagSettings.topics)
                    ? ""
                    : tagSettings.topics
                }
                onChange={(e: any) => {
                  setTagSettings({
                    ...tagSettings,
                    topics: parseInt(e.target.value),
                  });
                }}
                options={[
                  { label: "Default", value: -1 },
                  { label: "0", value: 0 },
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                  { label: "6", value: 6 },
                  { label: "7", value: 7 },
                  { label: "8", value: 8 },
                  { label: "9", value: 9 },
                  { label: "10", value: 10 },
                ]}
              />
              {hideTopics ? (
                <Tooltip
                  title="Show topics"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideTopics(false);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Hide topics"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideTopics(true);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandLess />
                  </IconButton>
                </Tooltip>
              )}
              <TagInfo type="topic" />
            </Box>
          )}
        </Box>

        <Box id="topics" sx={{ p: 1 }}>
          <TagDisplayer
            tags={topics}
            getConcept={getConcept}
            deleteTag={deleteTopic}
            setChosenConcept={setChosenConcept}
            setEditConceptModalShow={setEditConceptModalShow}
            type="topic"
            isEntity={false}
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
          <SearchTagsComponent
            type="topic"
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
        </Box>
      </Paper>

      <Paper sx={{ width: "100%", zIndex: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 0.5,
            px: 1.5,
            bgcolor: "#f5f5f5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", fontWeight: 700 }}>
            <GridView sx={{ mr: 1 }} />
            Entities {entities.length > 0 && `(${entities.length})`}
          </Box>
          {!tagQualityAssurance && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LimitSelector
                value={
                  tagSettings.entities === -1 ||
                  !Number.isInteger(tagSettings.entities)
                    ? ""
                    : tagSettings.entities
                }
                onChange={(e: any) => {
                  setTagSettings({
                    ...tagSettings,
                    entities: parseInt(e.target.value),
                  });
                }}
                options={[
                  { label: "Default", value: -1 },
                  { label: "0", value: 0 },
                  { label: "5", value: 5 },
                  { label: "10", value: 10 },
                  { label: "15", value: 15 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
              />
              {hideEntities ? (
                <Tooltip
                  title="Show entities"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideEntities(false);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Hide entities"
                  placement="top"
                  arrow
                  disableInteractive
                  enterDelay={500}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setHideEntities(true);
                    }}
                    sx={{ float: "right" }}
                  >
                    <ExpandLess />
                  </IconButton>
                </Tooltip>
              )}
              <TagInfo type="entity" />
            </Box>
          )}
        </Box>

        <Box id="entities" sx={{ p: 1 }}>
          <TagDisplayer
            tags={entities}
            getConcept={getConcept}
            deleteTag={deleteEntity}
            setChosenConcept={setChosenConcept}
            setEditConceptModalShow={setEditConceptModalShow}
            type="entity"
            isEntity={true}
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
          <SearchTagsComponent
            type="entity"
            tagQualityAssurance={tagQualityAssurance}
            tagQueueArticle={updateQueueArticle}
            tagQueueProps={{
              customQueue: userQueueData.customQueue,
              queueName: userQueueData.queueName,
              queueNr: userQueueData.queueNr,
              finished: false,
            }}
          />
        </Box>
      </Paper>
    </Box>
  );

  const NextArticleButton = () => (
    <Tooltip
      arrow
      disableInteractive
      title="Fetch the next unassigned article from the queue"
      enterDelay={500}
    >
      <span>
        <Button
          disabled={
            loadingNextArticle || userQueueData.articleFinished === false
          }
          variant="contained"
          color="primary"
          onClick={() => {
            setTagError({
              error: false,
              message: "",
            });
            getQueueArticle({
              fetchPolicy: "network-only",
              variables: {
                query: {
                  customQueue: userQueueData.customQueue,
                  queueName: userQueueData.queueName,
                },
              },
            });
          }}
          sx={{ gap: 0.5, alignItems: "center" }}
        >
          {loadingNextArticle ? (
            <CircularProgress size="23px" sx={{ p: "4px" }} />
          ) : (
            <NavigateNext />
          )}
          Fetch article
        </Button>
      </span>
    </Tooltip>
  );

  const SearchArticlesModal = (
    <Box>
      <Button
        variant="contained"
        color="warning"
        onClick={() => {
          setSearchShow(true);
        }}
        sx={{ mr: 2, gap: 0.5 }}
      >
        <Search />
        Search article
      </Button>

      <Modal
        id="modal"
        size="xl"
        show={searchShow}
        animation={false}
        onHide={() => setSearchShow(false)}
      >
        <Modal.Header>
          <Typography variant="h5">Search articles</Typography>

          <IconButton
            onClick={() => {
              setSearchShow(false);
            }}
            sx={{ position: "absolute", right: 12, top: 12 }}
          >
            <Close />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, minHeight: "33vh" }}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {searchArticlesInput}
            {submittedSearchString && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {`${retrievedArticles?.length}`}
                &nbsp;results found for&nbsp;
                <b>{`${submittedSearchString}`}</b>
              </Typography>
            )}
          </Box>
          <Box sx={{ position: "relative" }}>
            <LinearProgress
              color="primary"
              variant={isLoadingArticles ? "indeterminate" : "determinate"}
              value={0}
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                visibility: isLoadingArticles ? "visible" : "hidden",
                zIndex: 1,
              }}
            />
            <Table striped bordered hover style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Title</th>
                  <th style={{ width: "40%" }}>Preamble</th>
                  <th style={{ width: "35%" }}>Tags</th>
                </tr>
              </thead>
              <tbody>
                {retrievedArticles?.length > 0 &&
                  retrievedArticles.map((article: any) => (
                    <Fragment key={article.uuid}>
                      <CustomTooltip
                        arrow
                        placement="bottom"
                        disableInteractive
                        enterDelay={500}
                        title={
                          <Box>
                            <Box
                              sx={{
                                borderBottom: "1px solid rgba(255,255,255,0.4)",
                                pb: 0.2,
                                mb: 0.4,
                              }}
                            >
                              {article.uuid}
                            </Box>
                            <b>{article.headline}</b>
                            <Box>
                              {article.body.join(" ") !== null ? (
                                <span>{article.body.join(" ")}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No article found
                                </span>
                              )}
                            </Box>
                          </Box>
                        }
                      >
                        <tr
                          className="article-table-row"
                          onClick={() => {
                            setEditorText(
                              "<h1>" +
                                article.headline +
                                "</h1>" +
                                "<h2>" +
                                article.preamble +
                                "</h2>" +
                                "<p>" +
                                article.body.join(" ") +
                                "</p>"
                            );
                            setChannels((prev: string[]) => [
                              ...prev,
                              ...article.channels,
                            ]);
                            setSearchShow(false);
                          }}
                        >
                          <td>
                            <span className="font-weight-bold">
                              {article.headline}
                            </span>
                          </td>
                          <td>
                            <span>
                              {article.preamble === " " ||
                              article.preamble === ""
                                ? article.body[0]
                                : article.preamble}
                            </span>
                          </td>
                          <td>
                            {article.concepts?.map((concept: any) => (
                              <Chip
                                key={concept.uuid}
                                label={concept.title}
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 0.5, mb: 0.5 }}
                              />
                            ))}
                          </td>
                        </tr>
                      </CustomTooltip>
                    </Fragment>
                  ))}
              </tbody>
            </Table>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );

  const DoneButton = () => (
    <Tooltip
      arrow
      disableInteractive
      title="Mark article as finished with the specified tags"
      enterDelay={500}
    >
      <span>
        <Button
          disabled={
            loadingNextArticle ||
            loadingUpdateArticle ||
            !userQueueData.article ||
            userQueueData.articleFinished
          }
          variant="contained"
          color="success"
          onClick={() => {
            if (
              tagQualityAssurance &&
              Authenticator.hasAuthority([PRIVILEGES.QUEUE])
            ) {
              updateQueueArticle({
                variables: {
                  query: {
                    finished: true,
                    concepts: [...categories, ...topics, ...entities],
                    customQueue: userQueueData.customQueue,
                    queueName: userQueueData.queueName,
                    queueNr: userQueueData.queueNr,
                  },
                },
                onCompleted: (data) => {
                  setUserQueueData({
                    ...userQueueData,
                    queueNr: undefined,
                    articleUuid: undefined,
                    article: undefined,
                    articleFinished: undefined,
                  });
                  clearTags();
                },
              });
            }
          }}
          sx={{ gap: 0.5 }}
        >
          <Check />
          Done
        </Button>
      </span>
    </Tooltip>
  );

  const DemoPanel = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1.5,
        gap: 1,
        borderBottom: "1px solid rgba(0,0,0,0.125)",
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        {tagQualityAssurance ? (
          <Fragment>
            <NextArticleButton />
            <DoneButton />
            <SkipButton
              disabled={
                loadingNextArticle ||
                loadingUpdateArticle ||
                !userQueueData.article ||
                userQueueData.articleFinished
              }
              tagQueueArticle={updateQueueArticle}
              tagQueueProps={{
                customQueue: userQueueData.customQueue,
                queueName: userQueueData.queueName,
                queueNr: userQueueData.queueNr,
              }}
              tagQueueArticleCallback={(data: any) => {
                setUserQueueData({
                  ...userQueueData,
                  queueNr: undefined,
                  articleUuid: undefined,
                  article: undefined,
                  articleFinished: undefined,
                });
                clearTags();
              }}
            />
            <QueueStatisticsDisplayer userQueueData={userQueueData} />

            <Tooltip
              arrow
              disableInteractive={false}
              title={
                <span>
                  <div>
                    Current queue number:{" "}
                    <b>{userQueueData.queueNr ?? "N/A"}</b>
                  </div>
                  <div>
                    Current article UUID:{" "}
                    <b>{userQueueData.articleUuid ?? "N/A"}</b>
                  </div>
                </span>
              }
              enterDelay={500}
            >
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  maxWidth: 160,
                  maxHeight: 33,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="overline"
                  sx={{ lineHeight: 1, display: "flex", gap: 0.5 }}
                >
                  Current:
                  <b>{userQueueData.queueNr ?? "N/A"}</b>
                </Typography>
                {userQueueData.queueName && (
                  <CheckCircle
                    color={
                      userQueueData.articleFinished ? "success" : "disabled"
                    }
                  />
                )}
              </Paper>
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment>
            {activeEndpoint?.isDemoEnvironment && (
              <PlayDemoButton stopDemo={stopDemo} />
            )}

            <Button variant="contained" onClick={clearAll} sx={{ gap: 0.5 }}>
              <Delete />
              Clear all
            </Button>
            {!activeEndpoint?.isDemoEnvironment && SearchArticlesModal}
          </Fragment>
        )}
      </Box>

      <DatasetPicker />
    </Box>
  );

  useEffect(() => {
    if (addedConcept) {
      setTimeout(() => {
        getConcept({ variables: addedConcept }).then((data: any) => {
          const res = data?.data?.getConcept?.result;
          if (!res || res?.length < 1) {
            return;
          }
          const newConcept = res[0];
          addConcept(newConcept);
        });
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedConcept]);

  useEffect(() => {
    if (modifiedConcept?.uuid) {
      let newEntities: any[] = entities.map((entity: any) =>
        entity.uuid === modifiedConcept.uuid
          ? { ...entity, ...modifiedConcept }
          : entity
      );
      setEntities(newEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedConcept]);

  useEffect(() => {
    if (deletedConcept) {
      let newEntities: any[] = entities.filter(
        (entity: any) => entity.uuid !== deletedConcept
      );
      setEntities(newEntities);
      setDeletedConcept(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedConcept]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <CMConceptModal
        show={editConceptModalShow}
        onHide={() => setEditConceptModalShow(false)}
        concept={chosenConcept}
        setModifiedConcept={setModifiedConcept}
        setDeletedConcept={setDeletedConcept}
      />
      <CMConceptModal
        show={createConceptModalShow}
        onHide={() => setCreateConceptModalShow(false)}
        concept={newEntity}
        setAddedConcept={setAddedConcept}
        createConcept
      />
      <CMConceptModal
        show={createTopicModalShow}
        onHide={() => setCreateTopicModalShow(false)}
        concept={newTopic}
        setAddedConcept={setAddedConcept}
        createConcept
      />
      {DemoPanel}

      <Box
        sx={{
          display: "flex",
          flex: 1,
          px: 2,
          pt: 2,
          overflow: "auto",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{ flex: 1, p: 0.25, height: "100%", maxHeight: "100%" }}
          >
            {tagQualityAssurance ? (
              <Paper
                elevation={4}
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  fontFamily: "Crete Round",
                }}
              >
                <LinearProgress
                  sx={{ visibility: loadingNextArticle ? "visible" : "hidden" }}
                />
                <ArticleDisplayer article={userQueueData.article} />
              </Paper>
            ) : (
              <Paper
                elevation={4}
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  fontFamily: "Crete Round",
                }}
              >
                <Editor />
              </Paper>
            )}
          </Grid>

          <Grid
            item
            gap={2}
            xs={12}
            md={5}
            sx={{
              flex: 1,
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                overflowY: "auto",
                p: 0.25,
                flex: 1,
                height: "100%",
                maxHeight: "100%",
              }}
            >
              {tagError.error && (
                <Alert severity="error">{tagError.message}</Alert>
              )}

              {!tagQualityAssurance && <AdvancedSettingComponent />}

              {!tagQualityAssurance && articleFormat === "json" && (
                <MultiChannelInput
                  value={channels}
                  onChange={setChannels}
                  disabled={articleFormat !== "json"}
                />
              )}

              {!tagQualityAssurance &&
                activeEndpoint?.genderAnalysisEnabled && <GenderDisplayer />}

              {TagDisplayers()}

              {!tagQualityAssurance &&
                showImageDisplayer &&
                Authenticator.hasAuthority([PRIVILEGES.IMAGE_TAGGING]) && (
                  <Grid item xs={12}>
                    <ImageDisplayer />
                  </Grid>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AutotaggingDemo;

import { useState } from "react";
import {
  TextField,
  InputAdornment,
  Avatar,
  IconButton,
  Tooltip,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { FaMapMarkedAlt } from "react-icons/fa";

interface Props {
  superAdminMode: boolean;
  newGeoJSON: string;
  setNewGeoJSON: (geo: string) => void;
  setMapModalShow: (show: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapButton: {
      color: "#fff",
      backgroundColor: "rgba(88, 108, 191, 0.7)",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
      height: "36px",
      width: "36px",
    },
  })
);

const GeoInput = (props: Props) => {
  const classes = useStyles();
  const [activeGeoField, setActiveGeoField] = useState(false);
  const { superAdminMode, newGeoJSON, setNewGeoJSON, setMapModalShow } = props;

  return (
    <TextField
      disabled={superAdminMode ? false : true}
      autoComplete="off"
      type="text"
      label="GeoJSON"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      size="medium"
      value={newGeoJSON}
      multiline
      maxRows={activeGeoField ? 8 : 1}
      onFocus={() => setActiveGeoField(true)}
      onBlur={() => setActiveGeoField(false)}
      onChange={(e: any) => {
        setNewGeoJSON(e.target.value);
      }}
      fullWidth
      inputProps={{
        style: { overflow: activeGeoField ? "auto" : "hidden" },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="View map" placement="top">
              <span>
                <IconButton size="small" onClick={() => setMapModalShow(true)}>
                  <Avatar className={classes.mapButton}>
                    <FaMapMarkedAlt />
                  </Avatar>
                </IconButton>
              </span>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default GeoInput;

import { useState, useEffect } from "react";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
  LocalOffer,
} from "@mui/icons-material";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export const useCurrentWidth = () => {
  let [width, setWidth] = useState(getWidth());
  useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return width;
};

export const useCurrentHeight = () => {
  let [height, setHeight] = useState(getHeight());
  useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setHeight(getHeight()), 150);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return height;
};

type iconSize = "small" | "medium" | "large";
type iconType =
  | null
  | string
  | "all"
  | "category"
  | "topic"
  | "entity"
  | "person"
  | "place"
  | "organisation"
  | "event"
  | "object";

/**
 *
 * @param type returns the icon based on the type
 * @param size (optional) size of the icon
 * @param customSize (optional) custom size of the icon. Default small, use medium or large
 * @param style (optional) custom style object of the icon
 * @returns Icon component
 */
export const getTagIcon = (
  type: iconType,
  size: iconSize = "small",
  customSize?: string,
  style: any = {}
) => {
  if (typeof type !== "string") return null;
  return type.includes("all") ? (
    <LocalOffer fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("category") ? (
    <Description fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("topic") ? (
    <Grain fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("entity") ? (
    <GridView fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("person") ? (
    <Person fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("place") ? (
    <LocationOn fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("organisation") ? (
    <AccountBalance fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("event") ? (
    <EventNote fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : type.includes("object") ? (
    <GridView fontSize={size} sx={{ ...style, fontSize: customSize }} />
  ) : null;
};

import { Fragment } from "react";
import { Box, Typography, TextField } from "@mui/material";

import InterestChip from "./InterestChip";

interface IInterest {
  conceptID: string;
  count: number;
}

interface IProps {
  interests: [IInterest];
}
const InterestsComponent = (props: IProps) => {
  const { interests } = props;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <Typography
          variant="overline"
          sx={{
            mx: 2,
          }}
        >
          <b style={{ marginRight: "4px" }}>Topic subscriptions</b>
          {interests.length > 0 && `(${interests.length})`}
        </Typography>
        <TextField
          disabled
          autoComplete="off"
          variant="outlined"
          size="small"
          label="Search topics"
          placeholder="Temporarily disabled"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ sx: { px: 1.5, py: 0.75 } }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 0.5, my: 0.5, p: 1 }}>
        {interests.map((interest) => {
          return (
            <Fragment key={interest.conceptID}>
              <InterestChip uuid={interest.conceptID} />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default InterestsComponent;

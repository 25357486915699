import { useContext } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { CTX } from "../../utils/ContextStore";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Box,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Settings, Close } from "@mui/icons-material";

import ToggleSwitch from "./SettingsComponents/ToggleSwitch";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    modalContainer: {
      minWidth: 500,
      minHeight: 250,
    },
    privSwitch: {
      marginRight: 0,
      marginBottom: 0,
    },
  })
);

const SettingsModal = () => {
  const classes = useStyles();
  const {
    showSettings,
    setShowSettings,
    superAdminMode,
    setSuperAdminMode,
    isExtendedAdmin,
    setIsExtendedAdmin,
    displayAltDatasetName,
    setDisplayAltDatasetName,
    showImageDisplayer,
    setShowImageDisplayer,
    enableTagGrouping,
    setEnableTagGrouping,
  }: any = useContext(CTX);

  const handleCloseModal = () => {
    setShowSettings(false);
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      className={classes.modal}
      open={showSettings}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showSettings}>
        <Paper className={classes.modalContainer} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
              px: 2,
              py: 1,
            }}
          >
            <Typography sx={{ display: "flex", fontWeight: 700, gap: 1 }}>
              <Settings />
              Preferences
            </Typography>

            <IconButton size="small" edge="end" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2 }}>
            {(Authenticator.hasAuthority(["ADMIN"]) ||
              Authenticator.hasAuthority(["CUSTOMER_ADMIN"])) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Admin privileges
                <FormGroup aria-label="priv-switch" row>
                  <FormControlLabel
                    label={
                      <Typography variant="caption" style={{ fontWeight: 700 }}>
                        Extended admin
                      </Typography>
                    }
                    labelPlacement="top"
                    classes={{ root: classes.privSwitch }}
                    control={
                      <ToggleSwitch
                        disabled={superAdminMode}
                        value={isExtendedAdmin}
                        handler={setIsExtendedAdmin}
                      />
                    }
                  />
                  {Authenticator.hasAuthority(["ADMIN"]) && (
                    <FormControlLabel
                      label={
                        <Typography variant="caption" sx={{ fontWeight: 700 }}>
                          Super admin
                        </Typography>
                      }
                      labelPlacement="top"
                      classes={{ root: classes.privSwitch }}
                      control={
                        <ToggleSwitch
                          value={superAdminMode}
                          handler={setSuperAdminMode}
                        />
                      }
                    />
                  )}
                </FormGroup>
              </Box>
            )}

            <ToggleSwitch
              label="Show alternative dataset name"
              value={displayAltDatasetName}
              handler={setDisplayAltDatasetName}
            />

            <Divider textAlign="left">
              <Typography variant="overline" sx={{ fontWeight: 700 }}>
                Auto-tagging Demo
              </Typography>
            </Divider>

            {Authenticator.hasAuthority(["IMAGE_TAGGING"]) && (
              <ToggleSwitch
                label="Smart image suggestions"
                value={showImageDisplayer}
                handler={setShowImageDisplayer}
              />
            )}
            {Authenticator.hasAuthority(["TAGGING"]) && (
              <ToggleSwitch
                label="Group related tags"
                value={enableTagGrouping}
                handler={setEnableTagGrouping}
              />
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SettingsModal;

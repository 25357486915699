import gql from "graphql-tag";

const DELETE_RESOURCE = gql`
  mutation ($id: String!, $cluster: String!, $language: String!) {
    deleteResource(id: $id, cluster: $cluster, language: $language) {
      id
      message
    }
  }
`;

export default DELETE_RESOURCE;

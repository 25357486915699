import {
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  dialogTitle: {},
  closeButton: {
    position: "absolute",
    right: "1rem",
    top: "0.5rem",
  },
  listItem: {
    padding: "1rem 0",
  },
  overflowHidden: {
    overflow: "hidden",
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0,0,0,0.125)",
  },
});

export interface Props {
  concept: any;
  open: boolean;
  onClose: () => void;
}

const ViewOnlyConceptModal = (props: Props) => {
  const classes = useStyles();
  const { onClose, open, concept } = props;

  if (!concept) return null;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="viewonly-concept-modal"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="viewonly-concept-modal-title"
        className={classes.borderBottom}
      >
        <Box className={classes.dialogTitle}>
          <span>Concept Details</span>
          <span style={{ fontSize: "1rem", opacity: 0.75, marginLeft: "1rem" }}>
            (read-only)
          </span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.overflowHidden}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <List>
              <ListItem className={classes.listItem}>
                <TextField
                  autoComplete="off"
                  disabled
                  label="UUID"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.uuid ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  autoComplete="off"
                  disabled
                  label="Root ID"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.rootId ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  autoComplete="off"
                  disabled
                  label="Title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.title ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  autoComplete="off"
                  disabled
                  label="Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.type ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <Autocomplete
                  disabled
                  multiple
                  fullWidth
                  size="small"
                  freeSolo
                  limitTags={5}
                  id="keywords"
                  options={[]}
                  open={false}
                  value={concept.keywords ?? []}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      variant="outlined"
                      label={
                        concept.type === "category"
                          ? "Connected Topics"
                          : "Keywords"
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Broader"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.broader ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Short Description"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.shortDescription ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={3}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Long Description"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.longDescription ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={4}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Author"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.author ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Source"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.source ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <TextField
                  disabled
                  autoComplete="off"
                  label="Publication Status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={concept.pubStatus ? "On" : "Off"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewOnlyConceptModal;

import gql from "graphql-tag";

const GET_RESOURCE = gql`
  mutation ($id: String!, $language: String!, $cluster: String!) {
    getResource(id: $id, language: $language, cluster: $cluster) {
      title
      id
      source
      homonyms
      author
      deleted
      feeling
      noun
      stopword
      stopwordAlways
      stopwordButAlsoName
      word
      genderedPronoun
      malePronoun
      femalePronoun
      genderNeutralPronoun
      placeNameSuffix
      organizationNameSuffix
    }
  }
`;

export default GET_RESOURCE;

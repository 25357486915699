import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  MenuList,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Search, Cancel } from "@mui/icons-material";

import GET_WIKIDATA_Q from "../../../mutations/GET_WIKIDATA_Q";
import CONVERT_CONCEPT_TO_WIKIDATA from "../../../mutations/CONVERT_CONCEPT_TO_WIKIDATA";

import { CTX as CMCTX } from "../CMContext";

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      cursor: "default",
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
    conceptText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
    },
    tooltip: {
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.25)",
      fontSize: "12px",
    },
    inputLabel: {
      backgroundColor: "#fff",
    },
  })
);

let duplicateTimeoutQ: ReturnType<typeof setTimeout>;

interface Props {
  setShowCreator: (show: boolean) => void;
}

const DuplicateCheckerContent = (props: Props) => {
  const classes = useStyles();
  const { setShowCreator } = props;
  const [getValueQ] = useMutation(GET_WIKIDATA_Q);

  const [convertConceptToWikidata] = useMutation(CONVERT_CONCEPT_TO_WIKIDATA);
  const { wikiConcept, setWikiMode, setWikiConceptConverted }: any =
    useContext(CMCTX);

  const [searchString, setSearchString] = useState(wikiConcept.title || "");
  const [possibleDuplicates, setPossibleDuplicates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = () => {
    convertConceptToWikidata({
      variables: {
        concept: {
          title: wikiConcept.title,
          uuid: wikiConcept.uuid,
          types: wikiConcept.subtypes,
          aliases: wikiConcept.aliases,
          global: wikiConcept.global,
          language: wikiConcept.language,
          shortDescription: wikiConcept.shortDescription,
          id: wikiConcept.id || "",
          url: wikiConcept.url || "",
        },
      },
    })
      .then((data: any) => {
        setWikiConceptConverted(data.data.convertConceptToWikidata);
        setShowCreator(true);
      })
      .catch(() => {
        //Show error if concept couldn't be converted
        console.error("Failed to proceed");
      })
      .finally(() => {});
  };

  useEffect(() => {
    duplicateTimeoutQ && clearTimeout(duplicateTimeoutQ);
    if (searchString) {
      setIsLoading(true);
      duplicateTimeoutQ = setTimeout(() => {
        getValueQ({
          variables: {
            title: searchString,
          },
        })
          .then((data: any) => {
            setPossibleDuplicates(data.data.getWikidataValueQ);
          })
          .finally(() => setIsLoading(false));
      }, 500);
    } else {
      setPossibleDuplicates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <Box>
      <Box mb={3}>
        <Typography>Check for possible duplicates in Wikidata</Typography>
      </Box>
      <TextField
        id="duplicate-validator-input"
        autoComplete="off"
        fullWidth
        type="text"
        variant="outlined"
        size="small"
        label="Label"
        value={searchString}
        InputLabelProps={{
          shrink: true,
          style: { backgroundColor: "#fff" },
        }}
        aria-haspopup="true"
        InputProps={{
          style: { backgroundColor: "#fff" },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchString && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchString("");
                    setPossibleDuplicates([]);
                    $("#duplicate-validator-input") &&
                      $("#duplicate-validator-input").focus();
                  }}
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onInput={(e: any) => {
          setSearchString(e.target.value);
        }}
      />
      <Box style={{ height: "200px", maxHeight: "200px" }}>
        {isLoading && (
          <Box py={1} px={2}>
            <CircularProgress size="1.5rem" />
          </Box>
        )}
        {isLoading || possibleDuplicates?.length > 0 || !searchString ? null : (
          <Box py={1} px={2} color="#56E39F">
            No duplicates found for <b>{searchString}</b>
          </Box>
        )}
        <Paper
          elevation={1}
          style={{
            maxHeight: "inherit",
            overflow: "auto",
            visibility:
              !isLoading && possibleDuplicates?.length > 0
                ? "visible"
                : "hidden",
          }}
        >
          <MenuList id="possible-duplicates-list" dense>
            {possibleDuplicates.map((item: any) => (
              <Tooltip
                key={item.id}
                title={
                  <Box py={0.4}>
                    <b>{`${item.label} (${item.id})`}</b>
                    <Box mt={0.4}>{item.description}</Box>
                  </Box>
                }
                aria-label="concept-description"
                placement="left"
                arrow
                classes={{
                  tooltip: classes.tooltip,
                }}
              >
                <Box className={classes.listItem}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={0.5}
                    px={1}
                    mr={0.5}
                  >
                    <Box flexGrow={1} overflow={"hidden"}>
                      <Typography
                        variant="body2"
                        className={classes.conceptText}
                        style={{ fontWeight: 700 }}
                      >
                        {`${item.label} (${item.id})`}
                      </Typography>

                      {item.description ? (
                        <Typography
                          variant="body2"
                          className={classes.conceptText}
                        >
                          {item.description}
                        </Typography>
                      ) : null}
                    </Box>
                    <Box>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {}}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Set as link
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
            ))}
          </MenuList>
        </Paper>
      </Box>

      <hr />
      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        style={{ paddingBottom: "0.5rem" }}
      >
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => setWikiMode(false)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isLoading}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              handleContinue();
            }}
          >
            None of these, proceed
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DuplicateCheckerContent;

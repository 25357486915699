import { useContext } from "react";
import { CTX as CMContext } from "./CMContext";
import { getTagIcon } from "src/utils/helpers";
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
} from "@mui/material";

interface Option {
  value: string;
  label: string;
}

const options: Option[] = [
  {
    value: "all",
    label: "All types",
  },
  {
    value: "person",
    label: "Person",
  },
  {
    value: "place",
    label: "Place",
  },
  {
    value: "organisation",
    label: "Organization",
  },
  {
    value: "event",
    label: "Event",
  },
  {
    value: "object",
    label: "Object",
  },
];

interface IProps {
  isSuperAdmin: boolean;
}

const SearchFilterComponent = (props: IProps) => {
  const { isSuperAdmin } = props;
  const {
    entityFilter,
    setEntityFilter,
    excludeDrafts,
    setExcludeDrafts,
    extendSearch,
    setExtendSearch,
  }: any = useContext(CMContext);

  const handleChangeEntityFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeExtendSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExtendSearch(event.target.checked);
  };

  const handleChangeDraft = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcludeDrafts(event.target.checked);
  };

  const clearFilter = () => {
    setExtendSearch(false);
    setExcludeDrafts(false);
    setEntityFilter("all");
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, gap: 2 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Tooltip
          arrow
          disableInteractive
          title={isSuperAdmin ? "Always enabled for super admins" : ""}
          enterDelay={500}
        >
          <FormControlLabel
            disabled={isSuperAdmin}
            control={
              <Checkbox
                checked={extendSearch || isSuperAdmin}
                onChange={handleChangeExtendSearch}
              />
            }
            label="Extend search"
            slotProps={{
              typography: { fontWeight: 700 },
            }}
            sx={{ my: 0 }}
          />
        </Tooltip>
        <FormControlLabel
          control={
            <Checkbox checked={excludeDrafts} onChange={handleChangeDraft} />
          }
          label="Exclude drafts"
          slotProps={{
            typography: { fontWeight: 700 },
          }}
          sx={{ my: 0 }}
        />
      </Box>
      <TextField
        id="concept-filter-select"
        autoComplete="off"
        select
        size="small"
        label="Filter entity types"
        value={entityFilter}
        onChange={handleChangeEntityFilter}
        InputProps={{ sx: { fontWeight: 700 } }}
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              dense: true,
              disablePadding: true,
            },
          },
        }}
        sx={{ width: 200, backgroundColor: "#fff" }}
      >
        {options.map((option: Option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              {getTagIcon(option.value, "small")}
              <b>{option.label}</b>
            </Box>
          </MenuItem>
        ))}
      </TextField>

      <Button size="small" variant="contained" onClick={clearFilter}>
        Default
      </Button>
    </Box>
  );
};

export default SearchFilterComponent;

import { gql } from "@apollo/client";

const GET_PUBLISHED_ARTICLES = gql`
  query ($query: GetPublishedArticleInput) {
    getPublishedArticles(query: $query) {
      uuid
      headline
      preamble
      body
      publicationTimestamp
      concepts {
        uuid
        title
        type
        broader
      }
      evaluation {
        correct {
          uuid
          title
          type
        }
        incorrect {
          uuid
          title
          type
        }
        missed {
          uuid
          title
          type
        }
      }
      analysisResults {
        concepts {
          uuid
          title
          type
          broader
        }
        broader {
          uuid
          title
          type
          broader
        }
        manuallyAdded {
          uuid
          title
          type
          broader
        }
        manuallyRemoved {
          uuid
          title
          type
          broader
        }
        genderData {
          femaleScore
          femaleMentions
          uniqueFemaleEntities
          femalePeople
          maleScore
          maleMentions
          uniqueMaleEntities
          malePeople
          nonbinaryScore
          nonbinaryMentions
          uniqueNonbinaryEntities
          nonbinaryPeople
          unknownScore
          unknownMentions
          uniqueUnknownEntities
          unknownPeople
        }
      }
    }
  }
`;

export default GET_PUBLISHED_ARTICLES;

import gql from "graphql-tag";

const GET_TAXONOMY = gql`
  query {
    getTaxonomy {
      categories {
        uuid
        title
        type
        broader
        shortDescription
      }
      topics {
        uuid
        title
        type
        broader
        shortDescription
      }
    }
  }
`;

export default GET_TAXONOMY;

import { useState, useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import moment from "moment";
import { CTX } from "../../utils/ContextStore";
import { Box, Grid, Card } from "@mui/material";

import ConceptTopLists from "./ConceptTopLists";
import PublishedArticleList from "./PublishedArticleList";
import ProductionOverview from "./ProductionOverview";
import ConceptOverview from "./ConceptOverview";
import FilterPanel from "./FilterPanel";
import DatasetPicker from "../PanelComponents/DatasetPicker";
import Dashboard from "../Dashboard/Dashboard";

import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import NewTagsDisplayComponent from "./NewTagsDisplayComponent";

interface IOption {
  id: string;
  privs?: string[];
  path: string;
  label?: string;
  description?: string;
  image?: string;
}

const options: Array<IOption> = [
  {
    id: "publication-overview",
    label: "Publication overview",
    description: "Get an overview of your article production",
    path: "/production-insights/production-overview",
    privs: [PRIVILEGES.PRODUCTION_INSIGHTS],
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/article-overview_small.jpg",
  },
  {
    id: "concept-overview",
    label: "Concept overview",
    description:
      "Get an overview of your concepts and their utilization statistics",
    path: "/production-insights/concept-statistics",
    privs: [PRIVILEGES.PRODUCTION_INSIGHTS],
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/concept-overview_small.jpg",
  },
  {
    id: "compare-concepts",
    label: "Compare Concepts",
    description:
      "Select and compare concepts to get an overview of their utilization statistics",
    path: "/production-insights/compare-concepts",
    privs: [PRIVILEGES.PRODUCTION_INSIGHTS],
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/compare-concepts_small.jpg",
  },
];

const ProductionInsights = () => {
  // QUERY VARIABLES
  const { activeEndpoint }: any = useContext(CTX);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [filterConcepts, setFilterConcepts] = useState<any[]>([]);
  const [newspaper, setNewspaper] = useState("");
  const [channel, setChannel] = useState("");
  const [includeConcepts, setIncludeConcepts] = useState<string[]>([]);
  const [excludeAuthors, setExcludeAuthors] = useState<string[]>([]);

  const checkDuplicate = (item: any, list: object[]) => {
    return list.some((concept: any) => concept.uuid === item.uuid);
  };

  const addToConceptList = (item: any) => {
    if (Array.isArray(item)) {
      const filtered = item.filter(
        (concept: any) => !checkDuplicate(concept, filterConcepts)
      );
      setFilterConcepts([...filterConcepts, ...filtered]);
      return;
    }
    if (!checkDuplicate(item, filterConcepts)) {
      setFilterConcepts([...filterConcepts, item]);
    }
  };

  const deleteFromConceptList = (item: any) => {
    if (Array.isArray(item)) {
      setFilterConcepts(
        filterConcepts.filter(
          (concept: any) => !item.some((i: any) => i.uuid === concept.uuid)
        )
      );
      return;
    }
    setFilterConcepts(
      filterConcepts.filter((concept: any) => concept.uuid !== item.uuid)
    );
  };

  const clearConceptList = () => {
    setFilterConcepts([]);
  };

  useEffect(() => {
    if (activeEndpoint) {
      setFilterConcepts([]);
      setNewspaper("");
      setChannel("");
    }
  }, [activeEndpoint]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100vh - 60px)",
      }}
    >
      {window.location.pathname.indexOf("/production-insights") !== -1 && (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 5,
          }}
        >
          <Card>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  gap: 1,
                  p: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <FilterPanel
                    setIncludeConcepts={setIncludeConcepts}
                    deleteFromConceptList={deleteFromConceptList}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    newspaper={newspaper}
                    setNewspaper={setNewspaper}
                    channel={channel}
                    setChannel={setChannel}
                    includeConcepts={includeConcepts}
                    excludeAuthors={excludeAuthors}
                    setExcludeAuthors={setExcludeAuthors}
                  />
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                  <DatasetPicker />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
      <Box>
        <Switch>
          <Route path="/production-insights" exact={true}>
            <Dashboard options={options} />
          </Route>

          <Route path="/production-insights/production-overview" exact={true}>
            <Box sx={{ p: 2 }}>
              <ProductionOverview
                endpoint={activeEndpoint}
                startDate={startDate}
                endDate={endDate}
                newspaper={newspaper}
                channel={channel}
                setNewspaper={setNewspaper}
                setChannel={setChannel}
                includeConcepts={includeConcepts}
                excludeAuthors={excludeAuthors}
              />
              <PublishedArticleList
                endpoint={activeEndpoint}
                filterConcepts={[]}
                startDate={startDate}
                endDate={endDate}
                newspaper={newspaper}
                channel={channel}
                includeConcepts={includeConcepts}
                excludeAuthors={excludeAuthors}
              />
            </Box>
          </Route>

          <Route path="/production-insights/concept-statistics" exact={true}>
            <Box sx={{ p: 2 }}>
              <ConceptTopLists
                endpoint={activeEndpoint}
                startDate={startDate}
                endDate={endDate}
                newspaper={newspaper}
                channel={channel}
                excludeAuthors={excludeAuthors}
              />
              <NewTagsDisplayComponent endpoint={activeEndpoint} />
            </Box>
          </Route>
          <Route path="/production-insights/compare-concepts" exact={true}>
            <Box sx={{ p: 2 }}>
              <ConceptOverview
                endpoint={activeEndpoint}
                filterConcepts={filterConcepts}
                setFilterConcepts={setFilterConcepts}
                startDate={startDate}
                endDate={endDate}
                newspaper={newspaper}
                channel={channel}
                excludeAuthors={excludeAuthors}
                addToConceptList={addToConceptList}
                removeFromConceptList={deleteFromConceptList}
                clearConceptList={clearConceptList}
              />
            </Box>
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default ProductionInsights;

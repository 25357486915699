import { useEffect } from "react";
import { Button } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

interface IProps {
  data: any;
  fileName: string;
  disabled?: boolean;
  isFetchingExportData?: boolean;
  setIsFetchingExportData?: (fetching: boolean) => void;
}

const ExportTsvButton = (props: IProps) => {
  const {
    disabled,
    isFetchingExportData,
    data,
    fileName,
    setIsFetchingExportData,
  } = props;
  useEffect(() => {
    if (isFetchingExportData) {
      const exportDataJson = data?.current || data;
      const header = Object.keys(exportDataJson[0]);
      const tsv = [header.join("\t")];
      exportDataJson.forEach((row: any) => {
        tsv.push(header.map((key) => row[key]).join("\t"));
      });
      const blob = new Blob([tsv.join("\n")], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName.replace(".json", ".tsv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setIsFetchingExportData?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingExportData]);

  const onClick = () => {
    setIsFetchingExportData?.(true);
  };

  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="warning"
      fullWidth
      startIcon={<FileUpload />}
      onClick={onClick}
      sx={{ overflowWrap: "anywhere" }}
    >
      Export as TSV
    </Button>
  );
};

export default ExportTsvButton;

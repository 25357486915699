import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

interface Props {
  label: string;
  tooltipText?: String;
}

const TooltipLabel = (props: Props) => {
  const { label, tooltipText } = props;

  return (
    <span>
      {label}{" "}
      <Tooltip title={tooltipText ?? ""} aria-label="info" placement="top">
        <Info fontSize="medium" color="primary" sx={{ cursor: "help" }} />
      </Tooltip>
    </span>
  );
};

export default TooltipLabel;

import { useState } from "react";
import { Add, Cancel } from "@mui/icons-material";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Chip,
  Tooltip,
} from "@mui/material";

interface IProps {
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
}

const MultiChannelInput = (props: IProps) => {
  const { value, onChange, disabled } = props;
  const [channelInput, setChannelInput] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Autocomplete
      id="channel-input"
      disabled={disabled}
      value={value}
      onChange={(e: any, newValue: any) => {
        onChange(newValue);
        setChannelInput("");
      }}
      fullWidth
      multiple
      limitTags={3}
      freeSolo
      size="small"
      autoComplete={false}
      open={false}
      options={[]}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
        setChannelInput("");
      }}
      inputValue={channelInput}
      sx={{ bgcolor: "#fff" }}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          sx={{
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
          {...params}
          variant="outlined"
          size="small"
          label="Channels"
          onChange={(e: any) => {
            setChannelInput(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Backspace") {
              e.stopPropagation();
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ position: "absolute", right: 14, bottom: 4 }}>
                  {isFocused && (
                    <Box>
                      <Tooltip title="Add channel" placement="top">
                        <span>
                          <IconButton
                            disabled={!channelInput}
                            size="small"
                            onClick={() => {
                              if (!channelInput) return;
                              else if (!value?.includes(channelInput)) {
                                onChange([...value, channelInput]);
                                setChannelInput("");
                              }
                            }}
                          >
                            <Add />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title="Clear channels" placement="top">
                        <span>
                          <IconButton
                            disabled={value.length === 0}
                            size="small"
                            sx={{ ml: 0.2 }}
                            onClick={() => {
                              onChange([]);
                              setChannelInput("");
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            size="small"
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
    />
  );
};

export default MultiChannelInput;

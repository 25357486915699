import { getTagIcon } from "src/utils/helpers";
import { TextField, MenuItem } from "@mui/material";

interface Props {
  label: string;
  value: string;
  onChange: any;
  dataList: any[];
  fullWidth?: boolean;
  disabled?: boolean;
  style?: any;
}

const Picker = (props: Props) => {
  const { label, value, onChange, dataList, fullWidth, disabled, style } =
    props;

  return (
    <TextField
      autoComplete="off"
      disabled={disabled}
      fullWidth={fullWidth}
      variant="outlined"
      size="small"
      InputProps={{ style: { fontWeight: 700 } }}
      select
      label={label}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{
        MenuProps: {
          MenuListProps: {
            dense: true,
            disablePadding: true,
          },
        },
      }}
      sx={{ minWidth: 120, textAlign: "left", ...style }}
    >
      {dataList.map((data: any, index: number) => (
        <MenuItem
          key={index}
          value={data}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 0.5,
          }}
        >
          {getTagIcon(data)}
          {data}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Picker;

import React, { useState, useRef, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  Grow,
  MenuList,
  MenuItem,
  Tooltip,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { Description, Grain, Lock, LockOpen } from "@mui/icons-material";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";
import GET_ROOT_ID_TO_TITLE from "../../mutations/getRootIdToTitle";

interface Props {
  disabled?: boolean;
  type: string;
  rootId: string;
  setRootId(id: string): any;
  viewOnly?: boolean;
  sourceType?: string;
  editable?: boolean;
  small?: boolean;
}

interface RootIdConcept {
  uuid: string;
  rootId: string;
  title: string;
  type: string | null;
}

const RootIdInput = (props: Props) => {
  const {
    disabled,
    type,
    rootId,
    setRootId,
    viewOnly,
    sourceType,
    editable,
    small,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);

  const [lockedRootId, setLockedRootId] = useState<boolean>(true);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [openSuggestionList, setOpenSuggestionList] = useState(false);
  const [focusSuggestionList] = useState(false);
  const [rootIdConcept, setRootIdConcept] = useState<RootIdConcept>({
    uuid: "",
    title: "",
    rootId: "",
    type: "",
  });
  const [hideHelper, setHideHelper] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);
  const [getRootIdToTitle] = useMutation(GET_ROOT_ID_TO_TITLE);

  const inputRef = useRef<HTMLInputElement>(null);
  const isInitRender = useRef(true);

  useEffect(() => {
    if (!isInitRender?.current) {
      clearTimeouts(searchTagTimeouts);
      if (!inputValue) {
        setRootId("");
        setTagSuggestions([]);
      }
      if (inputValue && inputValue !== rootIdConcept.title) {
        let searchTagTimeout = setTimeout(async () => {
          searchConcepts({
            variables: {
              title: inputValue,
              type: type,
              draft: false,
              size: 6,
            },
          }).then((res: any) => {
            if (res?.data?.searchConcepts?.result) {
              const concepts = res.data.searchConcepts.result;
              const exactMatchArray = concepts.filter(
                (tag: any) =>
                  tag.rootId === inputValue || tag.title === inputValue
              );
              if (
                exactMatchArray.length === 1 &&
                inputValue === exactMatchArray[0].rootId
              ) {
                setTagSuggestions([]);
                setRootId(exactMatchArray[0].rootId);
                setInputValue(exactMatchArray[0].title);
                setRootIdConcept(exactMatchArray[0]);
              } else {
                setTagSuggestions(concepts);
              }
              setSearchTagTimeouts([]);
            }
          });
        }, 500);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    isInitRender.current = false;
    if (rootId) {
      const fetchRootId = async () => {
        setIsLoading(true);
        try {
          const res: any = await getRootIdToTitle({
            variables: {
              rootIds: [rootId],
            },
          });
          if (
            res?.data?.getRootIdToTitle?.result &&
            res.data.getRootIdToTitle.result.length > 0
          ) {
            setInputValue(
              res.data.getRootIdToTitle.result[0].title ||
                res.data.getRootIdToTitle.result[0].rootId
            );
            setRootIdConcept(res.data.getRootIdToTitle.result[0]);
          }
        } catch {
          setHideHelper(true);
        } finally {
          setIsLoading(false);
        }
      };
      fetchRootId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenSuggestionList = () => {
    setOpenSuggestionList(true);
  };

  const handleListKeyDownInput = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowDown" || event.key === "Tab") {
      event.preventDefault();
      $("#suggestion-list li:first").focus();
    }
  };

  const handleListKeyDownList = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      if ($("#suggestion-list li:focus").is(":last-child")) {
        $("#suggestion-list li:first").focus();
      } else $("#suggestion-list li:focus").next().focus();
    }
  };

  const handleCloseSuggestionList = () => {
    setOpenSuggestionList(false);
  };

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  return (
    <Box width="100%">
      <TextField
        ref={inputRef}
        disabled={
          disabled || (!editable && (lockedRootId || sourceType === "global"))
        }
        autoComplete="off"
        label="Root Id"
        variant="outlined"
        size="small"
        fullWidth
        value={inputValue || ""}
        onSelectCapture={handleOpenSuggestionList}
        helperText={
          !hideHelper && (
            <Box
              position="absolute"
              left="1rem"
              marginTop="-0.2rem"
              id="rootid-helper"
              component="span"
              fontWeight="bold"
              color="#777"
              onClick={() =>
                setTimeout(() => {
                  setOpenSuggestionList(false);
                }, 100)
              }
            >
              {!rootId ? null : isLoading ? (
                "Loading..."
              ) : rootId === rootIdConcept.rootId ? (
                <span>
                  <span>{rootIdConcept.rootId}</span>
                  {rootIdConcept.type && (
                    <Box component="span" ml={0.5}>
                      ({rootIdConcept.type})
                    </Box>
                  )}
                </span>
              ) : (
                "Invalid root ID"
              )}
            </Box>
          )
        }
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: !editable && !viewOnly && (
            <InputAdornment position="end">
              <Tooltip
                title={lockedRootId ? "Enable editing" : "Disable editing"}
                placement="top"
              >
                <IconButton
                  size="small"
                  onClick={() => setLockedRootId(!lockedRootId)}
                  edge="end"
                >
                  {lockedRootId ? <Lock /> : <LockOpen />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={(e: any) => {
          setRootId(e.target.value);
          setInputValue(e.target.value);
        }}
        onKeyDown={handleListKeyDownInput}
      />
      {tagSuggestions.length > 0 && (
        <Box>
          <Popper
            id="suggestion-dropdown"
            style={
              small
                ? {
                    width: "24.5%",
                    marginTop: "-0.2rem",
                    zIndex: 2,
                  }
                : {
                    width: "calc(100% - 3.3rem)",
                    marginTop: "-0.33rem",
                    zIndex: 2,
                  }
            }
            open={openSuggestionList}
            anchorEl={inputRef.current}
            transition
            placement="bottom-start"
            disablePortal={true}
            modifiers={[
              {
                name: "flip",
                enabled: false,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: false,
                options: {
                  altAxis: false,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper className="popover-index dropdown-transition">
                  <ClickAwayListener onClickAway={handleCloseSuggestionList}>
                    <Box display="flex">
                      <MenuList
                        dense
                        style={{
                          flexGrow: 1,
                          width: "100%",
                          borderLeft: "1px solid rgba(0,0,0,0.125)",
                          borderRight: "1px solid rgba(0,0,0,0.125)",
                        }}
                        id="suggestion-list-root-id"
                        autoFocusItem={focusSuggestionList}
                        onKeyDown={handleListKeyDownList}
                      >
                        <MenuItem
                          disabled
                          style={{
                            borderBottom: "1px solid rgba(0,0,0,0.125)",
                          }}
                        >
                          <Typography variant="overline">
                            {type === "category" ? "Categories" : "Topics"}
                          </Typography>
                        </MenuItem>

                        {tagSuggestions.map((item: any) => (
                          <Tooltip
                            title={
                              <Box minWidth="140px">
                                <Box fontWeight="bold">
                                  {item.title} ({item.type})
                                </Box>
                                {item.shortDescription ? (
                                  <Box mt={0.5}>{item.shortDescription}</Box>
                                ) : null}

                                {item.rootId ? (
                                  <Box mt={0.75}>{item.rootId}</Box>
                                ) : null}
                              </Box>
                            }
                            placement="bottom-start"
                            key={item.uuid}
                            arrow
                          >
                            <MenuItem
                              key={item.uuid}
                              disabled={!item.rootId}
                              style={{
                                borderBottom: "1px solid rgba(0,0,0,0.125)",
                              }}
                              dense
                              onClick={(e: any) => {
                                e.preventDefault();
                                setTagSuggestions([]);
                                setInputValue(item.title);
                                setRootId(item.rootId || "");
                                setRootIdConcept(
                                  {
                                    uuid: item.uuid,
                                    rootId: item.rootId,
                                    title: item.title,
                                    type: item.type,
                                  } || {}
                                );
                              }}
                            >
                              <Box>
                                <Box className="font-weight-bold">
                                  <Typography variant="inherit">
                                    {type === "category" ? (
                                      <Description
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : (
                                      <Grain
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    )}
                                    {item.title}
                                  </Typography>
                                </Box>
                              </Box>
                            </MenuItem>
                          </Tooltip>
                        ))}
                      </MenuList>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default RootIdInput;

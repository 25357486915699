import { useState, useMemo, useRef, useEffect } from "react";
import { useCurrentHeight } from "src/utils/helpers";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import {
  Close,
  HorizontalRule,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";

const useStyles = makeStyles({
  paginationLabel: { marginBottom: 0 },
});

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E8EEF2",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IData {
  title: string;
  type: string;
  precision: string;
  recall: string;
  f1: string;
  correct: string;
  guesses: string;
  precisionImprovements: string;
  recallImprovements: string;
  totalImprovements: string;
  comments?: string;
}

interface IHeadCell {
  id: keyof IData;
  label: string;
  numeric: boolean;
  width?: string;
}

interface EnhancedTableProps {
  headCells: readonly IHeadCell[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  order: Order;
  orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: "#DCDCDD",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 700,
              backgroundColor: "#DCDCDD",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const typeOptions = [
  "category",
  "topic",
  "person",
  "place",
  "organisation",
  "event",
  "object",
];

interface IProps {
  headers: Array<any>;
  data: Array<Array<string>>;
  dataCompare?: Array<Array<string>> | null;
  exportData?: any;
  isFetchingExportData?: boolean;
}

const DataTableComponent = (props: IProps) => {
  const { headers, data, dataCompare, exportData, isFetchingExportData } =
    props;

  const classes = useStyles();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IData>("title");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [searchString, setSearchString] = useState<string>("");
  const [filterType, setFilterType] = useState<Array<string>>([]);

  const filteredDataLength = useRef<number>(0);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const onClearSearch = () => {
    setSearchString("");
  };

  const onTypeFilter = (types: string[]) => {
    setFilterType(types);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage: any = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells: IHeadCell[] = headers;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(() => {
    let rowsJSON: Array<any> = [];
    if (searchString || filterType.length > 0) {
      rowsJSON = data.filter(
        (row: any) =>
          row.Title &&
          row.Title?.toLowerCase().includes(searchString.toLowerCase()) &&
          (filterType.length < 1 || filterType.includes(row.Type))
      );
    } else {
      rowsJSON = data.filter((row: any) => row.Title);
    }
    filteredDataLength.current = rowsJSON.length;
    const sortedData = stableSort(rowsJSON, getComparator(order, orderBy));
    if (isFetchingExportData) {
      exportData.current = sortedData;
    }
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchString,
    filterType,
    isFetchingExportData,
  ]);

  useEffect(() => {
    const table = document.getElementById("concept-explorer-table");
    if (table) table.scrollTop = 0;
  }, [page]);

  return (
    <Paper elevation={4}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          py: 1,
          px: 2,
          gap: 2,
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          label={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#666",
                gap: 2,
              }}
            >
              <Search /> Search
            </span>
          }
          value={searchString}
          onChange={onSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    visibility: searchString ? "visible" : "hidden",
                  }}
                  size="small"
                  onClick={onClearSearch}
                >
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: "20%" }}
        />

        <Autocomplete
          multiple
          size="small"
          options={typeOptions}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          ListboxProps={{ sx: { paddingTop: "0px", paddingBottom: "0px" } }}
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: 0 }}>
              <Checkbox checked={selected} sx={{ mr: 1 }} />
              {option}
            </li>
          )}
          sx={{ width: "20%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#666",
                    gap: 2,
                  }}
                >
                  Type filter
                </span>
              }
            />
          )}
          onChange={(e, val) => {
            onTypeFilter(val);
          }}
        />
      </Box>
      <TableContainer
        id="concept-explorer-table"
        sx={{ overflowY: "auto", height: `${useCurrentHeight() - 150}px` }}
      >
        <Table stickyHeader size="small">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {visibleRows.map((row: any, i: number) => (
              <StyledTableRow
                key={i}
                hover
                tabIndex={-1}
                onClick={() => {
                  //TODO: Add row click event
                }}
                sx={{ cursor: "pointer" }}
              >
                {headCells.map((cell: IHeadCell, i: number) => {
                  const key: any = cell.id;
                  const width = !cell.width ? "auto" : cell.width;
                  const primaryVal =
                    row[key] === "" ||
                    row[key] === null ||
                    row[key] === undefined
                      ? ""
                      : !cell.numeric
                      ? row[key]
                      : Math.round(
                          ((typeof row[key] !== "number"
                            ? parseFloat(row[key])
                            : row[key]) +
                            Number.EPSILON) *
                            100
                        ) / 100;
                  let compareData: any = dataCompare?.find(
                    (d: any) => d.Title === row.Title
                  );
                  let secondaryVal: any;
                  if (!compareData) {
                    secondaryVal = 1;
                  } else {
                    secondaryVal =
                      compareData[key] === "" ||
                      compareData[key] === null ||
                      compareData[key] === undefined
                        ? 1
                        : !cell.numeric
                        ? compareData[key]
                        : Math.round(
                            ((typeof compareData[key] !== "number"
                              ? parseFloat(compareData[key])
                              : compareData[key]) +
                              Number.EPSILON) *
                              100
                          ) / 100;
                  }

                  const possibleImprovements = [
                    "Possible precision improvements",
                    "Possible recall improvements",
                    "Total possible improvements",
                  ];

                  const color =
                    primaryVal === secondaryVal
                      ? "primary"
                      : !possibleImprovements.includes(key)
                      ? secondaryVal > primaryVal
                        ? "#56E39F"
                        : "secondary"
                      : secondaryVal < primaryVal
                      ? "#56E39F"
                      : "secondary";

                  return (
                    <StyledTableCell key={i} width={width}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: cell.numeric ? "right" : "left",
                          whiteSpace: "nowrap",
                          gap: 1.5,
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: key === "Title" ? 700 : undefined,
                          }}
                        >
                          {primaryVal}
                        </Typography>
                        {dataCompare && cell.numeric && (
                          <>
                            <Typography
                              component="span"
                              color={color}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                                fontWeight: 700,
                              }}
                            >
                              {` (${secondaryVal})`}
                              {primaryVal === secondaryVal ? (
                                <HorizontalRule fontSize="small" />
                              ) : primaryVal < secondaryVal ? (
                                <KeyboardArrowUp fontSize="small" />
                              ) : (
                                <KeyboardArrowDown fontSize="small" />
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                sx={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[100, 250, 500]}
        count={filteredDataLength.current}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage=""
        sx={{
          width: "100%",
          overflowX: "hidden",
          borderTop: "1px solid rgba(0, 0, 0, 0.125)",
        }}
        classes={{
          displayedRows: classes.paginationLabel,
          selectLabel: classes.paginationLabel,
        }}
      />
    </Paper>
  );
};

export default DataTableComponent;

import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import {
  Alert,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import GET_NEWS_RECOMMENDATIONS from "src/queries/GET_NEWS_RECOMMENDATIONS";

interface IArticle {
  uuid: string;
  headline: string;
  preamble: string;
  publicationTimestamp: string;
  imageUrl: string;
  topConcept: {
    uuid: string;
    title: string;
    type: string;
  };
}

interface IProps {
  endpoint: any | null;
  user: any | null;
  strategy: string | null;
  article: IArticle | null;
  setModalArticle: (article: any) => void;
}

const sizeOptions = [10, 20, 50, 100, 200];

const ArticleResultList = (props: IProps) => {
  const { endpoint, user, strategy, article, setModalArticle } = props;

  const [recommendations, setRecommendations] = useState<IArticle[] | null>(
    null
  );
  const [returnSize, setReturnSize] = useState<number>(10);

  const { loading, error } = useQuery(GET_NEWS_RECOMMENDATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      query: {
        source: "bot",
        returnSize: returnSize,
        userID: user?.id ?? undefined,
        articleID: !article?.uuid ? undefined : article.uuid,
        signedIn: true,
        config: strategy,
        location: user?.location?.latLng,
        returnFields: [
          "articleID",
          "headline",
          "preamble",
          "featuredImageURL",
          "publicationTimestamp",
          "topConcept",
          "concepts",
        ],
      },
    },
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const recommendations = data.recommendations;
      setRecommendations(recommendations);
    },
    onError: (e) => {
      setRecommendations(null);
    },
    skip: !strategy && !user?.id && !article?.uuid,
  });

  useEffect(() => {
    setRecommendations(null);
    setReturnSize(10);
  }, [endpoint, user, strategy, article]);

  return (
    <Box>
      <Paper
        elevation={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "rgba(0,0,0,0.075)",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Typography variant="overline" sx={{ ml: 2, fontWeight: 700 }}>
            Article results
            {recommendations && recommendations?.length > 0 ? (
              <Box component="span" sx={{ ml: 1 }}>
                ({recommendations?.length})
              </Box>
            ) : null}
          </Typography>
          <TextField
            autoComplete="off"
            select
            size="small"
            label="Size"
            value={returnSize}
            InputLabelProps={{ shrink: true }}
            InputProps={{ sx: { fontWeight: 700 } }}
            onChange={(e: any) => {
              setReturnSize(e.target.value);
            }}
            sx={{ width: "10%", minWidth: 100 }}
          >
            {!sizeOptions.includes(returnSize) && (
              <MenuItem disabled value={returnSize}>
                {returnSize}
              </MenuItem>
            )}
            {sizeOptions.map((option, i: number) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {loading && <LinearProgress />}
        <List
          dense
          disablePadding
          sx={{
            maxHeight: "calc(100vh - 240px)",
            overflow: "auto",
          }}
        >
          {error ? (
            <Alert severity="error">Failed to fetch results</Alert>
          ) : !recommendations ? null : recommendations.length < 1 ? (
            <Alert severity="info">No results found</Alert>
          ) : (
            recommendations.map((recommendation: IArticle, i: number) => (
              <ListItem
                key={i}
                disablePadding
                sx={{ bgcolor: i % 2 === 0 ? undefined : "rgba(0,0,0,0.04)" }}
              >
                <ListItemButton
                  onClick={() => {
                    setModalArticle(recommendation);
                  }}
                >
                  <ListItemText
                    primary={
                      <Box component="span">
                        <Box
                          component="span"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {recommendation?.topConcept?.title && (
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 700,
                                color: "#EF6F6C",
                                fontSize: "1rem",
                              }}
                            >
                              {recommendation.topConcept.title.toUpperCase()}
                            </Typography>
                          )}
                          <Typography component="span" variant="body2">
                            {moment
                              .parseZone(recommendation.publicationTimestamp)
                              .format("YYYY-MM-DD, HH:mm z")}
                          </Typography>
                        </Box>
                        <Typography
                          component="span"
                          sx={{
                            display: "block",
                            fontWeight: 700,
                            fontFamily: "Crete Round",
                            fontSize: "1.2rem",
                            mb: 0.5,
                          }}
                        >
                          {recommendation.headline}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box
                        component="span"
                        sx={{ fontFamily: "Crete Round", fontSize: "1rem" }}
                      >
                        {recommendation.preamble}
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>

        {recommendations && recommendations.length > 1 && (
          <Box sx={{ py: 1, display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              size="small"
              startIcon={<ArrowDropDown fontSize="large" />}
              sx={{ textDecoration: "underline" }}
              onClick={() => {
                setReturnSize(returnSize + 10);
              }}
            >
              View more
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ArticleResultList;

import { useState, useMemo } from "react";
import {
  Box,
  Divider,
  Grid,
  //MenuItem,
  Paper,
  Slider,
  //TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoOutlined } from "@mui/icons-material";

const CustomSlider = styled(Slider)({
  height: 8,
  //"& .MuiSlider-track": {},
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid rgba(89, 107, 191, 1)",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: 700,
    padding: "4px 6px",
    backgroundColor: "rgba(89, 107, 191, 0.92)",
  },
});

const currencyOptions = [
  { id: "EUR", label: "EUR", symbol: "€", multiplier: 1 },
  { id: "USD", label: "USD", symbol: "$", multiplier: 1 },
  { id: "GBP", label: "GBP", symbol: "£", multiplier: 1 },
  { id: "SEK", label: "SEK", symbol: "kr", multiplier: 1 },
];

const RoiCalculatorComponent = () => {
  const [selectedCurrency] = useState(currencyOptions[0]);

  const [articlesPerMonth, setArticlesPerMonth] = useState<number>(3000);
  const [staffHourlyRate, setStaffHourlyRate] = useState<number>(50);
  const [numberOfEditors, setNumberOfEditors] = useState<number>(100);

  // Time savings calculations
  const monthlyWorkHoursPerEditor = 160;
  const hoursSavedMonthly = useMemo(
    () => (articlesPerMonth * 7) / 60,
    [articlesPerMonth]
  );
  const productivityGain = useMemo(() => {
    const res =
      (hoursSavedMonthly / (monthlyWorkHoursPerEditor * numberOfEditors)) * 100;
    return Number.isFinite(res) ? res : 0;
  }, [hoursSavedMonthly, monthlyWorkHoursPerEditor, numberOfEditors]);

  // Subscriber impact calculations
  const [totalSubscribers, setTotalSubscribers] = useState<number>(125000);
  const [annualSubscriptionRevenue, setAnnualSubscriptionRevenue] =
    useState<number>(150);
  const tagFollowers = useMemo(
    () => totalSubscribers * 0.25,
    [totalSubscribers]
  );
  const estimatedSavedSubscribers = useMemo(
    () => tagFollowers * 0.01,
    [tagFollowers]
  );
  const estimatedAnnualRetentionRevenue = useMemo(
    () => estimatedSavedSubscribers * annualSubscriptionRevenue,
    [estimatedSavedSubscribers, annualSubscriptionRevenue]
  );

  // SEO impact calculations
  const [monthlyOrganicTraffic, setMonthlyOrganicTraffic] =
    useState<number>(400000);
  const [revenuePerVisitor, setRevenuePerVisitor] = useState<number>(0.1);
  const topicPageTraffic = useMemo(
    () => monthlyOrganicTraffic * 1,
    [monthlyOrganicTraffic]
  );
  const monthlyAdditionalTopicTraffic = useMemo(
    () => topicPageTraffic * 0.5,
    [topicPageTraffic]
  );
  const monthlySeoRevenue = useMemo(
    () => monthlyAdditionalTopicTraffic * revenuePerVisitor,
    [monthlyAdditionalTopicTraffic, revenuePerVisitor]
  );
  const annualSeoRevenue = useMemo(
    () => monthlySeoRevenue * 12,
    [monthlySeoRevenue]
  );

  // Annual impact summary
  const annualSavingRevenue = useMemo(
    () => hoursSavedMonthly * 12 * staffHourlyRate,
    [hoursSavedMonthly, staffHourlyRate]
  );
  const totalAnnualImpact = useMemo(() => {
    const res =
      annualSavingRevenue + estimatedAnnualRetentionRevenue + annualSeoRevenue;
    return Number.isFinite(res) ? res : 0;
  }, [annualSavingRevenue, estimatedAnnualRetentionRevenue, annualSeoRevenue]);

  /*
  const onCurrencyChange = (e: any) => {
    setSelectedCurrency(e.target.value);
  };
  */

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "#fff",
      }}
    >
      <Grid container spacing={2}>
        <Grid container item direction="column" xs={12} sm={4}>
          <Paper
            id="roi-time-savings"
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "grey.200",
              px: 3,
              py: 1,
              flexBasis: "100%",
            }}
          >
            {/*
              <TextField
                select
                label="Currency"
                size="small"
                value={selectedCurrency}
                onChange={onCurrencyChange}
                inputProps={{ sx: { fontWeight: 700 } }}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                      disablePadding: true,
                    },
                  },
                }}
                sx={{ width: "30%" }}
              >
                {currencyOptions.map((option: any) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    sx={{ fontWeight: 700 }}
                  >
                    {`${option.label} (${option.symbol})`}
                  </MenuItem>
                ))}
              </TextField>
            */}
            <Divider textAlign="left">
              <Typography variant="overline">Time Savings</Typography>
            </Divider>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Articles per Month
              </Typography>

              <CustomSlider
                valueLabelDisplay="on"
                step={500}
                marks={[{ value: 15000, label: "15k" }]}
                value={articlesPerMonth}
                min={0}
                max={15000}
                onChange={(e, value) => setArticlesPerMonth(value as number)}
              />
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Number of Writers
              </Typography>
              <CustomSlider
                valueLabelDisplay="on"
                step={10}
                marks={[{ value: 1000, label: "1000" }]}
                value={numberOfEditors}
                min={0}
                max={1000}
                onChange={(e, value) => setNumberOfEditors(value as number)}
              />
            </Box>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    p: 1.5,
                    ml: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Hours Saved Monthly
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Hours Saved Monthly</b>
                            <br />
                            Transform your workflow: Real customers save 7
                            minutes per article with auto-tagging, proven across
                            nearly 1 million articles.
                          </Typography>
                          <Typography variant="subtitle2">
                            Total time saved by automating the tagging process,
                            calculated at 7 minutes saved per article.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      display: "flex",
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {hoursSavedMonthly.toFixed(0).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    height: "100%",
                    mr: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Productivity Gain
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Productivity Gain</b>
                            <br />
                            Free your team's potential: Convert validated time
                            savings into productive content creation hours.
                          </Typography>
                          <Typography variant="subtitle2">
                            Hours saved expressed as percentage of total team
                            capacity based on standard 160-hour work month per
                            editor.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {productivityGain.toFixed(1) + "%"}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container item direction="column" xs={12} sm={4}>
          <Paper
            id="roi-subscriber-impact"
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "grey.200",
              px: 3,
              py: 1,
              flexBasis: "100%",
            }}
          >
            <Divider textAlign="left">
              <Typography variant="overline">Subscriber Impact</Typography>
            </Divider>

            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Total Subscribers
              </Typography>
              <CustomSlider
                valueLabelDisplay="on"
                marks={[{ value: 250000, label: "250k" }]}
                value={totalSubscribers}
                step={10000}
                min={10000}
                max={250000}
                onChange={(e, value) => setTotalSubscribers(value as number)}
              />
            </Box>

            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {`Annual Subscription Revenue ${
                  selectedCurrency
                    ? `(${selectedCurrency.symbol ?? selectedCurrency.id})`
                    : ""
                }`}
              </Typography>
              <CustomSlider
                valueLabelDisplay="on"
                step={10}
                marks={[{ value: 500, label: selectedCurrency.symbol + "500" }]}
                value={annualSubscriptionRevenue}
                min={10}
                max={500}
                onChange={(e, value) =>
                  setAnnualSubscriptionRevenue(value as number)
                }
              />
            </Box>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    height: "100%",
                    ml: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Active Tag Followers
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Active Tag Followers</b>
                            <br />
                            Engage your readers: One in four subscribers
                            actively follow topics they care about.
                          </Typography>
                          <Typography variant="body2">
                            Percentage of total subscribers who actively follow
                            topic tags, measured at 25% of subscriber base.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {tagFollowers.toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    height: "100%",
                    mr: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Annual Retained Revenue
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Annual Retained Revenue (Estimated)</b>
                            <br />
                            Data shows clear connection between tag following
                            and user activity, making it a valuable tool for
                            subscriber retention.
                          </Typography>
                          <Typography variant="subtitle2">
                            Estimated annual subscription value retained by
                            reducing the churn rate of active tag followers by 1
                            percentage point.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {selectedCurrency?.symbol +
                      Math.round(
                        estimatedAnnualRetentionRevenue
                      ).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container item direction="column" xs={12} sm={4}>
          <Paper
            id="roi-seo-impact"
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "grey.200",
              px: 3,
              py: 1,
              flexBasis: "100%",
            }}
          >
            <Divider textAlign="left">
              <Typography variant="overline">SEO Impact</Typography>
            </Divider>

            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Monthly Topic Page Traffic
              </Typography>
              <CustomSlider
                valueLabelDisplay="on"
                step={100000}
                marks={[{ value: 2000000, label: "2m" }]}
                value={monthlyOrganicTraffic}
                min={100000}
                max={2000000}
                onChange={(e, value) =>
                  setMonthlyOrganicTraffic(value as number)
                }
              />
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {`Revenue per Visitor ${
                  selectedCurrency
                    ? `(${selectedCurrency.symbol ?? selectedCurrency.id})`
                    : ""
                }`}
              </Typography>
              <CustomSlider
                valueLabelDisplay="on"
                step={0.05}
                marks={[
                  { value: 0.3, label: selectedCurrency?.symbol + "0.3" },
                ]}
                value={revenuePerVisitor}
                min={0.05}
                max={0.3}
                onChange={(e, value) => setRevenuePerVisitor(value as number)}
              />
            </Box>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    height: "100%",
                    ml: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Additional Monthly Traffic
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Additional Monthly Traffic</b>
                            <br />
                            Build search authority: Achieve significant topic
                            page growth in your first year.
                          </Typography>
                          <Typography variant="subtitle2">
                            Achieve 50%+ boost to your topic page traffic in the
                            first year.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {monthlyAdditionalTopicTraffic.toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    height: "100%",
                    mr: -1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      flexBasis: "100%",
                    }}
                  >
                    Monthly SEO Revenue
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Monthly SEO Revenue</b>
                            <br />
                            Turn improved topic page visibility into revenue
                            opportunities with proven growth patterns.
                          </Typography>
                          <Typography variant="subtitle2">
                            Projected additional monthly revenue from increased
                            topic page traffic.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      fontSize: "2vw",
                    }}
                  >
                    {selectedCurrency?.symbol +
                      Math.round(monthlySeoRevenue).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container item direction="column" xs={12}>
          <Paper
            id="roi-annual-impact-summary"
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "grey.200",
              px: 3,
              py: 1,
              flexBasis: "100%",
            }}
          >
            <Divider textAlign="left">
              <Typography variant="overline">Annual Impact Summary</Typography>
            </Divider>

            <Grid container item direction="row" spacing={6}>
              <Grid container item xs={7} sm={8} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    {`Staff Hourly Cost ${
                      selectedCurrency
                        ? `(${selectedCurrency.symbol ?? selectedCurrency.id})`
                        : ""
                    }`}
                  </Typography>
                  <CustomSlider
                    valueLabelDisplay="on"
                    step={1}
                    marks={[
                      { value: 100, label: selectedCurrency.symbol + "100" },
                    ]}
                    value={staffHourlyRate}
                    min={0}
                    max={100}
                    onChange={(e, value) => setStaffHourlyRate(value as number)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 1.5,
                      height: "100%",
                      ml: -1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 0.5,
                        flexBasis: "100%",
                      }}
                    >
                      Annual Productivity Gain Value
                      <Tooltip
                        arrow
                        disableInteractive
                        title={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2">
                              <b>Annual Productivity Gain Value</b>
                              <br />
                              Free your team's potential: Convert validated time
                              savings into productive content creation hours.
                            </Typography>
                            <Typography variant="subtitle2">
                              Total hours saved × hourly rate × 12 months ×
                              staff hourly cost.
                            </Typography>
                          </Box>
                        }
                      >
                        <InfoOutlined
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "primary.main",
                        fontWeight: 700,
                        fontSize: "2.5vw",
                      }}
                    >
                      {selectedCurrency?.symbol +
                        Math.round(annualSavingRevenue).toLocaleString()}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 1.5,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 0.5,
                        flexBasis: "100%",
                      }}
                    >
                      Annual Retained Revenue
                      <Tooltip
                        arrow
                        disableInteractive
                        title={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2">
                              <b>Annual Retained Revenue (Estimated)</b>
                              <br />
                              Data shows clear connection between tag following
                              and user activity, making it a valuable tool for
                              subscriber retention.
                            </Typography>
                            <Typography variant="subtitle2">
                              Estimated annual subscription value retained by
                              reducing the churn rate of active tag followers by
                              1 percentage point.
                            </Typography>
                          </Box>
                        }
                      >
                        <InfoOutlined
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "primary.main",
                        fontWeight: 700,
                        fontSize: "2.5vw",
                      }}
                    >
                      {selectedCurrency?.symbol +
                        Math.round(
                          estimatedAnnualRetentionRevenue
                        ).toLocaleString()}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 1.5,
                      height: "100%",
                      mr: -1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 0.5,
                        flexBasis: "100%",
                      }}
                    >
                      Annual SEO Revenue
                      <Tooltip
                        arrow
                        disableInteractive
                        title={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="subtitle2">
                              <b>Annual SEO Revenue</b>
                              <br />
                              Projected additional annual revenue from increased
                              topic page traffic.
                            </Typography>
                          </Box>
                        }
                      >
                        <InfoOutlined
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "primary.main",
                        fontWeight: 700,
                        fontSize: "2.5vw",
                      }}
                    >
                      {selectedCurrency?.symbol +
                        Math.round(annualSeoRevenue).toLocaleString()}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={5} sm={4}>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    gap: 0.5,
                    px: 2,
                    pt: 2,
                    pb: 0.5,
                    mx: -1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 0.5,
                      fontWeight: 700,
                    }}
                  >
                    Total Annual Impact
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            <b>Total Annual Impact</b>
                            <br />
                            See your complete ROI potential, built on validated
                            success metrics.
                          </Typography>
                          <Typography variant="subtitle2">
                            Combined annual value of measured time savings plus
                            projected revenue impacts from improved engagement
                            and SEO.
                          </Typography>
                        </Box>
                      }
                    >
                      <InfoOutlined
                        fontSize="medium"
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexBasis: "100%",
                      color: "success.main",
                      fontWeight: 700,
                      fontSize: "3.5vw",
                    }}
                  >
                    {selectedCurrency?.symbol +
                      Math.round(totalAnnualImpact).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    Based on industry benchmarks and customer data
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoiCalculatorComponent;

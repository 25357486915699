import { Fragment, useState, useEffect, useContext } from "react";
import { Authenticator } from "../../../utils/Authenticator";
import { useMutation } from "@apollo/client";
import { CTX } from "../../../utils/ContextStore";
import { CTX as CMContext } from ".././CMContext";

import { Row, Col } from "react-bootstrap";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  List,
  ListItem,
  Alert,
  AlertTitle,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { MdCheck, MdClose, MdDelete, MdDeleteForever } from "react-icons/md";

import GET_RESOURCE from "../../../mutations/GET_RESOURCE";
import CREATE_RESOURCE from "../../../mutations/CREATE_RESOURCE";
import EDIT_RESOURCE from "../../../mutations/EDIT_RESOURCE";
import DELETE_RESOURCE from "../../../mutations/DELETE_RESOURCE";

import AliasInput from ".././AliasInput";
import BooleanSwitch from "./BooleanSwitch";

interface IResource {
  title: string;
  id: string;
  source: string;
  homonyms: [string];
  author: string;
  deleted: boolean;
  feeling: boolean;
  noun: boolean;
  stopword: boolean;
  stopwordAlways: boolean;
  stopwordButAlsoName: boolean;
  word: boolean;
  genderedPronoun: boolean;
  malePronoun: boolean;
  femalePronoun: boolean;
  genderNeutralPronoun: boolean;
  placeNameSuffix: boolean;
  organizationNameSuffix: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    inactiveType: {
      color: "#fff",
      backgroundColor: "#DCDCDD",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    activeType: {
      color: "#fff",
      backgroundColor: "#56E39F",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    listRoot: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    buttonIcon: {
      marginLeft: "4px",
    },
    typePanel: {
      display: "flex",
    },
    aliasChip: {
      height: "2em",
      borderRadius: "1em",
    },
  })
);

const greenTextFieldStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3,
    },
  },
});

const buildCreatedConcept = () => {
  let concept = {
    title: "",
    source: "imatrics",
    homonyms: [],
    author: Authenticator.getUsername() + " (Web Portal)",
    deleted: false,
    feeling: false,
    noun: false,
    stopword: false,
    stopwordAlways: false,
    word: false,
    genderedPronoun: false,
    malePronoun: false,
    femalePronoun: false,
    genderNeutralPronoun: false,
    placeNameSuffix: false,
    organizationNameSuffix: false,
  };
  return concept;
};

const ResourceModalContent = (props: any) => {
  const classes = useStyles();
  const greenTextFieldClasses = greenTextFieldStyles();
  const {
    superAdminMode,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
  }: any = useContext(CTX);
  const { keepChanges }: any = useContext(CMContext);
  const createResourceMode: boolean = props.createResource
    ? props.createResource
    : false;
  const viewOnly: boolean = props.viewOnly ? props.viewOnly : false;
  const display: Boolean = props.display;

  const resource: IResource = createResourceMode
    ? buildCreatedConcept()
    : props.resource;

  const hasPrivs: Boolean = Authenticator.hasAuthority(["CONCEPT_MANAGEMENT"]);
  const [newTitle, setNewTitle] = useState(resource.title);
  const [newId, setNewId] = useState(resource.id);
  const [newSource, setNewSource] = useState(resource.source);
  const [newHomonyms, setNewHomonyms] = useState(resource.homonyms || []);
  const [newAuthor, setNewAuthor] = useState(resource.author || "");
  const [newDeleted, setNewDeleted] = useState(resource.deleted || false);
  const [newFeeling, setNewFeeling] = useState(resource.feeling || false);
  const [newNoun, setNewNoun] = useState(resource.noun || false);
  const [newStopword, setNewStopword] = useState(resource.stopword || false);
  const [newStopwordAlways, setNewStopwordAlways] = useState(
    resource.stopwordAlways || false
  );
  const [newStopwordButAlsoName, setNewStopwordButAlsoName] = useState(
    resource.stopwordButAlsoName || false
  );
  const [newWord, setNewWord] = useState(resource.word || false);
  const [newGenderedPronoun, setNewGenderedPronoun] = useState(
    resource.genderedPronoun || false
  );
  const [newMalePronoun, setNewMalePronoun] = useState(
    resource.malePronoun || false
  );
  const [newFemalePronoun, setNewFemalePronoun] = useState(
    resource.femalePronoun || false
  );
  const [newGenderNeutralPronoun, setNewGenderNeutralPronoun] = useState(
    resource.genderNeutralPronoun || false
  );
  const [newPlaceNameSuffix, setNewPlaceNameSuffix] = useState(
    resource.placeNameSuffix || false
  );
  const [newOrganizationNameSuffix, setNewOrganizationNameSuffix] = useState(
    resource.organizationNameSuffix || false
  );
  const [deletingConcept, setDeletingConcept] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buttonIconSize = "1.8em";

  const [getResource] = useMutation(GET_RESOURCE);
  const [editResource] = useMutation(EDIT_RESOURCE);
  const [createResource] = useMutation(CREATE_RESOURCE);
  const [deleteResource] = useMutation(DELETE_RESOURCE);

  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    if (!createResourceMode) {
      setIsFetching(true);
      getResource({
        variables: {
          id: resource.id,
          language: props.language,
          cluster: props.cluster,
        },
      })
        .then((data: any) => {
          let retrievedResource: IResource = data.data.getResource;
          setNewTitle(newTitle || retrievedResource.title || "");
          setNewId(retrievedResource.id || "");
          setNewSource(retrievedResource.source);
          setNewHomonyms(retrievedResource.homonyms || []);
          setNewAuthor(retrievedResource.author || "");
          setNewDeleted(retrievedResource.deleted || false);
          setNewFeeling(retrievedResource.feeling || false);
          setNewNoun(retrievedResource.noun || false);
          setNewStopword(retrievedResource.stopword || false);
          setNewStopwordAlways(retrievedResource.stopwordAlways || false);
          setNewWord(retrievedResource.word || false);
          setNewGenderedPronoun(retrievedResource.genderedPronoun || false);
          setNewMalePronoun(retrievedResource.malePronoun || false);
          setNewFemalePronoun(retrievedResource.femalePronoun || false);
          setNewGenderNeutralPronoun(
            retrievedResource.genderNeutralPronoun || false
          );
          setNewPlaceNameSuffix(retrievedResource.placeNameSuffix || false);
          setNewOrganizationNameSuffix(
            retrievedResource.organizationNameSuffix || false
          );
        })
        .catch(() => {
          setLoadingError(true);
        })
        .finally(() => setIsFetching(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildChangedResource = () => {
    if (createResourceMode) {
      //Create should have no id
      return {
        title: newTitle,
        source: newSource,
        homonyms: newHomonyms,
        author: newAuthor,
        deleted: newDeleted,
        feeling: newFeeling,
        noun: newNoun,
        stopword: newStopword,
        stopwordAlways: newStopwordAlways,
        word: newWord,
        genderedPronoun: newGenderedPronoun,
        malePronoun: newMalePronoun,
        femalePronoun: newFemalePronoun,
        genderNeutralPronoun: newGenderNeutralPronoun,
        placeNameSuffix: newPlaceNameSuffix,
        organizationNameSuffix: newOrganizationNameSuffix,
      };
    }
    return {
      id: newId,
      title: newTitle,
      source: newSource,
      homonyms: newHomonyms,
      author: newAuthor,
      deleted: newDeleted,
      feeling: newFeeling,
      noun: newNoun,
      stopword: newStopword,
      stopwordAlways: newStopwordAlways,
      word: newWord,
      genderedPronoun: newGenderedPronoun,
      malePronoun: newMalePronoun,
      femalePronoun: newFemalePronoun,
      genderNeutralPronoun: newGenderNeutralPronoun,
      placeNameSuffix: newPlaceNameSuffix,
      organizationNameSuffix: newOrganizationNameSuffix,
    };
  };

  const EditResourceButton = () => (
    <Button
      disabled={hasPrivs ? false : true}
      className="float-right mr-2"
      variant="outlined"
      color="primary"
      onClick={() => {
        setSnackbarMessage(false);
        setIsLoading(true);
        let query = {
          language: props.language,
          cluster: props.cluster,
          ...buildChangedResource(),
        };
        editResource({
          variables: query,
        })
          .then((data: any) => {
            setIsLoading(false);
            setSnackbarMessage(data.data.editResource.message);
            setShowSnackbar(true);
            props.onHide();
            let inputField = document.getElementById("resourceSearchInput");
            if (inputField) {
              inputField.dispatchEvent(new Event("input", { bubbles: true }));
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setShowSnackbar(true);
            setSnackbarError(true);
            setSnackbarMessage(error.message);
          });
      }}
    >
      Save {superAdminMode && " (admin)"}
      <MdCheck size={buttonIconSize} className={classes.buttonIcon} />
    </Button>
  );

  const CreateResourceButton = () => (
    <Button
      disabled={hasPrivs ? false : true}
      className="float-right mr-2"
      variant="outlined"
      color="primary"
      onClick={() => {
        setSnackbarMessage(false);
        setIsLoading(true);
        let query = {
          language: props.language,
          cluster: props.cluster,
          ...buildChangedResource(),
        };
        createResource({
          variables: query,
        })
          .then((data: any) => {
            setIsLoading(false);
            setSnackbarMessage(data.data.createResource.message);
            setShowSnackbar(true);
            setNewId(data.data.createResource.id);
            props.onHide();
            let inputField = document.getElementById("resourceSearchInput");
            if (inputField) {
              inputField.dispatchEvent(new Event("input", { bubbles: true }));
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setSnackbarError("Failed to create resource");
            setShowSnackbar(true);
          });
      }}
    >
      Save {superAdminMode && " (admin)"}
      <MdCheck size={buttonIconSize} className={classes.buttonIcon} />
    </Button>
  );

  const DeleteResourceButton = () => (
    <Button
      disabled={isLoading ? true : hasPrivs ? false : true}
      className="float-left mr-2"
      variant="outlined"
      color="secondary"
      onClick={() => {
        setShowSnackbar(false);
        setIsLoading(true);
        deleteResource({
          variables: {
            id: newId,
            language: props.language,
            cluster: props.cluster,
          },
        })
          .then((data: any) => {
            setIsLoading(false);
            setShowSnackbar(true);
            setSnackbarMessage(data.data.deleteResource.message);
            setNewDeleted(true);
            props.onHide();
            let inputField = document.getElementById("resourceSearchInput");
            if (inputField) {
              inputField.dispatchEvent(new Event("input", { bubbles: true }));
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setSnackbarError("Failed to delete resource");
            setShowSnackbar(true);
          });
      }}
    >
      Confirm
      <MdDeleteForever size={buttonIconSize} className={classes.buttonIcon} />
    </Button>
  );

  useEffect(() => {
    //Ensure focus on title when opening the modal
    $("#new-title-field").focus();
  }, []);

  return (
    display && (
      <Fragment>
        {loadingError && !createResourceMode ? (
          <Box className={classes.root}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Something went wrong fetching the requested resource
            </Alert>
          </Box>
        ) : isFetching ? (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <Row noGutters>
              <Col sm={6}>
                <List className={classes.listRoot}>
                  <ListItem className={classes.root}>
                    <TextField
                      disabled
                      label={<span className="font-weight-bold">ID</span>}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newId || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <TextField
                      id="new-title-field"
                      className={
                        keepChanges?.title
                          ? greenTextFieldClasses.root
                          : undefined
                      }
                      disabled={viewOnly || (hasPrivs ? false : true)}
                      label={<span className="font-weight-bold">Title</span>}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newTitle || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <AliasInput
                      id="homonyms-input"
                      aliases={newHomonyms}
                      setAliases={setNewHomonyms}
                      label="Homonyms"
                      disabled={viewOnly}
                    />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <TextField
                      id="new-title-field"
                      className={
                        keepChanges?.title
                          ? greenTextFieldClasses.root
                          : undefined
                      }
                      disabled={viewOnly || (hasPrivs ? false : true)}
                      label={<span className="font-weight-bold">Author</span>}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newAuthor || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setNewAuthor(e.target.value)}
                    />
                  </ListItem>
                  <ListItem className={classes.root}>
                    <TextField
                      id="new-title-field"
                      className={
                        keepChanges?.title
                          ? greenTextFieldClasses.root
                          : undefined
                      }
                      disabled={viewOnly || (hasPrivs ? false : true)}
                      label={<span className="font-weight-bold">Source</span>}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newSource || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setNewSource(e.target.value)}
                    />
                  </ListItem>
                  <BooleanSwitch
                    label="Word"
                    viewOnly={viewOnly}
                    value={newWord}
                    setChange={setNewWord}
                  />
                  <BooleanSwitch
                    label="Stopword"
                    viewOnly={viewOnly}
                    value={newStopword}
                    setChange={setNewStopword}
                  />
                  <BooleanSwitch
                    label="Stopword Always"
                    viewOnly={viewOnly}
                    value={newStopwordAlways}
                    setChange={setNewStopwordAlways}
                  />
                </List>
              </Col>
              <Col sm={6}>
                <List className={classes.listRoot}>
                  <BooleanSwitch
                    label="Feeling"
                    viewOnly={viewOnly}
                    value={newFeeling}
                    setChange={setNewFeeling}
                  />
                  <BooleanSwitch
                    label="Noun"
                    viewOnly={viewOnly}
                    value={newNoun}
                    setChange={setNewNoun}
                  />
                  <BooleanSwitch
                    label="Stopword But Also Name"
                    viewOnly={viewOnly}
                    value={newStopwordButAlsoName}
                    setChange={setNewStopwordButAlsoName}
                  />
                  <BooleanSwitch
                    label="Gendered Pronoun"
                    viewOnly={viewOnly}
                    value={newGenderedPronoun}
                    setChange={setNewGenderedPronoun}
                  />
                  <BooleanSwitch
                    label="Male Pronoun"
                    viewOnly={viewOnly}
                    value={newMalePronoun}
                    setChange={setNewMalePronoun}
                  />
                  <BooleanSwitch
                    label="Female Pronoun"
                    viewOnly={viewOnly}
                    value={newFemalePronoun}
                    setChange={setNewFemalePronoun}
                  />
                  <BooleanSwitch
                    label="Gender Neutral Pronoun"
                    viewOnly={viewOnly}
                    value={newGenderNeutralPronoun}
                    setChange={setNewGenderNeutralPronoun}
                  />
                  <BooleanSwitch
                    label="Place Name Suffix"
                    viewOnly={viewOnly}
                    value={newPlaceNameSuffix}
                    setChange={setNewPlaceNameSuffix}
                  />
                  <BooleanSwitch
                    label="Organization Name Suffix"
                    viewOnly={viewOnly}
                    value={newOrganizationNameSuffix}
                    setChange={setNewOrganizationNameSuffix}
                  />
                </List>
              </Col>
            </Row>

            {!viewOnly ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="-1rem"
                mr="-1rem"
                pt={2}
                pl={2}
                pr={2}
                borderTop="1px solid #e9ecef"
              >
                <Box>
                  {!createResourceMode &&
                    (deletingConcept ? (
                      <Box>
                        <Button
                          disabled
                          className="float-left mr-2"
                          variant="outlined"
                          color="secondary"
                          onClick={() => setDeletingConcept(true)}
                        >
                          Delete Resource
                          <MdDelete
                            size={buttonIconSize}
                            className={classes.buttonIcon}
                          />
                        </Button>

                        <DeleteResourceButton />
                        {isLoading && (
                          <CircularProgress
                            style={{ marginLeft: "2em" }}
                            size="1.8em"
                          />
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          disabled={newDeleted}
                          className="float-left mr-2"
                          variant="outlined"
                          color="secondary"
                          onClick={() => setDeletingConcept(true)}
                        >
                          {newDeleted ? "Resource deleted" : "Delete resource"}
                          <MdDelete
                            size={buttonIconSize}
                            className={classes.buttonIcon}
                          />
                        </Button>
                      </Box>
                    ))}
                </Box>
                <Box>
                  <Button
                    className="float-right mr-0"
                    variant="outlined"
                    color="secondary"
                    onClick={() => props.onHide()}
                  >
                    Cancel
                    <MdClose
                      size={buttonIconSize}
                      className={classes.buttonIcon}
                    />
                  </Button>
                  {!createResourceMode && <EditResourceButton />}
                  {createResourceMode && <CreateResourceButton />}
                </Box>
              </Box>
            ) : null}
          </Fragment>
        )}
      </Fragment>
    )
  );
};

export default ResourceModalContent;

import { useState, useRef, useEffect, useContext } from "react";
import "./ConceptManagement.css";
import { useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";
import { CTX as CMContext } from "./CMContext";
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  Tooltip,
  Menu,
  Badge,
  LinearProgress,
} from "@mui/material";
import { Search, Cancel, Settings } from "@mui/icons-material";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";

import DatasetPicker from "../PanelComponents/DatasetPicker";
import SearchFilterComponent from "./SearchFilterComponent";
import CMConceptList from "./CMConceptList";

interface Concept {
  title: string;
  type: string;
  gender?: string;
  uuid: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus: boolean;
  ignore: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp: string;
  latestVersionTimestamp: string;
}

interface IErrorMessage {
  show: boolean;
  message: string;
}

const ConceptManagement = () => {
  const { superAdminMode, isExtendedAdmin, activeEndpoint }: any =
    useContext(CTX);
  const {
    entityFilter,
    excludeDrafts,
    extendSearch,
    activeFilterCount,
    setKeepChanges,
  }: any = useContext(CMContext);

  const [searchConcepts, { loading }] = useMutation(SEARCH_CONCEPTS);
  const isInitRender = useRef(true);
  let inputString = "";

  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [topicList, setTopicList] = useState<Concept[]>([]);
  const [entityList, setEntityList] = useState<Concept[]>([]);
  const [conceptSearchString, setConceptSearchString] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({
    show: false,
    message: "",
  });
  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  const handleClickFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  /** TRIGGERS A NEW SEARCH AFTER CHANGING SEARCH DEPENDENCIES */
  useEffect(() => {
    const inputField = document.getElementById("conceptSearchInput");
    if (inputField) {
      inputField.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [activeEndpoint, superAdminMode, excludeDrafts, extendSearch]);

  useEffect(() => {
    if (errorMessage.show) {
      errorTimeout.current && clearTimeout(errorTimeout.current);
      errorTimeout.current = setTimeout(() => {
        setErrorMessage({
          show: false,
          message: "",
        });
      }, 4000);
    }
  }, [errorMessage]);

  useEffect(() => {
    isInitRender.current = false;
    setKeepChanges({
      title: false,
      type: false,
      gender: false,
      shortDescription: false,
      longDescription: false,
      aliases: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SearchConceptInput = (
    <TextField
      autoFocus
      autoComplete="off"
      id="conceptSearchInput"
      value={conceptSearchString}
      type="text"
      label={
        <span>
          <Search sx={{ mr: 0.5 }} />
          Search concepts
        </span>
      }
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      size="small"
      helperText={
        errorMessage.show && (
          <Alert
            component="span"
            severity="error"
            sx={{ position: "absolute" }}
          >
            {errorMessage.message}
          </Alert>
        )
      }
      FormHelperTextProps={{
        sx: { position: "absolute", bottom: 0, zIndex: 3 },
      }}
      InputProps={{
        endAdornment: conceptSearchString ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                clearTimeouts(searchTagTimeouts);
                setConceptSearchString("");
                setCategoryList([]);
                setTopicList([]);
                setEntityList([]);
                $("#conceptSearchInput") && $("#conceptSearchInput").focus();
              }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onInput={(e: any) => {
        clearTimeouts(searchTagTimeouts);
        inputString = e.target.value; //Used to prevent search fault due to state change delay
        setConceptSearchString(e.target.value);
        setErrorMessage({
          show: false,
          message: "",
        });
        const searchTagTimeout = setTimeout(() => {
          setSearchTagTimeouts([]);
          if (!inputString) {
            setCategoryList([]);
            setTopicList([]);
            setEntityList([]);
          } else {
            searchConcepts({
              variables: {
                title: inputString,
                type: "all",
                draft: !excludeDrafts,
                extendSearch: extendSearch,
                size: 200,
                superAdmin: superAdminMode,
                extendedAdmin: isExtendedAdmin,
              },
            })
              .then((data: any) => {
                let foundCategories: any = [];
                let foundTopics: any = [];
                let foundEntities: any = [];
                data?.data?.searchConcepts?.result?.forEach(
                  (concept: Concept) => {
                    concept.type.includes("category")
                      ? (foundCategories = [...foundCategories, concept])
                      : concept.type.includes("topic")
                      ? (foundTopics = [...foundTopics, concept])
                      : (foundEntities = [...foundEntities, concept]);
                  }
                );
                setCategoryList(foundCategories);
                setTopicList(foundTopics);
                setEntityList(foundEntities);
              })
              .catch((err: Error) => {
                setErrorMessage({
                  show: true,
                  message: err.message,
                });
              });
          }
        }, 500);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }}
      sx={{ width: "33%", zIndex: 1 }}
    />
  );
  return (
    <Box>
      <Grid
        container
        sx={{
          backgroundColor: "rgba(255,255,255,0.9)",
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          zIndex: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 1.5,
            gap: 1,
          }}
        >
          {SearchConceptInput}
          <Tooltip arrow title="Open search options" enterDelay={500}>
            <IconButton color="primary" onClick={handleClickFilter}>
              <Badge
                badgeContent={activeFilterCount}
                color="success"
                slotProps={{ badge: { style: { fontWeight: 700 } } }}
              >
                <Settings />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleCloseFilter}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <SearchFilterComponent isSuperAdmin={superAdminMode} />
          </Menu>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <DatasetPicker />
          </Box>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />

      <Grid
        container
        item
        sx={{ display: "flex", width: "100%", flex: 1, py: 1, px: 2 }}
      >
        <Grid item xs={12} md={4}>
          <CMConceptList
            list={categoryList}
            listLabel="Categories"
            listType="category"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CMConceptList list={topicList} listLabel="Topics" listType="topic" />
        </Grid>
        <Grid item xs={12} md={4}>
          <CMConceptList
            list={
              entityFilter === "all"
                ? entityList
                : entityList.filter(
                    (entity: Concept) => entity.type === entityFilter
                  )
            }
            listLabel="Entities"
            listType="entity"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConceptManagement;

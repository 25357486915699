import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { getTagIcon } from "src/utils/helpers";

import {
  Paper,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Box,
  Alert,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import { StackedBarChart } from "@mui/icons-material";

import GET_LATEST_ARTICLES from "../../queries/GET_PUBLISHED_ARTICLES";

import ArticleModal from "./ArticleModal";
import EvaluationBarChart from "./EvaluationBarChart";
import ArticleSearchInput from "./ArticleSearchInput";

interface IConcept {
  uuid: string;
  title: string;
  type: string;
  usage?: number;
}

interface IProps {
  endpoint: any;
  filterConcepts: IConcept[];
  startDate: string;
  endDate: string;
  channel: string;
  newspaper: string;
  includeConcepts: string[];
  excludeAuthors: string[];
}

const getBorderColor = (uuid: String, article: any) => {
  if (!article?.evaluation) return undefined;
  return article?.evaluation?.correct.some(
    (concept: any) => concept.uuid === uuid
  )
    ? "#56E39F"
    : article.evaluation.incorrect.some((concept: any) => concept.uuid === uuid)
    ? "#EF6F6C"
    : "#FFAD75";
};

const sizeOptions = [10, 50, 100, 200];

const PublishedArticleList = (props: IProps) => {
  const {
    endpoint,
    startDate,
    endDate,
    channel,
    includeConcepts,

    excludeAuthors,
  } = props;

  const [articleModalShow, setArticleModalShow] = useState(false);
  const [showEvaluationChart, setShowEvaluationChart] = useState(true);
  const [chosenArticle, setChosenArticle] = useState<any>({
    key: "",
    uuid: "",
  });
  const [returnSize, setReturnSize] = useState<number>(10);
  const [publishedArticles, setPublishedArticles] = useState<any>(undefined);

  const handleArticleModalClose = () => {
    setArticleModalShow(false);
  };

  const handleViewMore = () => {
    setReturnSize(returnSize + 10);
  };

  const [getPublishedArticles, { loading, error }] = useLazyQuery(
    GET_LATEST_ARTICLES,
    {
      fetchPolicy: "network-only",
      variables: {
        query: {
          startDate: startDate,
          endDate: endDate,
          size: returnSize,
          channels: channel ? [channel] : [],
          concepts: includeConcepts,
          excludeAuthors: excludeAuthors,
        },
      },

      onCompleted: (data) => {
        const articles = data.getPublishedArticles.map((article: any) => {
          let formattedArticle = {
            ...article,
            concepts: article.concepts?.filter(
              (concept: any) =>
                concept.uuid &&
                [
                  "category",
                  "topic",
                  "person",
                  "place",
                  "organisation",
                  "event",
                  "object",
                ].includes(concept.type.replace("x-im/", ""))
            ),
          };
          return formattedArticle;
        });
        setPublishedArticles(articles);
      },
      onError: () => {
        setPublishedArticles([]);
      },
    }
  );

  useEffect(() => {
    setPublishedArticles(undefined);
    setReturnSize(10);
    setArticleModalShow(false);
    setChosenArticle({ key: "", uuid: "" });
    if (startDate && endDate) getPublishedArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, channel, startDate, endDate, includeConcepts, excludeAuthors]);

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
          gap: 2,
          pr: 1,
        }}
      >
        <Box sx={{ flexGrow: 0 }}>
          <Typography variant="overline" sx={{ pl: 2, fontWeight: "bold" }}>
            Published articles
          </Typography>
          <Typography component="span" variant="body2" sx={{ pl: 1 }}>
            ({publishedArticles?.length || 0})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexGrow: 1,
            gap: 1,
          }}
        >
          <TextField
            autoComplete="off"
            select
            size="small"
            label="Size"
            value={returnSize}
            onChange={(e) => setReturnSize(parseInt(e.target.value))}
            InputLabelProps={{ shrink: true }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            sx={{ width: "10%", minWidth: 100 }}
          >
            {!sizeOptions.includes(returnSize) && (
              <MenuItem disabled value={returnSize}>
                {returnSize}
              </MenuItem>
            )}
            {sizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ width: "25%", minWidth: 200 }}>
            <ArticleSearchInput />
          </Box>
          <Tooltip
            title={
              (showEvaluationChart ? "Hide" : "Show") + " evaluation chart"
            }
          >
            <IconButton
              color={showEvaluationChart ? "secondary" : "primary"}
              onClick={() => setShowEvaluationChart(!showEvaluationChart)}
            >
              <StackedBarChart />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {loading && (
        <LinearProgress
          sx={{ visibility: loading ? "visible" : "hidden", width: "100%" }}
        />
      )}
      <Grid container>
        <Grid item md={showEvaluationChart ? 9 : 12}>
          <Box
            sx={{
              overflowX: "hidden",
              overflowY: "auto",
              minHeight: 420,
              maxHeight: "60vh",
            }}
          >
            {(error || publishedArticles?.length === 0) && !loading && (
              <Alert severity="error" sx={{ display: "inline-flex", m: 2 }}>
                No article data found for the selected dataset
              </Alert>
            )}
            <ArticleModal
              chosenArticle={chosenArticle}
              articleModalShow={articleModalShow}
              handleArticleModalClose={handleArticleModalClose}
              enableClickTags
            />

            <List dense disablePadding>
              {publishedArticles?.map((article: any, i: number) => (
                <ListItem
                  key={i}
                  button
                  onClick={() => {
                    setChosenArticle(article);
                    setArticleModalShow(true);
                  }}
                  sx={{ bgcolor: i % 2 ? "rgba(0, 0, 0, 0.06)" : undefined }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      component: "div",
                      sx: { pb: 0.5, pl: 0.5 },
                    }}
                    primary={
                      <Box component="span">
                        <Typography component="span" sx={{ fontWeight: 700 }}>
                          {article.headline}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: "#777",
                            float: "right",
                            fontWeight: 700,
                          }}
                        >
                          {moment
                            .parseZone(article.publicationTimestamp)
                            .format("YYYY-MM-DD, HH:mm z")}
                        </Typography>
                      </Box>
                    }
                    secondaryTypographyProps={{ component: "div" }}
                    secondary={
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {!article?.evaluation && !article?.analysisResults ? (
                          <Alert
                            variant="filled"
                            severity="warning"
                            sx={{ display: "inline-flex", mt: 0.5 }}
                          >
                            Could not find any saved auto-tagging results
                          </Alert>
                        ) : (
                          <Box
                            component="span"
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {article?.concepts
                              ?.filter((concept: any) => concept.uuid)
                              .map((concept: IConcept, i: number) => {
                                return (
                                  <Chip
                                    key={i}
                                    component="span"
                                    variant="outlined"
                                    sx={{
                                      borderColor: getBorderColor(
                                        concept.uuid,
                                        article
                                      ),
                                      borderWidth: 2.5,
                                    }}
                                    label={concept.title}
                                    icon={getTagIcon(concept.type) ?? undefined}
                                  />
                                );
                              })}
                          </Box>
                        )}

                        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                          {article?.concepts.length +
                            ` ${article?.concepts.length > 1 ? "tags" : "tag"}`}
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          {publishedArticles?.length > 0 && (
            <Button
              variant="contained"
              fullWidth
              onClick={handleViewMore}
              sx={{
                borderRadius: showEvaluationChart ? "0 0 0 4px" : "0 0 4px 4px",
              }}
            >
              See more
            </Button>
          )}
        </Grid>
        {showEvaluationChart && publishedArticles?.length > 0 && (
          <Grid item md={3} sx={{ px: 1, py: 3 }}>
            <EvaluationBarChart data={publishedArticles} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default PublishedArticleList;

import { useEffect, useState } from "react";
import { getTagIcon } from "src/utils/helpers";
import { useQuery } from "@apollo/client";
import moment from "moment";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  LinearProgress,
  Alert,
  Divider,
  IconButton,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import SEARCH_ARTICLE from "../../queries/searchArticleQuery";

import CMConceptModal from "src/components/ConceptManagement/CMConceptModal";

const getBorderColor = (uuid: String, article: any) => {
  return article.evaluation.correct.some(
    (concept: any) => concept.uuid === uuid
  )
    ? "#56E39F"
    : article.evaluation.incorrect.some((concept: any) => concept.uuid === uuid)
    ? "#EF6F6C"
    : "#FFAD75";
};

interface IProps {
  chosenArticle: any;
  articleModalShow: boolean;
  handleArticleModalClose: () => void;
  enableClickTags?: boolean;
}

const ArticleModal = (props: IProps) => {
  const {
    chosenArticle,
    articleModalShow,
    handleArticleModalClose,
    enableClickTags,
  } = props;

  const [article, setArticle] = useState<any>(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [chosenConcept, setChosenConcept] = useState<any>({});

  const [tagClickErrorIndex, setTagClickErrorIndex] = useState<Array<number>>(
    []
  );

  const handleOnClick = (concept: any, i: number | undefined = undefined) => {
    if (!concept.uuid) {
      setTagClickErrorIndex((prev): any => [...prev, i]);
      setTimeout(() => {
        setTagClickErrorIndex((prev): any => prev.filter((c) => c !== i));
      }, 2000);
      return;
    }
    if (!enableClickTags || concept.deleted) return;
    setChosenConcept({ ...concept });
    setEditModalShow(true);
  };

  const { error, loading, data } = useQuery(SEARCH_ARTICLE, {
    variables: {
      uuid: true,
      searchString: chosenArticle.uuid,
      returnSize: 1,
    },
  });

  useEffect(() => {
    if (data?.searchArticleQuery?.length > 0) {
      setArticle(data.searchArticleQuery[0]);
    }
  }, [data]);

  return (
    <Dialog
      open={articleModalShow}
      onClose={handleArticleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <CMConceptModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        concept={chosenConcept}
        size="md"
      />

      <IconButton
        onClick={handleArticleModalClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          bgcolor: "rgba(0, 0, 0, 0.05)",
          "&:hover": { bgcolor: "rgba(0, 0, 0, 0.1)" },
        }}
      >
        <Close />
      </IconButton>

      {error ? (
        <Box sx={{ p: 4 }}>
          <Alert severity="error">Error fetching article</Alert>
        </Box>
      ) : loading ? (
        <LinearProgress />
      ) : (
        article && (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 4,
              width: "100%",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              {article?.authors?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    flexDirection: "row",
                  }}
                >
                  <AvatarGroup total={article.authors.length} max={3}>
                    {article.authors.map((author: any, i: number) => (
                      <Avatar
                        key={i}
                        alt={author}
                        sx={{ bgcolor: "primary.main" }}
                      >
                        {author.charAt(0).toUpperCase()}
                      </Avatar>
                    ))}
                  </AvatarGroup>

                  <Typography variant="body2">
                    {article.authors?.join(", ")}
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                {(article?.dateline || article?.publicationTimestamp) && (
                  <Typography variant="overline">
                    {moment
                      .parseZone(
                        article.dateline || article.publicationTimestamp
                      )
                      .format("YYYY-MM-DD, HH:mm z")}
                  </Typography>
                )}
                {article?.minuteRead > 0 && (
                  <>
                    <span>|</span>
                    <Typography variant="overline">
                      {article.minuteRead} minutes read
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat", fontWeight: 700 }}
              >
                {article.headline}
              </Typography>

              {article.preamble && (
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Roboto, sans-serif" }}
                >
                  {article.preamble}
                </Typography>
              )}
              {article?.body?.length > 0 && (
                <Typography
                  component="div"
                  sx={{ fontFamily: "Roboto, sans-serif" }}
                >
                  {article.body.map((paragraph: String, i: number) => (
                    <p key={i}>{paragraph}</p>
                  ))}
                </Typography>
              )}
            </Box>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.75,
                }}
              >
                {article?.concepts?.map((concept: any, i: number) => (
                  <Chip
                    key={i}
                    variant={
                      tagClickErrorIndex.includes(i) ? "filled" : "outlined"
                    }
                    color={tagClickErrorIndex.includes(i) ? "error" : "primary"}
                    label={concept.title}
                    sx={{
                      fontWeight: 700,
                      borderWidth: 2,
                    }}
                    icon={getTagIcon(concept.type) ?? undefined}
                    onClick={() => handleOnClick(concept, i)}
                  />
                ))}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2">Tag evaluation:</Typography>
              <Box sx={{ mt: 0.5 }}>
                {!chosenArticle?.evaluation &&
                !chosenArticle?.analysisResults ? (
                  <Alert
                    variant="filled"
                    severity="warning"
                    sx={{ display: "inline-flex" }}
                  >
                    Could not find any saved auto-tagging results
                  </Alert>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.75,
                    }}
                  >
                    {chosenArticle?.concepts?.map((concept: any, i: number) => (
                      <Chip
                        key={i}
                        variant={
                          tagClickErrorIndex.includes(i) ? "filled" : "outlined"
                        }
                        color={
                          tagClickErrorIndex.includes(i) ? "error" : "default"
                        }
                        label={concept.title}
                        icon={getTagIcon(concept.type) ?? undefined}
                        onClick={() => handleOnClick(concept, i)}
                        sx={{
                          fontWeight: 700,
                          borderColor: getBorderColor(
                            concept.uuid,
                            chosenArticle
                          ),
                          borderWidth: 2.5,
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
        )
      )}

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignitems: "center",
          bgcolor: "rgba(0, 0, 0, 0.05)",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {article?.uuid && (
          <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
            Article UUID:
            <br />
            <b>{article.uuid}</b>
          </Typography>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={handleArticleModalClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleModal;

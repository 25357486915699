import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { CTX } from "../../utils/ContextStore";
import { useMutation } from "@apollo/client";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  Autocomplete,
  DialogActions,
  CircularProgress,
  Alert,
  Typography,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import ADD_USER from "src/mutations/ADD_USER";
import QueueInputComponent from "./QueueInputComponent";

const unformatQueue = (queueListified: any[]) => {
  if (!queueListified) return undefined;
  const queueData: any = {};
  queueListified?.forEach((item) => {
    queueData[item.key] = item.value;
  });
  try {
    return JSON.stringify(queueData);
  } catch (err) {
    return undefined;
  }
};

interface IProps {
  open: boolean;
  onClose: () => void;
  getUsers: () => void;
  privOptions: string[];
  apiOptions: string[];
}

const AddUserModal = ({
  getUsers,
  privOptions,
  apiOptions,
  ...rest
}: IProps) => {
  const {
    superAdminMode,
    selectedEndpoints,
    endpointList,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
  }: any = useContext(CTX);

  const preFillUser = () => {
    return {
      username: "",
      password: undefined,
      privs: [],
      docPrivs: [],
      webdemo: {
        dataset:
          superAdminMode || selectedEndpoints.length === 0
            ? ""
            : !Number.isNaN(selectedEndpoints[0]?.settingsName)
            ? parseInt(selectedEndpoints[0]?.settingsName)
            : "",
        expires: moment().add(1, "y").format("YYYY-MM-DD"),
        max_api_calls: 500,
        api_calls: 0,
      },
      queue:
        superAdminMode || selectedEndpoints.length === 0
          ? []
          : [{ key: `gate${selectedEndpoints[0]?.settingsName}`, value: -1 }],
    };
  };

  const [newUser, setNewUser] = useState<any>(preFillUser());

  const [addUser, { loading }] = useMutation(ADD_USER, {
    onCompleted: (data) => {
      setNewUser({ ...newUser, password: data.addUser.password });
      setSnackbarMessage(data.addUser.username + " created successfully");
      setSnackbarError(false);
      setShowSnackbar(true);
      getUsers();
      //rest.onClose();
    },
    onError: (error) => {
      setSnackbarMessage(error.message);
      setSnackbarError(true);
      setShowSnackbar(true);
    },
  });

  const handleAddUser = (newUser: any) => {
    delete newUser.password;
    if (!newUser.privs.includes("QUEUE")) newUser.queue = undefined;
    newUser = { ...newUser, queue: unformatQueue(newUser.queue) };
    addUser({ variables: { userdata: newUser } });
  };

  const resetUserFields = () => {
    setNewUser(preFillUser());
  };

  const handleAddQueueRow = () => {
    const newQueue = [...newUser.queue, { key: "", value: -1 }];
    setNewUser({ ...newUser, queue: newQueue });
  };

  const handleDeleteQueueRow = (queueIndex: number) => {
    const newQueue = [...newUser.queue];
    newQueue.splice(queueIndex, 1);
    setNewUser({ ...newUser, queue: newQueue });
  };

  const onChangeQueueRow = (e: any, queueIndex: number) => {
    const newQueue = [...newUser.queue];
    newQueue[queueIndex].key = e.target.value;
    setNewUser({ ...newUser, queue: newQueue });
  };

  const onChangeQueueNumber = (e: any, queueIndex: number) => {
    const newQueue = [...newUser.queue];
    newQueue[queueIndex].value = !e.target.value
      ? ""
      : +e.target.value < -1
      ? -1
      : +e.target.value;
    setNewUser({ ...newUser, queue: newQueue });
  };

  useEffect(() => {
    if (!rest.open) {
      resetUserFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.open]);

  return (
    <Dialog fullWidth maxWidth="md" {...rest}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <DialogTitle sx={{ p: 0 }}>Add user</DialogTitle>
        <IconButton size="small" onClick={rest.onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid container item direction="column" xs={6} sx={{ gap: 3 }}>
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Username"
              type="text"
              name="new-user-username"
              InputLabelProps={{ shrink: true }}
              value={newUser.username}
              onChange={(e) => {
                setNewUser({ ...newUser, username: e.target.value });
              }}
            />

            <TextField
              autoComplete="off"
              disabled={!superAdminMode}
              select
              label="Dataset"
              variant="standard"
              fullWidth
              value={newUser.webdemo.dataset}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: { fontWeight: 700 },
              }}
              helperText={
                Number.isNaN(newUser.webdemo.dataset) && (
                  <Typography color="error">Unsupported NaN input</Typography>
                )
              }
              onChange={(e: { target: { value: string } }) => {
                if (!superAdminMode) return;
                setNewUser({
                  ...newUser,
                  webdemo: {
                    ...newUser.webdemo,
                    dataset: !e.target.value ? "" : +e.target.value,
                  },
                });
              }}
            >
              {endpointList
                .sort((a: any, b: any) =>
                  a.settingsName.localeCompare(b.settingsName)
                )
                .map((option: any) => (
                  <MenuItem key={option.name} value={option.settingsName}>
                    <b>{option.settingsName}</b>&nbsp;— {option.name}
                  </MenuItem>
                ))}
            </TextField>

            <Autocomplete
              multiple
              limitTags={4}
              fullWidth
              size="small"
              options={privOptions}
              getOptionLabel={(option) => option}
              value={newUser.privs}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  variant="standard"
                  label="Privs"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(e: any, newValue: any) => {
                setNewUser({ ...newUser, privs: newValue });
              }}
            />

            {newUser?.privs?.includes("API_DOCS") && (
              <Autocomplete
                multiple
                limitTags={4}
                fullWidth
                size="small"
                options={apiOptions}
                getOptionLabel={(option) => option}
                value={newUser.docPrivs}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    {...params}
                    variant="standard"
                    label="API Docs privs"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e: any, newValue: any) => {
                  setNewUser({ ...newUser, docPrivs: newValue });
                }}
              />
            )}

            {newUser?.privs?.includes("QUEUE") && (
              <QueueInputComponent
                isSuperAdmin={superAdminMode}
                row={newUser}
                onChangeQueueRow={onChangeQueueRow}
                onChangeQueueNumber={onChangeQueueNumber}
                handleAddQueueRow={handleAddQueueRow}
                handleDeleteQueueRow={handleDeleteQueueRow}
              />
            )}
          </Grid>
          <Grid container item direction="column" xs={6} sx={{ gap: 3 }}>
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Valid until"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={newUser.webdemo.expires}
              onChange={(e) => {
                setNewUser({
                  ...newUser,
                  webdemo: {
                    ...newUser.webdemo,
                    expires: moment(e.target.value).format("YYYY-MM-DD"),
                  },
                });
              }}
            />

            <TextField
              disabled={!superAdminMode}
              autoComplete="off"
              variant="standard"
              type="number"
              fullWidth
              size="small"
              label="Max API calls"
              InputLabelProps={{ shrink: true }}
              value={newUser.webdemo.max_api_calls}
              onChange={(e) => {
                if (!superAdminMode) return;
                setNewUser({
                  ...newUser,
                  webdemo: {
                    ...newUser.webdemo,
                    max_api_calls: !e.target.value ? "" : +e.target.value,
                  },
                });
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              {newUser.password && (
                <Alert
                  variant="filled"
                  severity="success"
                  //sx={{ width: "100%" }}
                >
                  User created with the password:
                  <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                    {newUser.password}
                  </Typography>
                </Alert>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size="small" />}
        <Button variant="contained" color="secondary" onClick={rest.onClose}>
          {newUser.password ? "Close" : "Cancel"}
        </Button>
        <Button
          disabled={loading || newUser.password ? true : false}
          variant="contained"
          onClick={() => {
            if (loading) return;
            handleAddUser(newUser);
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;

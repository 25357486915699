import { useState, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Chip,
  TextField,
  Grid,
  Alert,
  AlertTitle,
  Switch,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { Settings, ExpandMore, Cancel } from "@mui/icons-material";

const StyledAccordionSummary = withStyles({
  content: { m: 0 },
  root: {
    minHeight: 36,
    maxHeight: 36,
    "&.Mui-expanded": {
      minHeight: 36,
      maxHeight: 36,
    },
  },
  expandIcon: {
    order: -1,
  },
})(AccordionSummary);

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box sx={{ pt: 3, px: 2 }}>{children}</Box>}
    </Box>
  );
};
const AdvancedSettingComponent = () => {
  const { tagSettings, setTagSettings, articleFormat, setArticleFormat }: any =
    useContext(tagCTX);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, selectedTab: number) => {
    setActiveTab(selectedTab);
  };

  interface IDefaultFilter {
    id: string;
    label: string;
    value: number;
  }

  const [filterVariables, setFilterVariables] = useState<Array<IDefaultFilter>>(
    [
      {
        id: "categoryFilterVariable",
        label: "Category filter variable",
        value: -1,
      },
      {
        id: "topicFilterVariable",
        label: "Topic filter variable",
        value: -1,
      },
      {
        id: "personFilterVariable",
        label: "Person filter variable",
        value: -1,
      },
      {
        id: "placeFilterVariable",
        label: "Place filter variable",
        value: -1,
      },
      {
        id: "organisationFilterVariable",
        label: "Organisation filter variable",
        value: -1,
      },
      {
        id: "eventFilterVariable",
        label: "Event filter variable",
        value: -1,
      },
      {
        id: "objectFilterVariable",
        label: "Object filter variable",
        value: -1,
      },
    ]
  );

  const [filterCount, setFilterCount] = useState<number>(0);

  const [entityTypeLimits, setEntityTypeLimits] = useState<
    Array<IDefaultFilter>
  >([
    {
      id: "personLimit",
      label: "Person limit",
      value: -1,
    },
    {
      id: "placeLimit",
      label: "Place limit",
      value: -1,
    },
    {
      id: "organisationLimit",
      label: "Organisation limit",
      value: -1,
    },
    {
      id: "eventLimit",
      label: "Event limit",
      value: -1,
    },
    {
      id: "objectLimit",
      label: "Object limit",
      value: -1,
    },
  ]);

  const [entityWeightModifiers, setEntityWeightModifiers] = useState<
    Array<IDefaultFilter>
  >([
    {
      id: "entityWeightHeadlineModifier",
      label: "Headline weight modifier",
      value: -1,
    },
    {
      id: "entityWeightPreambleModifier",
      label: "Preamble weight modifier",
      value: -1,
    },
    {
      id: "entityWeightBodyModifier",
      label: "Body weight modifier",
      value: -1,
    },
    {
      id: "entityWeightDatelineModifier",
      label: "Dateline weight modifier",
      value: -1,
    },
    {
      id: "entityWeightImageTextModifier",
      label: "Image text weight modifier",
      value: -1,
    },
  ]);

  interface IFilter {
    categoryFilterVariable: number;
    topicFilterVariable: number;
    personFilterVariable: number;
    placeFilterVariable: number;
    organisationFilterVariable: number;
    eventFilterVariable: number;
    objectFilterVariable: number;
    personLimit: number;
    placeLimit: number;
    organisationLimit: number;
    eventLimit: number;
    objectLimit: number;
    entityWeightHeadlineModifier: number;
    entityWeightPreambleModifier: number;
    entityWeightBodyModifier: number;
    entityWeightDatelineModifier: number;
    entityWeightImageTextModifier: number;
  }

  const updateFilterCount = (filter: IFilter) => {
    let count = 0;
    for (const value of Object.values(filter)) {
      if (value !== -1) {
        count++;
      }
    }
    setFilterCount(count);
  };

  const clearFilter = () => {
    let newVal = { ...tagSettings };
    let newEntityTypeLimits = [...entityTypeLimits];
    let newFilterVariables = [...filterVariables];
    let newEntityWeightModifiers = [...entityWeightModifiers];
    entityTypeLimits.forEach((entityTypeLimit, i) => {
      newVal[entityTypeLimit.id] = -1;
      newEntityTypeLimits[i].value = -1;
    });
    filterVariables.forEach((filterVariable, i) => {
      newVal[filterVariable.id] = -1;
      newFilterVariables[i].value = -1;
    });
    entityWeightModifiers.forEach((weightModifier, i) => {
      newVal[weightModifier.id] = -1;
      newEntityWeightModifiers[i].value = -1;
    });
    setEntityTypeLimits(newEntityTypeLimits);
    setFilterVariables(newFilterVariables);
    setEntityWeightModifiers(newEntityWeightModifiers);
    setTagSettings(newVal);
    updateFilterCount(newVal);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    clearFilter();
  };

  const onSubmit = () => {
    let newVal = { ...tagSettings };
    entityTypeLimits.forEach((entityTypeLimit) => {
      newVal[entityTypeLimit.id] = entityTypeLimit.value;
    });
    filterVariables.forEach((filterVariable) => {
      newVal[filterVariable.id] = filterVariable.value;
    });
    entityWeightModifiers.forEach((weightModifier) => {
      newVal[weightModifier.id] = weightModifier.value;
    });
    setTagSettings(newVal);
    updateFilterCount(newVal);
  };

  return (
    <Accordion expanded={expanded} sx={{ width: "100%" }}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore color="primary" />}
        sx={{
          px: 0.5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Tooltip
            title="Set article format sent to server. If checked, the format will be in JSON, otherwise clean text. You can only modify the advanced settings in JSON format."
            placement="top"
          >
            <Box component="span" sx={{ whiteSpace: "nowrap" }}>
              <Switch
                checked={articleFormat === "json"}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  const format = e.target.checked ? "json" : undefined;
                  setArticleFormat(format);
                  setTagSettings({
                    ...tagSettings,
                    articleFormat: format,
                  });
                }}
              />
              <Typography
                component="span"
                variant="overline"
                sx={{ fontWeight: 700 }}
              >
                JSON
              </Typography>
            </Box>
          </Tooltip>

          <Typography
            component="span"
            variant="button"
            color="primary"
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
            sx={{
              display: "flex",
              pr: 0.5,
              textAlign: "right",
              fontWeight: 700,
              alignItems: "center",
              gap: 1,
            }}
          >
            {articleFormat === "json" && filterCount > 0 && (
              <Chip
                label={
                  filterCount +
                  (filterCount === 1 ? " active filter" : " active filters")
                }
                color="success"
                variant="filled"
                size="small"
                onDelete={onDelete}
              />
            )}
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Settings fontSize="small" />
              Advanced settings
            </Box>
          </Typography>
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails
        sx={
          articleFormat !== "json"
            ? {
                bgcolor: "rgba(0,0,0,0.125)",
                opacity: 0.75,
              }
            : undefined
        }
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mx: -2 }}>
            <Tabs
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
            >
              <Tab label="Filter variables" sx={{ py: 0, px: 2 }} />
              <Tab label="Entity type limits" sx={{ py: 0, px: 2 }} />
              <Tab label="Entity weight modifiers" sx={{ py: 0, px: 2 }} />
            </Tabs>
          </Box>
          <TabPanel value={activeTab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                {filterVariables.map(
                  (filterVariable: IDefaultFilter, i: number) => (
                    <Box
                      key={filterVariable.id}
                      sx={{ display: "flex", alignItems: "center", mb: 2 }}
                    >
                      <TextField
                        autoComplete="off"
                        disabled={articleFormat !== "json"}
                        type="number"
                        id={filterVariable.id}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={
                          filterVariable.value !== -1
                            ? filterVariable.value
                            : ""
                        }
                        label={filterVariable.label}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let newVal = [...filterVariables];
                          if (
                            e.target.value === "" ||
                            e.target.valueAsNumber <= -1
                          ) {
                            newVal[i].value = -1;
                            setFilterVariables(newVal);
                          } else {
                            newVal[i].value = e.target.valueAsNumber;
                            setFilterVariables(newVal);
                          }
                        }}
                      />

                      <Cancel
                        onClick={() => {
                          let newVal = [...filterVariables];
                          newVal[i].value = -1;
                          setFilterVariables(newVal);
                        }}
                        sx={{
                          cursor: "pointer",
                          ml: 0.5,
                          visibility:
                            filterVariable.value !== -1 ? "visible" : "hidden",
                        }}
                      />
                    </Box>
                  )
                )}
              </Grid>
              <Grid item xs={7}>
                <Alert severity="info">
                  <AlertTitle sx={{ fontWeight: 700 }}>
                    Filter variables
                  </AlertTitle>
                  <Typography variant="body2">
                    You can use filter variables for tweaking how many entities
                    of each type that will be returned based on the score of the
                    top entity. You can set these to nothing, or a positive
                    number.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                {entityTypeLimits.map(
                  (entityTypeLimit: IDefaultFilter, i: number) => (
                    <Box
                      key={entityTypeLimit.id}
                      sx={{ display: "flex", alignItems: "center", mb: 2 }}
                    >
                      <TextField
                        autoComplete="off"
                        disabled={articleFormat !== "json"}
                        type="number"
                        id={entityTypeLimit.id}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={
                          entityTypeLimit.value !== -1
                            ? entityTypeLimit.value
                            : ""
                        }
                        label={entityTypeLimit.label}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let newVal = [...entityTypeLimits];
                          if (
                            e.target.value === "" ||
                            e.target.valueAsNumber <= -1
                          ) {
                            newVal[i].value = -1;
                            setEntityTypeLimits(newVal);
                          } else {
                            newVal[i].value = e.target.valueAsNumber;
                            setEntityTypeLimits(newVal);
                          }
                        }}
                      />
                      <Cancel
                        onClick={() => {
                          let newVal = [...entityTypeLimits];
                          newVal[i].value = -1;
                          setEntityTypeLimits(newVal);
                        }}
                        sx={{
                          cursor: "pointer",
                          ml: 0.5,
                          visibility:
                            entityTypeLimit.value !== -1 ? "visible" : "hidden",
                        }}
                      />
                    </Box>
                  )
                )}
              </Grid>
              <Grid item xs={7}>
                <Alert severity="info">
                  <AlertTitle sx={{ fontWeight: 700 }}>
                    Entity type limits
                  </AlertTitle>
                  <Typography variant="body2">
                    You can use entity type limits for tweaking the maximum
                    amount of entities of each type that will be returned. You
                    can set these to nothing, or a positive number.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                {entityWeightModifiers.map(
                  (weightModifier: IDefaultFilter, i: number) => (
                    <Box
                      key={weightModifier.id}
                      sx={{ display: "flex", alignItems: "center", mb: 2 }}
                    >
                      <TextField
                        autoComplete="off"
                        disabled={articleFormat !== "json"}
                        type="number"
                        id={weightModifier.id}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={
                          weightModifier.value !== -1
                            ? weightModifier.value
                            : ""
                        }
                        label={weightModifier.label}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let newVal = [...entityWeightModifiers];
                          if (e.target.valueAsNumber <= -1) {
                            newVal.forEach((item) => {
                              if (item.value > -1) {
                                item.value = -1;
                              }
                            });

                            newVal[i].value = -1;
                          } else {
                            newVal.forEach((item) => {
                              if (item.value < 0) {
                                item.value = 1;
                              }
                            });

                            newVal[i].value = e.target.valueAsNumber;
                          }
                          setEntityWeightModifiers(newVal);
                        }}
                      />
                    </Box>
                  )
                )}
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    let newEntityWeightModifiers = [...entityWeightModifiers];
                    entityWeightModifiers.forEach((weightModifier, i) => {
                      newEntityWeightModifiers[i].value = -1;
                    });
                    setEntityWeightModifiers(newEntityWeightModifiers);
                  }}
                >
                  <Cancel sx={{ mr: 0.5 }} />
                  Clear modifiers
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Alert severity="info">
                  <AlertTitle sx={{ fontWeight: 700 }}>
                    Entity weight modifiers
                  </AlertTitle>
                  <Typography variant="body2">
                    You can use weight modifiers for tweaking the score on each
                    entity based on in which part of the article it is
                    mentioned. Each mention gets the score of the corresponding
                    modifier. If you set one of these variables to a number, all
                    the others will default to 1.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
          <Button variant="contained" size="small" onClick={onSubmit}>
            Apply filters
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvancedSettingComponent;

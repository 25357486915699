import { useMemo } from "react";
import moment from "moment";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";

const customColors = ["#56E39F", "#EF6F6C", "#FFAD75"];

interface IProps {
  data: any;
}

const EvaluationBarChart = (props: IProps) => {
  let { data } = props;

  const plotData = useMemo(() => {
    if (!data) return [];
    let groupedData = data.reduce((acc: any, item: any) => {
      const date = item.publicationTimestamp.split("T")[0];
      if (!acc[date]) {
        acc[date] = {
          date,
          "auto-tagged": 0,
          removed: 0,
          added: 0,
          total: 0,
        };
      }
      acc[date]["auto-tagged"] += item?.evaluation?.correct?.length ?? 0;
      acc[date].removed += item?.evaluation?.incorrect?.length ?? 0;
      acc[date].added += item?.evaluation?.missed?.length ?? 0;
      acc[date].total =
        acc[date]["auto-tagged"] + acc[date].removed + acc[date].added;
      return acc;
    }, {});
    groupedData = Object.values(groupedData);
    groupedData = groupedData.reverse();
    return groupedData;
  }, [data]);

  return (
    <ResponsiveBar
      data={plotData}
      keys={["auto-tagged", "removed", "added"]}
      indexBy="date"
      margin={{ top: 45, right: 110, bottom: 45, left: 35 }}
      padding={0.05}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={customColors}
      layout="vertical"
      defs={[]}
      fill={[]}
      tooltip={(d: any) => {
        return (
          <BasicTooltip
            id={d.data.date}
            value={`${d.value} (${((d.value / d.data.total) * 100).toFixed(
              1
            )}%)`}
            color={d.color}
            enableChip
          />
        );
      }}
      enableTotals
      borderColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      axisTop={{
        legend: "Auto-tagging evaluation",
        legendPosition: "start",
        legendOffset: -40,
        tickSize: 0,
        format: () => "",
      }}
      axisBottom={{
        tickSize: 4,
        tickPadding: 5,
        tickRotation: -30,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 40,
        truncateTickAt: 0,
        format: (value) => {
          const date = moment(value).format("DD");
          const firstTickIsEven =
            Number(moment(plotData[0].date).format("DD")) % 2 === 0;
          if (plotData.length > 32 && plotData.length <= 90) {
            return moment(value).format("[W]WW");
          } else if (plotData.length > 90) {
            return moment(value).format("MMM YY");
          } else if (
            plotData.length > 20 &&
            (firstTickIsEven ? Number(date) % 2 !== 0 : Number(date) % 2 === 0)
          ) {
            return "";
          } else {
            return moment(value).format("Do");
          }
        },
      }}
      axisLeft={undefined}
      axisRight={undefined}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default EvaluationBarChart;

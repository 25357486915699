import { useState, useRef, Fragment, KeyboardEvent, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Paper,
  TextField,
  MenuList,
  MenuItem,
  Grow,
  ClickAwayListener,
  Popper,
  IconButton,
} from "@mui/material";
import {
  Search,
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
  Cancel,
} from "@mui/icons-material";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";

let searchTimeout: ReturnType<typeof setTimeout>;

interface IProps {
  endpoint: any;
  addToConceptList: (concept: any) => void;
}

const ConceptSearchField = (props: IProps) => {
  const { endpoint, addToConceptList } = props;
  const [searchConceptString, setSearchConceptString] = useState("");
  const [concepts] = useState([]);
  const conceptRef = useRef<HTMLInputElement>(null);
  const [openConceptList, setOpenConceptList] = useState(false);
  const [conceptSuggestions, setConceptSuggestions] = useState([]);
  const [focusConceptList] = useState(false);

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);

  const handleListKeyDownConceptInput = (event: KeyboardEvent) => {
    if (event.key === "ArrowDown" || event.key === "Tab") {
      event.preventDefault();
      $("#concept-suggestion-list li:first").focus();
    }
  };

  const handleListKeyDownConceptList = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      if ($("#concept-suggestion-list li:focus").is(":last-child")) {
        $("#concept-suggestion-list li:first").focus();
      } else $("#concept-suggestion-list li:focus").next().focus();
    }
  };

  const checkDuplicate = (item: any, list: any[]) => {
    let hasDuplicate = false;
    list.forEach((tag: any) => {
      if (tag.title === item.title) {
        hasDuplicate = true;
      }
    });
    return hasDuplicate;
  };

  const addConcept = (item: any) => {
    if (item.uuid && !checkDuplicate(item, concepts)) {
      addToConceptList(item);
      setSearchConceptString("");
      setConceptSuggestions([]);
    }
  };

  const handleOpenConceptList = () => {
    setOpenConceptList(true);
  };

  const handleCloseConceptList = () => {
    setOpenConceptList(false);
  };

  const handleClearInput = () => {
    setSearchConceptString("");
    setConceptSuggestions([]);
  };

  useEffect(() => {
    searchTimeout && clearTimeout(searchTimeout);
    if (searchConceptString.length > 1) {
      searchTimeout = setTimeout(() => {
        searchConcepts({
          variables: {
            title: searchConceptString,
            type: "all",
            draft: false,
            size: 15,
          },
          onCompleted: (data: any) => {
            setConceptSuggestions(data.searchConcepts.result);
            handleOpenConceptList();
          },
          onError: (err) => {
            setConceptSuggestions([]);
          },
        });
      }, 500);
    } else if (searchConceptString.length === 0) {
      setConceptSuggestions([]);
      handleCloseConceptList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConceptString]);

  useEffect(() => {
    searchTimeout && clearTimeout(searchTimeout);
    if (endpoint) {
      setSearchConceptString("");
      setConceptSuggestions([]);
      handleCloseConceptList();
    }
  }, [endpoint]);

  return (
    <Fragment>
      <TextField
        autoComplete="off"
        fullWidth
        type="text"
        variant="outlined"
        size="small"
        label={
          <span>
            <Search sx={{ mr: 0.5 }} />
            Search concepts
          </span>
        }
        value={searchConceptString}
        ref={conceptRef}
        InputLabelProps={{
          shrink: true,
        }}
        aria-controls={openConceptList ? "concept-suggestion-list" : undefined}
        aria-haspopup="true"
        InputProps={{
          endAdornment:
            searchConceptString.length > 0 ? (
              <IconButton
                edge="end"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearInput();
                }}
              >
                <Cancel fontSize="small" />
              </IconButton>
            ) : null,
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenConceptList();
        }}
        onKeyDown={handleListKeyDownConceptInput}
        onChange={(e: any) => {
          setSearchConceptString(e.target.value);
        }}
      />

      <Box className="popper-wrapper">
        <Popper
          open={openConceptList}
          anchorEl={conceptRef.current}
          transition
          placement="bottom-start"
          disablePortal={true}
          modifiers={[
            {
              name: "flip",
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: false,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className="popover-index dropdown-transition">
                <ClickAwayListener onClickAway={handleCloseConceptList}>
                  <MenuList
                    id="category-suggestion-list"
                    dense
                    disablePadding
                    autoFocusItem={focusConceptList}
                    onKeyDown={handleListKeyDownConceptList}
                    sx={{ maxHeight: 250, overflowY: "auto" }}
                  >
                    {conceptSuggestions !== null &&
                    conceptSuggestions?.length !== 0
                      ? conceptSuggestions.map(
                          (concept: any, index: number) => (
                            <MenuItem
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                addConcept(concept);
                              }}
                              sx={{ px: 2 }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {concept.type.includes("category") ? (
                                  <Description fontSize="small" />
                                ) : concept.type.includes("topic") ? (
                                  <Grain fontSize="small" />
                                ) : concept.type.includes("person") ? (
                                  <Person fontSize="small" />
                                ) : concept.type.includes("place") ? (
                                  <LocationOn fontSize="small" />
                                ) : concept.type.includes("organisation") ? (
                                  <AccountBalance fontSize="small" />
                                ) : concept.type.includes("event") ? (
                                  <EventNote fontSize="small" />
                                ) : concept.type.includes("object") ? (
                                  <GridView fontSize="small" />
                                ) : null}
                                <Box
                                  component="span"
                                  sx={{ fontWeight: "bold", pl: 1 }}
                                >
                                  {concept.title}
                                </Box>
                                {concept.shortDescription &&
                                  " — " + concept.shortDescription}
                              </Box>
                            </MenuItem>
                          )
                        )
                      : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Fragment>
  );
};

export default ConceptSearchField;

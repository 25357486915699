import { Fragment } from "react";
import "./SideBar.css";
import moment from "moment";
import logo from "../../images/logo/Logo.png";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Authenticator } from "../../utils/Authenticator";
import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import {
  Box,
  Grid,
  List,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
  Loyalty,
  Public,
  Poll,
  Extension,
  ChevronRight,
  ExpandMore,
  Newspaper,
  AdminPanelSettings,
  Diamond,
  VolunteerActivism,
  CollectionsBookmark,
} from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    copyrightContainer: {
      position: "fixed",
      display: "block",
      float: "left",
      color: "#fff",
      bottom: "0",
      padding: "4px 12px",
    },
    copyrightAnchor: {
      color: "#e8eef2",
      "&:hover": {
        color: "#e8eef2",
        opacity: 0.8,
        textDecoration: "underline",
      },
    },
  })
);

interface Props {
  location: any;
}

const SideBar = (props: Props) => {
  const classes = useStyles();
  const { location } = props;

  return (
    <Box className="sidebar" sx={{ overflowY: "auto" }}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          <Link
            to="/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              marginTop: "16px",
            }}
          >
            <img src={logo} alt="imatrics" className="grow-logo" width="40%" />
          </Link>
        </Grid>
        <Grid item>
          <List disablePadding>
            {Authenticator.hasAuthority([PRIVILEGES.TAGGING]) && (
              <Fragment>
                <Link to={"/autotagging"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        window.location.pathname.indexOf("/autotagging") !== -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <Loyalty />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary="Auto-tagging Demo"
                    />
                    {!Authenticator.hasAuthority([
                      PRIVILEGES.QUEUE,
                    ]) ? null : location.pathname.indexOf("/autotagging") !==
                      -1 ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </ListItem>
                </Link>
                <Collapse
                  in={location.pathname.indexOf("/autotagging") !== -1}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {Authenticator.hasAuthority([PRIVILEGES.QUEUE]) && (
                      <Link to={"/autotagging/tag-quality-assurance"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/autotagging/tag-quality-assurance"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Tag Quality Assurance" />
                        </ListItem>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </Fragment>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.PAT]) && (
              <Link to="/pat">
                <ListItem
                  dense
                  button
                  className={classnames("sidebar-list-item", {
                    activeSidebarListItem:
                      location.pathname.indexOf("/pat") !== -1,
                  })}
                >
                  <ListItemIcon className="sidebar-icon mr-3">
                    <CollectionsBookmark />
                  </ListItemIcon>
                  <ListItemText primary="PAT" disableTypography />
                </ListItem>
              </Link>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.QUALITY_EVALUATOR]) && (
              <Link to={"/auto-tagging-quality-evaluator"}>
                <ListItem
                  dense
                  button
                  className={classnames("sidebar-list-item", {
                    activeSidebarListItem:
                      location.pathname.indexOf(
                        "/auto-tagging-quality-evaluator"
                      ) !== -1,
                  })}
                >
                  <ListItemIcon className="sidebar-icon mr-3">
                    <Diamond />
                  </ListItemIcon>
                  <ListItemText
                    primary="Auto-tagging Quality Evaluator"
                    disableTypography
                  />
                </ListItem>
              </Link>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.CONCEPT_MANAGEMENT]) && (
              <Fragment>
                <Link to={"/concept-management"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        location.pathname.indexOf("/concept-management") !==
                          -1 && location.pathname.indexOf("/api-docs") === -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <Public />
                    </ListItemIcon>
                    <ListItemText
                      primary="Concept Management"
                      disableTypography
                    />
                    {location.pathname.indexOf("/concept-management") !== -1 &&
                    location.pathname.indexOf("/api-docs") === -1 ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </ListItem>
                </Link>
                <Collapse
                  in={
                    location.pathname.indexOf("/concept-management") !== -1 &&
                    location.pathname.indexOf("/api-docs") === -1
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {Authenticator.hasAuthority([
                      PRIVILEGES.CONCEPT_MANAGEMENT,
                    ]) && (
                      <Link to={"/concept-management/taxonomy-explorer"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/concept-management/taxonomy-explorer"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Taxonomy Explorer" />
                        </ListItem>
                      </Link>
                    )}
                    {Authenticator.hasAuthority([
                      PRIVILEGES.CONCEPT_SUGGESTIONS,
                    ]) && (
                      <Link to={"/concept-management/suggestions"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/concept-management/suggestions"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Concept Suggestions" />
                        </ListItem>
                      </Link>
                    )}

                    {Authenticator.hasAuthority([PRIVILEGES.ADMIN]) && (
                      <Link to={"/concept-management/resources"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/concept-management/resources"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Resource Management" />
                        </ListItem>
                      </Link>
                    )}
                    {Authenticator.hasAuthority([PRIVILEGES.ADMIN]) && (
                      <Link to={"/concept-management/missing-data"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/concept-management/missing-data"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Missing Data Detector" />
                        </ListItem>
                      </Link>
                    )}
                    {Authenticator.hasAuthority([PRIVILEGES.ADMIN]) && (
                      <Link to={"/concept-management/custom-analysis-rules"}>
                        <ListItem
                          dense
                          button
                          className={classnames("sidebar-list-item pl-4", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/concept-management/custom-analysis-rules"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Custom Analysis Rules" />
                        </ListItem>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </Fragment>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.PER]) && (
              <Link to={"/personalization-center"}>
                <ListItem
                  dense
                  button
                  className={classnames("sidebar-list-item", {
                    activeSidebarListItem:
                      location.pathname.indexOf("/personalization-center") !==
                      -1,
                  })}
                >
                  <ListItemIcon className="sidebar-icon mr-3">
                    <VolunteerActivism />
                  </ListItemIcon>
                  <ListItemText
                    primary="Personalization Center"
                    disableTypography
                  />
                </ListItem>
              </Link>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.PRODUCTION_INSIGHTS]) && (
              <Fragment>
                <Link to={"/production-insights"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        location.pathname.indexOf("/production-insights") !==
                        -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <Poll />
                    </ListItemIcon>
                    <ListItemText
                      primary="Production Insights"
                      disableTypography
                    />
                    {location.pathname.indexOf("/production-insights") !==
                    -1 ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </ListItem>
                </Link>
                <Collapse
                  in={location.pathname.indexOf("/production-insights") !== -1}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    <Link to={"/production-insights/production-overview"}>
                      <ListItem
                        dense
                        button
                        className={classnames("sidebar-list-item pl-4", {
                          activeSidebarListItemSub:
                            location.pathname.indexOf(
                              "/production-insights/production-overview"
                            ) !== -1,
                        })}
                      >
                        <ListItemIcon className="sidebar-icon mr-1">
                          <ChevronRight />
                        </ListItemIcon>
                        <ListItemText primary="Publication" />
                      </ListItem>
                    </Link>

                    <Link to={"/production-insights/concept-statistics"}>
                      <ListItem
                        dense
                        button
                        className={classnames("sidebar-list-item pl-4", {
                          activeSidebarListItemSub:
                            location.pathname.indexOf(
                              "/production-insights/concept-statistics"
                            ) !== -1,
                        })}
                      >
                        <ListItemIcon className="sidebar-icon mr-1">
                          <ChevronRight />
                        </ListItemIcon>
                        <ListItemText primary="Concept" />
                      </ListItem>
                    </Link>
                    <Link to={"/production-insights/compare-concepts"}>
                      <ListItem
                        dense
                        button
                        className={classnames("sidebar-list-item pl-4", {
                          activeSidebarListItemSub:
                            location.pathname.indexOf(
                              "/production-insights/compare-concepts"
                            ) !== -1,
                        })}
                      >
                        <ListItemIcon className="sidebar-icon mr-1">
                          <ChevronRight />
                        </ListItemIcon>
                        <ListItemText primary="Compare Concepts" />
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </Fragment>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.TIMES]) && (
              <Fragment>
                <Link to={"/imatrics-times"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        location.pathname.indexOf("/imatrics-times") !== -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <Newspaper />
                    </ListItemIcon>
                    <ListItemText primary="iMatrics Times" disableTypography />
                  </ListItem>
                </Link>

                <List disablePadding>
                  <Link to="/imatrics-times/topic-page-management">
                    <ListItem
                      dense
                      button
                      className={classnames("sidebar-list-item pl-4", {
                        activeSidebarListItemSub:
                          location.pathname.indexOf(
                            "/imatrics-times/topic-page-management"
                          ) !== -1,
                      })}
                    >
                      <ListItemIcon className="sidebar-icon mr-1">
                        <ChevronRight />
                      </ListItemIcon>
                      <ListItemText primary="Topic Page Management" />
                    </ListItem>
                  </Link>
                </List>
              </Fragment>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.API_DOCS]) && (
              <Fragment>
                <Link to={"/api-docs"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        location.pathname.indexOf("/api-docs") !== -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <Extension />
                    </ListItemIcon>
                    <ListItemText primary="API Docs" disableTypography />
                  </ListItem>
                </Link>
              </Fragment>
            )}

            {Authenticator.hasAuthority([PRIVILEGES.USER_ADMIN]) && (
              <Fragment>
                <Link to={"/admin-panel"}>
                  <ListItem
                    className={classnames("sidebar-list-item", {
                      activeSidebarListItem:
                        location.pathname.indexOf("/admin-panel") !== -1,
                    })}
                  >
                    <ListItemIcon className="sidebar-icon mr-3">
                      <AdminPanelSettings />
                    </ListItemIcon>
                    <ListItemText primary="Admin Panel" disableTypography />
                    {location.pathname.indexOf("/admin-panel") !== -1 ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </ListItem>
                </Link>
                <Collapse
                  in={location.pathname.indexOf("/admin-panel") !== -1}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {Authenticator.hasAuthority([PRIVILEGES.USER_ADMIN]) && (
                      <Link to={"/admin-panel/users"}>
                        <ListItem
                          dense
                          className={classnames("sidebar-list-item pl-5", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/admin-panel/users"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Users" />
                        </ListItem>
                      </Link>
                    )}
                    {Authenticator.hasAuthority([PRIVILEGES.ADMIN]) && (
                      <Link to={"/admin-panel/customer-settings"}>
                        <ListItem
                          dense
                          className={classnames("sidebar-list-item pl-5", {
                            activeSidebarListItemSub:
                              location.pathname.indexOf(
                                "/admin-panel/customer-settings"
                              ) !== -1,
                          })}
                        >
                          <ListItemIcon className="sidebar-icon mr-1">
                            <ChevronRight />
                          </ListItemIcon>
                          <ListItemText primary="Customer Settings" />
                        </ListItem>
                      </Link>
                    )}
                  </List>
                </Collapse>
              </Fragment>
            )}
          </List>
        </Grid>
      </Grid>
      <Box height="24px" />
      <span className={classes.copyrightContainer}>
        {`©${moment().year()} `}
        <a
          className={classes.copyrightAnchor}
          href="https://imatrics.com/"
          target="_blank"
          rel="noreferrer"
        >
          iMatrics
        </a>
      </span>
    </Box>
  );
};

export default SideBar;

import { Fragment, useState, useEffect } from "react";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AddCircle } from "@mui/icons-material";
import OutlinedDiv from "./OutlinedDiv";

const useStyles = makeStyles(() => ({
  outerChip: {
    maxWidth: "100%",
    height: "fit-content",
    padding: "0.1rem",
    margin: "2px 14px 2px -2px",
    backgroundColor: "rgba(232, 237, 242, 0.35)",
    cursor: "default",
  },
  innerChip: {
    margin: "2px 14px 2px -10px",
    backgroundColor: "#fff",
  },
  chipInput: {
    marginLeft: "-10px",
    border: 0,
    backgroundColor: "inherit",
    transform: "translateY(2px)",
  },
  inputForm: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

interface Props {
  disabled?: boolean;
  words: any;
  setWords: any;
  label: any;
  id: string;
  adornment: any;
  isInheriting: boolean;
  setIsInheriting?: (inherit: boolean) => void;
  isSuperAdmin?: boolean;
  isExtendedAdmin?: boolean;
  mergedWords?: any;
}

const RelationInput = (props: Props) => {
  const classes = useStyles();
  const {
    words,
    setWords,
    label,
    id,
    disabled,
    adornment,
    isSuperAdmin,
    isExtendedAdmin,
    mergedWords,
    isInheriting,
    setIsInheriting,
  } = props;

  const handleAddOuterChip = () => {
    if (!words) setWords([[]]);
    else setWords([...words, []]);
  };

  const handleAddInnerChip = (newInput: string, outerIndex: number) => {
    newInput !== "" &&
      newInput !== " " &&
      setWords(
        words?.map((outerWords: any, i: number) => {
          if (i === outerIndex) {
            return [...outerWords, newInput];
          }
          return outerWords;
        })
      );
  };

  const handleDeleteOuterChipByIndex = (outerIndex: number) => {
    setWords(words.filter((outerWord: any, i: number) => i !== outerIndex));
  };

  const handleDeleteInnerChipByIndex = (
    outerIndex: number,
    innerIndex: number
  ) => {
    setWords(
      words?.map((outerWord: any, i: number) => {
        if (i === outerIndex) {
          return outerWord.filter(
            (innerWord: any, j: number) => j !== innerIndex
          );
        } else return outerWord;
      })
    );
  };

  const arrayIncludes = (array: string[][], word: string[]) => {
    let res: boolean = false;
    try {
      let wordStr: string = JSON.stringify(word);
      array?.forEach((item) => {
        let itemStr: string = JSON.stringify(item);
        if (wordStr === itemStr) res = true;
      });
    } catch {}
    return res;
  };

  useEffect(() => {
    if (!mergedWords || isSuperAdmin) {
      return;
    }
    if (isInheriting && isExtendedAdmin) {
      setWords([...mergedWords]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInheriting]);

  useEffect(() => {
    let merged: any[] = [];
    if (isExtendedAdmin && !isSuperAdmin) {
      words?.forEach((word: string[]) => {
        if (!arrayIncludes(merged, word)) merged.push(word);
      });

      try {
        if (JSON.stringify(merged) === JSON.stringify(mergedWords)) {
          !isInheriting && setIsInheriting && setIsInheriting(true);
        } else {
          isInheriting && setIsInheriting && setIsInheriting(false);
        }
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words]);

  const ChipInput = ({ outerIndex, id }: any) => {
    const [newInput, setNewInput] = useState("");
    return (
      <form
        className={classes.inputForm}
        autoComplete={"off"}
        onSubmit={(e: any) => {
          e.preventDefault();
          handleAddInnerChip(newInput, outerIndex);
          setTimeout(() => {
            $(`#${id}` + outerIndex).focus();
          }, 20);
        }}
      >
        <input
          id={id + outerIndex}
          name="word"
          data-lpignore="true"
          className={`${classes.chipInput} font-weight-500`}
          autoComplete={"off"}
          value={newInput}
          style={
            newInput.length < 2
              ? { width: "3ch" }
              : { width: newInput.length + 1 + "ch" }
          }
          onChange={(e: any) => setNewInput(e.target.value)}
          onBlur={() => {
            /** Adds the input string when clicking away */
            handleAddInnerChip(newInput, outerIndex);
          }}
        />
      </form>
    );
  };

  const RelationChip = () => {
    return (
      <OutlinedDiv
        label={label}
        disabled={disabled}
        error={disabled}
        adornment={
          <Fragment>
            {((isExtendedAdmin && !isSuperAdmin) || !isInheriting) && (
              <Tooltip title="Add word" placement="top">
                <span>
                  <IconButton
                    disabled={
                      isExtendedAdmin && !isSuperAdmin
                        ? false
                        : isInheriting || disabled
                        ? true
                        : false
                    }
                    color="primary"
                    size="small"
                    sx={{ ml: 0.5 }}
                    onClick={() => {
                      handleAddOuterChip();
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {adornment}
          </Fragment>
        }
      >
        <Box sx={{ minHeight: "17.81px" }}>
          {(isInheriting ? mergedWords : words)?.map(
            (wordCombo: any, i: number) => (
              <Chip
                onClick={(e: any) => {
                  $(`#${id}` + i).focus();
                }}
                disabled={
                  disabled
                    ? true
                    : isExtendedAdmin && !isSuperAdmin
                    ? false
                    : isInheriting
                    ? true
                    : false
                }
                className={`${classes.outerChip} outer-chip`}
                key={i}
                variant="outlined"
                color={
                  isExtendedAdmin && !isSuperAdmin
                    ? "default"
                    : isInheriting && arrayIncludes(mergedWords, wordCombo)
                    ? "success"
                    : "default"
                }
                label={
                  <Box sx={{ whiteSpace: "normal" }}>
                    {wordCombo?.map((word: string, j: number) => (
                      <Chip
                        key={j}
                        color={
                          isExtendedAdmin && !isSuperAdmin
                            ? "primary"
                            : isInheriting &&
                              arrayIncludes(mergedWords, wordCombo)
                            ? "success"
                            : "primary"
                        }
                        className={classes.innerChip}
                        size="small"
                        variant="outlined"
                        label={word}
                        onDelete={() => {
                          handleDeleteInnerChipByIndex(i, j);
                        }}
                      />
                    ))}
                    <ChipInput outerIndex={i} id={id} />
                  </Box>
                }
                onDelete={() => {
                  handleDeleteOuterChipByIndex(i);
                }}
              />
            )
          )}
        </Box>
      </OutlinedDiv>
    );
  };

  return <RelationChip />;
};

export default RelationInput;

import gql from "graphql-tag";

const GET_QUEUE_ARTICLE = gql`
  query ($query: GetQueueArticleInput) {
    getQueueArticle(query: $query) {
      uuid
      headline
      preamble
      body
      dateline
      authors
      concepts {
        weight
        title
        type
        uuid
        shortDescription
        wikipedia
        wikidata
        openStreetMap
        source
        longDescription
        pubStatus
        aliases
        author
        createdTimestamp
        latestVersionTimestamp
        broader
        geoJSON
        links {
          id
          relationType
          source
          uri
          url
        }
      }
      publicationTimestamp
      latestVersionTimestamp
      channels
      newspapers
      queueNr
      finished
    }
  }
`;

export default GET_QUEUE_ARTICLE;

import { createContext, useState, useEffect, useMemo } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import { useQuery, useMutation } from "@apollo/client";
import GET_CONCEPT from "../../mutations/GET_CONCEPT";
import GET_CONCEPT_CHANGE_SUGGESTIONS from "../../mutations/getConceptChangeSuggestions";
import UPDATE_CONCEPT_CHANGE_SUGGESTIONS from "../../mutations/updateConceptChangeSuggestions";
import GET_CLUSTERS from "../../queries/GET_CLUSTERS";

interface ICluster {
  cluster_name: string;
  cluster_languages: string[];
}

export const CTX = createContext(null);

export default function CMContext(props: any) {
  const [getConcept] = useMutation(GET_CONCEPT);
  const [getConceptChangeSuggestions] = useMutation(
    GET_CONCEPT_CHANGE_SUGGESTIONS
  );
  const [updateConceptChangeSuggestions] = useMutation(
    UPDATE_CONCEPT_CHANGE_SUGGESTIONS
  );

  /** CM modal states */
  const [showConceptModal, setShowConceptModal] = useState<boolean>(false);
  const [showCreateConceptModal, setShowCreateConceptModal] =
    useState<boolean>(false);

  /** Filter states */
  const [entityFilter, setEntityFilter] = useState<string>("all");
  const [excludeDrafts, setExcludeDrafts] = useState<boolean>(false);
  const [extendSearch, setExtendSearch] = useState<boolean>(false);

  /** Concept change suggestion states */
  const [showHistoryLog, setShowHistoryLog] = useState<Boolean>(false);
  const [suggestionInbox, setSuggestionInbox] = useState<any>(undefined);
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>(undefined);
  const [disabledInboxItems, setDisabledInboxItems] = useState(false);
  const [disabledPanel, setDisabledPanel] = useState<boolean>(false);
  const [currentConcept, setCurrentConcept] = useState<any>(undefined);
  const [changedConcept, setChangedConcept] = useState<any>(undefined);
  const [keepChanges, setKeepChanges] = useState<any>({
    title: false,
    type: false,
    shortDescription: false,
    longDescription: false,
    aliases: false,
    broader: false,
    gender: false,
  });

  const [pageNumber, setPageNumber] = useState(0);
  const [suggestionFilter, setSuggestionFilter] = useState<
    "unhandled" | "handled" | "all"
  >("unhandled");
  const [suggestionSearchString, setSuggestionSearchString] =
    useState<string>("");

  const [clusterData, setClusterData] = useState<Array<ICluster>>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [clusters, setClusters] = useState<string[]>([]);
  const [customers, setCustomers] = useState<string[]>([]);

  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedCluster, setSelectedCluster] = useState<string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");

  const [wikiMode, setWikiMode] = useState<boolean>(false);
  const [wikiConcept, setWikiConcept] = useState<any>(undefined);
  const [wikiConceptConverted, setWikiConceptConverted] =
    useState<any>(undefined);

  const activeFilterCount = useMemo(() => {
    let filterNumber = 0;
    if (entityFilter !== "all") filterNumber++;
    if (excludeDrafts) filterNumber++;
    if (extendSearch) filterNumber++;
    return filterNumber;
  }, [entityFilter, excludeDrafts, extendSearch]);

  useQuery(GET_CLUSTERS, {
    onError: (err) => {},
    onCompleted: (data: any) => {
      if (data?.getClusters) {
        setClusterData(data.getClusters);
        const clusterNames = data.getClusters.map(
          (cluster: any) => cluster.cluster_name
        );
        setClusters(clusterNames);
        setSelectedCluster(
          clusterNames.find(
            (clusterName: string) => clusterName === "PRODUCTION"
          ) ??
            clusterNames[0] ??
            ""
        );
      }
    },
    skip: !Authenticator.hasAuthority([PRIVILEGES.ADMIN]),
  });

  useEffect(() => {
    if (selectedCluster) {
      const languages =
        clusterData.find(
          (cluster: ICluster) => selectedCluster === cluster.cluster_name
        )?.cluster_languages ?? [];
      setLanguages(languages);
      !languages.includes(selectedLanguage) &&
        setSelectedLanguage(languages[0] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCluster]);

  return (
    <CTX.Provider
      value={
        {
          entityFilter,
          setEntityFilter,
          excludeDrafts,
          setExcludeDrafts,
          extendSearch,
          setExtendSearch,
          activeFilterCount,

          disabledInboxItems,
          setDisabledInboxItems,
          getConcept,
          getConceptChangeSuggestions,
          updateConceptChangeSuggestions,
          suggestionInbox,
          setSuggestionInbox,
          selectedSuggestion,
          setSelectedSuggestion,
          currentConcept,
          setCurrentConcept,
          changedConcept,
          setChangedConcept,
          keepChanges,
          setKeepChanges,
          showConceptModal,
          setShowConceptModal,
          showCreateConceptModal,
          setShowCreateConceptModal,
          showHistoryLog,
          setShowHistoryLog,
          pageNumber,
          setPageNumber,
          suggestionFilter,
          setSuggestionFilter,
          suggestionSearchString,
          setSuggestionSearchString,
          disabledPanel,
          setDisabledPanel,

          languages,
          clusters,
          customers,
          setCustomers,
          selectedLanguage,
          setSelectedLanguage,
          selectedCluster,
          setSelectedCluster,
          selectedCustomer,
          setSelectedCustomer,

          wikiMode,
          setWikiMode,
          wikiConcept,
          setWikiConcept,
          wikiConceptConverted,
          setWikiConceptConverted,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}

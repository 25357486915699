import gql from "graphql-tag";

const EXPORT_ARTICLE_QUEUE = gql`
  query ($query: ExportArticleQueueInput) {
    exportArticleQueue(query: $query) {
      queueName
      completed
      skipped
      inProgress
      channels
      queue {
        customQueue
        queueName
        queueNr
        articleUUID
        isDone
        lastUpdate
        username
        channels
        concepts {
          weight
          title
          type
          uuid
          shortDescription
          wikipedia
          wikidata
          openStreetMap
          source
          longDescription
          pubStatus
          aliases
          author
          createdTimestamp
          latestVersionTimestamp
          broader
          geoJSON
          links {
            id
            relationType
            source
            uri
            url
          }
        }
      }
    }
  }
`;

export default EXPORT_ARTICLE_QUEUE;

import React, {
  Fragment,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { getTagIcon } from "src/utils/helpers";
import { useMutation } from "@apollo/client";
import { CTX as tagCTX } from "./TagContext";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Grow,
  MenuList,
  MenuItem,
  Tooltip,
  ClickAwayListener,
  Popper,
  Alert,
  LinearProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Add, Cancel } from "@mui/icons-material";

import SEARCH_CONCEPTS from "src/mutations/searchConcepts";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.25)",
      fontSize: "12px",
    },
  })
);

interface IErrorMessage {
  show: boolean;
  message: string;
}

interface IProps {
  type: string;
  disabled?: boolean;
  tagQualityAssurance?: boolean;
  tagQueueArticle?: any;
  tagQueueProps?: any;
}

const SearchTagsComponent = (props: IProps) => {
  const classes = useStyles();
  const {
    type,
    disabled,
    tagQueueArticle,
    tagQueueProps = {},
    tagQualityAssurance,
  } = props;
  const {
    categories,
    topics,
    entities,
    addConcept,
    addConcepts,
    getConceptAndRelatedConcepts,
    clearTimeouts,
    setCreateConceptModalShow,
    setCreateTopicModalShow,
    formatTag,
    setRecentConcepts,
  }: any = useContext(tagCTX);

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);

  const [searchString, setSearchString] = useState<string>("");
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [openSuggestionList, setOpenSuggestionList] = useState<boolean>(false);
  const [focusSuggestionList] = useState(false);
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({
    show: false,
    message: "",
  });
  const [loadingAddTag, setLoadingAddTag] = useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const isEntity = useRef(type === "entity");
  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleOpenSuggestionList = () => {
    setOpenSuggestionList(true);
  };

  const handleCloseSuggestionList = () => {
    setOpenSuggestionList(false);
  };

  const handleListKeyDownInput = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowDown" || event.key === "Tab") {
      event.preventDefault();
      $("#suggestion-list li:first").focus();
    }
  };

  const handleListKeyDownList = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      if ($("#suggestion-list li:focus").is(":last-child")) {
        $("#suggestion-list li:first").focus();
      } else $("#suggestion-list li:focus").next().focus();
    }
  };

  useEffect(() => {
    clearTimeouts(searchTagTimeouts);
    setErrorMessage({ show: false, message: "" });
    handleCloseSuggestionList();
    if (disabled) return;
    if (searchString !== "") {
      let searchTagTimeout = setTimeout(() => {
        setSearchTagTimeouts([]);
        searchConcepts({
          variables: {
            title: searchString,
            type: type,
            draft: false,
            size: 10,
          },
        })
          .then((data: any) => {
            data?.data?.searchConcepts?.result &&
              setTagSuggestions(data.data.searchConcepts.result);
            handleOpenSuggestionList();
          })
          .catch((err: Error) => {
            setErrorMessage({ show: true, message: err.message });
          });
      }, 500);
      setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      $("#suggestion-dropdown").show();
    } else {
      setTagSuggestions([]);
      $("#suggestion-dropdown").hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const bottomRef = useRef<any>(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isEntity.current) {
      scrollToBottom();
    }
  }, [tagSuggestions]);

  useEffect(() => {
    if (isEntity.current && openSuggestionList) {
      scrollToBottom();
    }
  }, [openSuggestionList]);

  useEffect(() => {
    if (errorMessage.show) {
      errorTimeout.current && clearTimeout(errorTimeout.current);
      errorTimeout.current = setTimeout(() => {
        setErrorMessage({
          show: false,
          message: "",
        });
      }, 4000);
    }
  }, [errorMessage]);

  return (
    <Fragment>
      <TextField
        disabled={disabled}
        id={"search-tag-input-" + type}
        autoComplete="off"
        fullWidth
        type="text"
        variant="outlined"
        size="small"
        label={
          type === "category"
            ? "Add Category"
            : type === "topic"
            ? "Add Topic"
            : type === "entity"
            ? "Add Entity"
            : ""
        }
        value={searchString}
        ref={inputRef}
        helperText={
          errorMessage.show && (
            <Alert severity="error" sx={{ position: "absolute" }}>
              {errorMessage.message}
            </Alert>
          )
        }
        FormHelperTextProps={{
          sx: { position: "absolute", bottom: 0, zIndex: 3 },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        aria-controls={openSuggestionList ? "suggestion-list" : undefined}
        aria-haspopup="true"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchString && (
                <IconButton
                  size="small"
                  sx={type === "entity" ? { marginRight: "0.6em" } : undefined}
                  onClick={() => {
                    setSearchString("");
                    setTagSuggestions([]);
                    $("#search-tag-input-" + type) &&
                      $("#search-tag-input-" + type).focus();
                  }}
                >
                  <Cancel />
                </IconButton>
              )}

              {type !== "category" && (
                <Tooltip title={"Create new " + type} placement="top">
                  <IconButton
                    disabled={disabled}
                    size="small"
                    id="new-entity-button"
                    onClick={() => {
                      if (type === "topic") setCreateTopicModalShow(true);
                      else setCreateConceptModalShow(true);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
        onClick={() => {
          handleOpenSuggestionList();
        }}
        onKeyDown={handleListKeyDownInput}
        onInput={(e: any) => {
          setSearchString(e.target.value);
        }}
      />
      <Box className="popper-wrapper">
        <Popper
          id="suggestion-dropdown"
          open={searchString.length > 0 && openSuggestionList}
          anchorEl={inputRef.current}
          transition
          placement="bottom-start"
          disablePortal={true}
          modifiers={[
            {
              name: "flip",
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: false,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: false,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className="popover-index dropdown-transition">
                {loadingAddTag && <LinearProgress />}
                <ClickAwayListener onClickAway={handleCloseSuggestionList}>
                  <MenuList
                    id="suggestion-list"
                    dense
                    disablePadding
                    autoFocusItem={focusSuggestionList}
                    onKeyDown={handleListKeyDownList}
                  >
                    {tagSuggestions.length < 1 && (
                      <MenuItem
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography color="error" variant="subtitle2">
                          No results found
                        </Typography>
                      </MenuItem>
                    )}
                    {tagSuggestions.map((item: any) => (
                      <Tooltip
                        key={item.uuid}
                        title={item.shortDescription}
                        aria-label="concept-description"
                        placement="left"
                        arrow
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                      >
                        <MenuItem
                          disabled={loadingAddTag}
                          onClick={async (e: any) => {
                            e.preventDefault();
                            setLoadingAddTag(true);
                            const addTagTimeout = setTimeout(() => {
                              setLoadingAddTag(false);
                            }, 10000);
                            if (tagQualityAssurance && tagQueueArticle) {
                              let conceptsToAdd: Array<any> = [];
                              if (item.type.includes("topic")) {
                                conceptsToAdd =
                                  await getConceptAndRelatedConcepts(item);
                                setRecentConcepts((prev: any) => {
                                  return [...prev, ...conceptsToAdd];
                                });
                              } else {
                                conceptsToAdd = [item];
                              }

                              tagQueueArticle({
                                variables: {
                                  query: {
                                    finished: false,
                                    concepts: [
                                      ...categories,
                                      ...topics,
                                      ...entities,
                                      ...conceptsToAdd.map((concept: any) =>
                                        formatTag(concept)
                                      ),
                                    ],
                                    customQueue: false,
                                    queueName: undefined,
                                    queueNr: undefined,
                                    ...tagQueueProps,
                                  },
                                },
                                onCompleted: () => {
                                  addConcepts(conceptsToAdd);
                                  setSearchString("");
                                  setTagSuggestions([]);
                                },
                              });
                            } else {
                              if (item.type.includes("topic")) {
                                const conceptsToAdd =
                                  await getConceptAndRelatedConcepts(item);
                                setRecentConcepts((prev: any) => {
                                  return [...prev, ...conceptsToAdd];
                                });
                                addConcepts(conceptsToAdd);
                              } else {
                                addConcept(item);
                              }
                              setSearchString("");
                              setTagSuggestions([]);
                            }
                            clearTimeout(addTagTimeout);
                            setLoadingAddTag(false);
                          }}
                          sx={{
                            display: "flex",
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            px: 1,
                            gap: 1,
                          }}
                        >
                          {getTagIcon(item.type)}
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ fontWeight: 700 }}
                          >
                            {item.title}
                          </Typography>

                          {item.shortDescription ? (
                            <Typography component="span" variant="body2">
                              {" "}
                              - {item.shortDescription}
                            </Typography>
                          ) : null}
                        </MenuItem>
                      </Tooltip>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div ref={bottomRef} />
      </Box>
    </Fragment>
  );
};

export default SearchTagsComponent;

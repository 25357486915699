import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import ResourceModalContent from "./ResourceModalContent";
import { Close } from "@mui/icons-material";

const ResourceModal = (props: any) => {
  const createResource: boolean = props.createResource
    ? props.createResource
    : false;

  const setAddedResource = props.setAddedResource
    ? props.setAddedResource
    : null;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.show}
      onClose={props.onHide}
      scroll="body"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          backgroundColor: "rgba(232, 237, 242, 0.4)",
          px: 2,
          py: 1,
        }}
      >
        {createResource ? "Create Resource" : "Edit Resource"}

        <IconButton aria-label="close" onClick={props.onHide}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ResourceModalContent
          resource={props.resource}
          language={props.language}
          cluster={props.cluster}
          display
          onHide={props.onHide}
          createResource={createResource}
          setAddedConcept={setAddedResource}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ResourceModal;

import React, { useState, useRef, useEffect, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import {
  Box,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Tooltip,
  ClickAwayListener,
  Popper,
  ListItemText,
} from "@mui/material";
import { PlayArrow, Stop, ExpandMore } from "@mui/icons-material";

interface IProps {
  stopDemo(): void;
  disabled?: boolean;
}

const PlayDemoButton = (props: IProps) => {
  const { stopDemo, disabled } = props;
  const anchorRef = useRef<HTMLDivElement>(null);
  const {
    demoArticles,
    selectedDemoArticle,
    setSelectedDemoArticle,
    isPlaying,
    setIsPlaying,
  }: any = useContext(tagCTX);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (demoArticles && demoArticles.length > 0) {
      setSelectedDemoArticle(demoArticles[0]);
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoArticles]);

  const handleClick = () => {
    setIsPlaying(true);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    i: number
  ) => {
    setSelectedIndex(i);
    setSelectedDemoArticle(demoArticles[i]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box component="span" sx={{ whiteSpace: "nowrap" }}>
      <ButtonGroup
        disabled={disabled || !selectedDemoArticle}
        disableElevation={disabled || !selectedDemoArticle}
        variant="contained"
        ref={anchorRef}
        aria-label="demo button"
      >
        {!isPlaying ? (
          <Tooltip
            arrow
            placement="top"
            title={
              <span>
                Demo: <b>{selectedDemoArticle?.headline}</b>
              </span>
            }
            enterDelay={500}
          >
            <span>
              <Button
                color="success"
                onClick={handleClick}
                sx={{
                  width: "140px",
                  gap: 0.5,
                  overflow: "hidden",
                  borderRight: "0 !important",
                }}
              >
                <PlayArrow />
                {`Play demo${
                  (selectedDemoArticle && selectedIndex) || selectedIndex === 0
                    ? ` ${selectedIndex + 1}`
                    : ""
                }`}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip arrow placement="top" title="Stop demo" enterDelay={500}>
            <span>
              <Button
                color="secondary"
                onClick={stopDemo}
                sx={{
                  width: "140px",
                  gap: 0.5,
                  overflow: "hidden",
                  borderRight: "0 !important",
                }}
              >
                <Stop />
                Stop demo
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip
          arrow
          placement="top"
          title="Select demo article"
          enterDelay={500}
        >
          <span>
            <Button
              disabled={isPlaying}
              color="success"
              aria-controls={open ? "demo-articles-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select demo article"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{ px: 0 }}
            >
              <ExpandMore />
            </Button>
          </span>
        </Tooltip>
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="demo-articles-button-menu"
                  dense
                  disablePadding
                  sx={{ minWidth: "180px", maxWidth: "270px" }}
                >
                  {demoArticles.map((article: any, i: number) => (
                    <Tooltip
                      key={i}
                      title={article.headline}
                      placement="right"
                      arrow
                    >
                      <MenuItem
                        selected={i === selectedIndex}
                        onClick={(e) => handleMenuItemClick(e, i)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ListItemText
                          primary={
                            `${i + 1 + ". " + article.altHeadline}` ||
                            `Demo article ${i + 1}`
                          }
                          secondary={article.headline}
                          primaryTypographyProps={{ fontWeight: 700 }}
                          secondaryTypographyProps={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </MenuItem>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default PlayDemoButton;

import { Fragment, useState, useEffect, useContext } from "react";
import moment from "moment";
import { getTagIcon } from "../../../utils/helpers";
import { CTX } from "../CMContext";

import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  Pagination,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { CheckCircle } from "@mui/icons-material";

import { useCurrentWidth } from "../../../utils/helpers";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: "100%",
      maxHeight: "100%",
      borderRight: "1px solid rgba(0,0,0,0.125)",
      overflowY: "auto",
    },
    listFooter: {
      height: "67px",
      backgroundColor: "rgba(255,255,255,0.9)",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      borderTop: "1px solid rgba(0,0,0,0.125)",
    },
    fontBold: { fontWeight: 700 },
    marginRight: {
      marginRight: "0.4rem",
    },
    bgWhite: {
      backgroundColor: "rgba(255,255,255,0.75)",
      transition: "500ms linear",
    },
    bgTrans: {
      backgroundColor: "rgba(255,255,255,0.75)",
      transition: "500ms linear",
    },
  })
);

/**
 * Returns an array with arrays of the given size
 *
 * @param array {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
const chunkArray = (array: Array<Object>, chunkSize: number) => {
  let chunkedArray = [];
  let tempArray = array.slice();
  if (tempArray)
    while (tempArray.length) {
      chunkedArray.push(tempArray.splice(0, chunkSize));
    }
  return chunkedArray;
};

const sortByDate = (arr: any) => {
  const sortedArr = Array.isArray(arr)
    ? arr.sort((a: any, b: any) =>
        moment(b.reportDate).diff(moment(a.reportDate))
      )
    : [];
  return sortedArr;
};

const ListItemCustomized = withStyles({
  root: {
    display: "block",
    padding: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
    borderBottom: "1px solid rgba(0,0,0,0.125)",
    "&$selected": {
      backgroundColor: "rgba(255,255,255,0.25)",
    },
    "&$selected:hover": {},
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.4)",
    },
  },
  selected: {},
})(ListItem);

const ConceptList = () => {
  const classes = useStyles();
  const [selectedItemIndex, setSelectedItemIndex] = useState<any>(null);

  const {
    selectedSuggestion,
    setSelectedSuggestion,
    disabledInboxItems,
    setDisabledPanel,
    getConceptChangeSuggestions,
    suggestionInbox,
    setSuggestionInbox,
    setCurrentConcept,
    pageNumber,
    setPageNumber,
    suggestionFilter,
    suggestionSearchString,
  }: any = useContext(CTX);

  let width = useCurrentWidth();
  const changePagehandler = (
    event: React.ChangeEvent<unknown>,
    newPageNumber: number
  ) => {
    if (pageNumber !== newPageNumber - 1) {
      setPageNumber(newPageNumber - 1);

      const inboxElem = document.getElementById("suggestion-inbox");
      if (inboxElem) inboxElem.scrollTop = 0;
    }
  };
  const [searchResult, setSearchResult] = useState(null);
  const pageChunkSize = 20;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSuggestionInbox(null);
    setLoading(true);
    setError(false);
    setDisabledPanel(true);
    if (suggestionFilter === "unhandled") {
      getConceptChangeSuggestions({
        variables: {
          handled: false,
        },
      })
        .then((data: any) => {
          if (data?.data?.getConceptChangeSuggestions?.result) {
            setSuggestionInbox(data.data.getConceptChangeSuggestions.result);
          } else setError(true);
        })
        .catch((err: Error) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          setDisabledPanel(false);
        });
    } else if (suggestionFilter === "handled") {
      getConceptChangeSuggestions({
        variables: {
          handled: true,
        },
      })
        .then((data: any) => {
          if (data?.data?.getConceptChangeSuggestions?.result) {
            setSuggestionInbox(data.data.getConceptChangeSuggestions.result);
          } else setError(true);
        })
        .catch((err: Error) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          setDisabledPanel(false);
        });
    } else {
      getConceptChangeSuggestions({
        variables: {
          handled: true,
        },
      })
        .then((data1: any) => {
          if (data1?.data?.getConceptChangeSuggestions?.result) {
            const list1 = data1.data.getConceptChangeSuggestions.result;
            getConceptChangeSuggestions({
              variables: {
                handled: false,
              },
            })
              .then((data2: any) => {
                if (data2?.data?.getConceptChangeSuggestions?.result) {
                  const list2 = data2.data.getConceptChangeSuggestions.result;
                  setSuggestionInbox(list1.concat(list2));
                }
              })
              .catch((err: Error) => {
                setError(true);
              })
              .finally(() => {
                setLoading(false);
                setDisabledPanel(false);
              });
          } else setError(true);
        })
        .catch((err: Error) => {
          setError(true);
          setLoading(false);
          setDisabledPanel(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionFilter]);

  useEffect(() => {
    if (suggestionSearchString && suggestionSearchString !== " ") {
      suggestionInbox &&
        setSearchResult(
          suggestionInbox.filter((suggestion: any) =>
            suggestion.concept?.title
              .toLowerCase()
              .includes(suggestionSearchString.toLowerCase())
          )
        );
    } else {
      setSearchResult(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionSearchString]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflow: "auto",
        }}
      >
        <List
          id="suggestion-inbox"
          className={
            selectedSuggestion
              ? `${classes.list} ${classes.bgWhite} w3-animate-opacity`
              : `${classes.list} ${classes.bgTrans} w3-animate-opacity`
          }
        >
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 4,
                width: "100%",
              }}
            >
              <CircularProgress size="2em" />
            </Box>
          )}
          {error && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                width: "100%",
              }}
            >
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Make sure that you are using the correct dataset
              </Alert>
            </Box>
          )}
          {suggestionInbox &&
            chunkArray(
              sortByDate(searchResult || suggestionInbox),
              pageChunkSize
            ) &&
            chunkArray(
              sortByDate(searchResult || suggestionInbox),
              pageChunkSize
            )[pageNumber] &&
            chunkArray(
              sortByDate(searchResult || suggestionInbox),
              pageChunkSize
            )[pageNumber].map((data: any, index: number) => (
              <ListItemCustomized
                key={index}
                disabled={disabledInboxItems}
                selected={selectedItemIndex === index}
                onClick={() => {
                  setCurrentConcept(null);
                  if (selectedSuggestion && selectedItemIndex === index) {
                    setSelectedItemIndex(null);
                    setSelectedSuggestion(null);
                  } else {
                    setSelectedItemIndex(index);
                    setSelectedSuggestion(data);
                  }
                }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <Chip
                            variant="filled"
                            size="small"
                            label={
                              data.createNewRequested
                                ? "Create"
                                : data.deleteRequested
                                ? "Delete"
                                : "Edit"
                            }
                            color={
                              data.createNewRequested
                                ? "success"
                                : data.deleteRequested
                                ? "secondary"
                                : "primary"
                            }
                            sx={{ fontWeight: 700 }}
                          />
                          <Box component="span" ml={1.5}>
                            {data.handled && (
                              <CheckCircle htmlColor="#56E39F" />
                            )}
                          </Box>
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {getTagIcon(data.concept.type, "medium")}

                          <Typography
                            component="span"
                            variant="inherit"
                            color="inherit"
                            className={classes.fontBold}
                          >
                            {data.concept.title}
                          </Typography>
                        </Box>
                      </Fragment>
                    }
                    secondary={
                      <Fragment>
                        <Box component="span" mt={1}>
                          <Typography
                            component="span"
                            variant="body1"
                            color="inherit"
                            sx={{
                              display: "block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data.reasonForChange}
                          </Typography>
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                          }}
                        >
                          <Typography component="span" variant="subtitle2">
                            {data.reporter}
                          </Typography>
                          <Typography component="span" variant="subtitle2">
                            {moment(data.reportDate).format("llll")}
                          </Typography>
                        </Box>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItemCustomized>
            ))}
        </List>
      </Box>
      <Box
        className={classes.listFooter}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRight: "1px solid rgba(0,0,0,0.125)",
        }}
      >
        {!loading && (
          <Pagination
            count={
              chunkArray(
                sortByDate(searchResult || suggestionInbox),
                pageChunkSize
              ).length
            }
            page={pageNumber + 1}
            size={width > 1700 ? "medium" : "small"}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            color="primary"
            onChange={changePagehandler}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConceptList;

import { useState, createContext, useContext, useRef } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CTX as mainCTX } from "../../utils/ContextStore";

import GET_CONCEPT from "src/mutations/GET_CONCEPT";
import GET_CONNECTED_CATEGORIES from "src/queries/GET_CONNECTED_CATEGORIES";

import ANALYZE_ARTICLE from "../../mutations/analyzeArticle";
import TAG_ARTICLE from "../../mutations/getTagsMutation";
import GET_IMAGES from "../../mutations/getImages";
import GET_DEMO_ARTICLES from "../../queries/getDemoArticles";

export const CTX = createContext(null);

interface Error {
  error: boolean;
  message: string;
}

export default function AutoTaggingContext(props: any) {
  const [genderData, setGenderData] = useState({
    maleScore: 0,
    maleMentions: 0,
    uniqueMaleEntities: 0,
    malePeople: [],
    femaleScore: 0,
    femaleMentions: 0,
    uniqueFemaleEntities: 0,
    femalePeople: [],
    nonbinaryScore: 0,
    nonbinaryMentions: 0,
    uniqueNonbinaryEntities: 0,
    nonbinaryPeople: [],
    unknownScore: 0,
    unknownMentions: 0,
    uniqueUnknownEntities: 0,
    unknownPeople: [],
  });

  const [analyzeArticle] = useMutation(ANALYZE_ARTICLE);
  const [tagArticle] = useMutation(TAG_ARTICLE);
  const [getImages] = useMutation(GET_IMAGES);
  const [getConcept] = useMutation(GET_CONCEPT);
  const [getConnectedCategories] = useLazyQuery(GET_CONNECTED_CATEGORIES);

  const [editorText, setEditorText] = useState("");
  const [genderTagText, setGenderTagText] = useState("");
  const [textEditor, setTextEditor] = useState();
  const [tagQualityAssurance, setTagQualityAssurance] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const [categories, setCategories] = useState<any>([]);
  const [topics, setTopics] = useState<any>([]);
  const [entities, setEntities] = useState<any>([]);

  const [recentConcepts, setRecentConcepts] = useState<any>([]);

  const [articleFormat, setArticleFormat] = useState<string | undefined>(
    "json"
  );

  const [tagSettings, setTagSettings] = useState({
    articleFormat: articleFormat,
    categories: -1,
    topics: -1,
    entities: -1,
  });

  const [channels, setChannels] = useState<string[]>([]);

  const taggingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [hideCategories, setHideCategories] = useState<boolean>(false);
  const [hideTopics, setHideTopics] = useState<boolean>(false);
  const [hideEntities, setHideEntities] = useState<boolean>(false);

  const [editConceptModalShow, setEditConceptModalShow] =
    useState<boolean>(false);
  const [createConceptModalShow, setCreateConceptModalShow] =
    useState<boolean>(false);
  const [createTopicModalShow, setCreateTopicModalShow] =
    useState<boolean>(false);

  const [demoArticles, setDemoArticles] = useState([]);
  const [selectedDemoArticle, setSelectedDemoArticle] =
    useState<any>(undefined);

  const [queueStatisticsTrigger, setQueueStatisticsTrigger] = useState(0);

  const { activeEndpoint }: any = useContext(mainCTX);

  const [tagError, setTagError] = useState<Error>({
    error: false,
    message: "",
  });

  const formatTag = (item: any) => {
    delete item.__typename;
    return {
      ...item,
      weight: item.weight === "" || isNaN(item.weight) ? 1 : item.weight,
    };
  };

  const clearTags = () => {
    setCategories([]);
    setTopics([]);
    setEntities([]);
  };

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  //takes in a tag (string) and tags (list) and return true if tag in list
  const checkDuplicate = (item: any, list: object[]) => {
    return list.some((tag: any) => tag.uuid === item.uuid);
  };

  const addConcept = (item: any) => {
    if (item.type.includes("category")) {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, categories)) {
        setCategories((prev: any) => [formatTag(item), ...prev]);
      }
    } else if (item.type.includes("topic")) {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, topics)) {
        setTopics((prev: any) => [formatTag(item), ...prev]);
      }
    } else {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, entities)) {
        setEntities((prev: any) => [formatTag(item), ...prev]);
      }
    }
  };

  const addConcepts = (concepts: any) => {
    let categoriesToAdd: any = [];
    let topicsToAdd: any = [];
    let entitiesToAdd: any = [];
    concepts.forEach((concept: any) => {
      if (Object.keys(concept).length > 0) {
        if (
          concept.type.includes("category") &&
          !checkDuplicate(concept, categories)
        ) {
          categoriesToAdd.push(formatTag(concept));
        } else if (
          concept.type.includes("topic") &&
          !checkDuplicate(concept, topics)
        ) {
          topicsToAdd.push(formatTag(concept));
        } else {
          if (!checkDuplicate(concept, entities)) {
            entitiesToAdd.push(formatTag(concept));
          }
        }
      }
    });
    setCategories((prev: any) => [...categoriesToAdd, ...prev]);
    setTopics((prev: any) => [...topicsToAdd, ...prev]);
    setEntities((prev: any) => [...entitiesToAdd, ...prev]);
  };

  const getConceptAndRelatedConcepts = async (item: any) => {
    const getConnectedCategoriesRes = await getConnectedCategories({
      variables: {
        input: {
          concept: item,
          sourceType: "customer",
        },
      },
    });
    const connectedCategories =
      getConnectedCategoriesRes.data?.getConnectedCategories;
    const conceptsToAdd = [item];

    let relatedConceptsUuid = item.broader ? [item.broader] : [];

    relatedConceptsUuid = [
      ...relatedConceptsUuid,
      ...connectedCategories.map((category: any) => category.uuid),
    ];

    let relatedConcepts = await Promise.allSettled(
      relatedConceptsUuid.map((concept: any) =>
        getConcept({
          variables: {
            uuid: concept,
          },
        })
      )
    );
    relatedConcepts = relatedConcepts.map((concept: any) => {
      if (
        concept.status === "fulfilled" &&
        concept.value.data.getConcept.result.length > 0
      ) {
        return concept.value.data.getConcept.result[0];
      }
      return null;
    });

    let broaderOfRelatedConceptsUuid: Array<string> = [];
    relatedConcepts.forEach((concept: any) => {
      if (concept?.broader) {
        broaderOfRelatedConceptsUuid.push(concept.broader);
      }
    });

    while (broaderOfRelatedConceptsUuid.length > 0) {
      let broaderOfRelatedConcepts: Array<any> = await Promise.allSettled(
        broaderOfRelatedConceptsUuid.map((concept: any) =>
          getConcept({
            variables: {
              uuid: concept,
            },
          })
        )
      );

      broaderOfRelatedConcepts = broaderOfRelatedConcepts.map(
        (concept: any) => {
          if (
            concept.status === "fulfilled" &&
            concept.value.data.getConcept.result.length > 0
          ) {
            return concept.value.data.getConcept.result[0];
          }
          return null;
        }
      );

      broaderOfRelatedConceptsUuid = [];
      for (const concept of broaderOfRelatedConcepts) {
        if (concept) {
          relatedConcepts.push(concept);
          if (concept?.broader) {
            broaderOfRelatedConceptsUuid.push(concept.broader);
          }
        }
      }
    }

    conceptsToAdd.push(...relatedConcepts);
    return conceptsToAdd;
  };

  const deleteCategory = (item: any) => {
    setCategories(
      categories.filter((category: any) => category.uuid !== item.uuid)
    );
  };

  const deleteTopic = (item: any) => {
    setTopics(topics.filter((topic: any) => topic.uuid !== item.uuid));
  };

  const deleteEntity = (item: any) => {
    setEntities(entities.filter((entity: any) => entity.uuid !== item.uuid));
  };

  const resetGenderData = () => {
    setGenderData({
      maleScore: 0,
      maleMentions: 0,
      uniqueMaleEntities: 0,
      malePeople: [],
      femaleScore: 0,
      femaleMentions: 0,
      uniqueFemaleEntities: 0,
      femalePeople: [],
      nonbinaryScore: 0,
      nonbinaryMentions: 0,
      uniqueNonbinaryEntities: 0,
      nonbinaryPeople: [],
      unknownScore: 0,
      unknownMentions: 0,
      uniqueUnknownEntities: 0,
      unknownPeople: [],
    });
  };

  const updateQueueStatistics = () => {
    setQueueStatisticsTrigger((value: number) => value + 1);
  };

  useQuery(GET_DEMO_ARTICLES, {
    variables: { language: activeEndpoint?.language },
    onCompleted: (data) => {
      if (data?.getDemoArticles.length > 0) {
        setDemoArticles(data.getDemoArticles);
        setSelectedDemoArticle(data.getDemoArticles[0]);
      } else {
        setSelectedDemoArticle(undefined);
      }
    },
    skip: !activeEndpoint?.isDemoEnvironment,
  });

  return (
    <CTX.Provider
      value={
        {
          activeEndpoint,

          categories,
          setCategories,
          topics,
          setTopics,
          entities,
          setEntities,
          recentConcepts,
          setRecentConcepts,

          tagArticle,
          analyzeArticle,
          getImages,

          genderData,
          setGenderData,
          resetGenderData,

          editorText,
          setEditorText,
          genderTagText,
          setGenderTagText,
          textEditor,
          setTextEditor,

          tagQualityAssurance,
          setTagQualityAssurance,
          tagSettings,
          setTagSettings,
          formatTag,
          clearTags,
          isPlaying,
          setIsPlaying,

          taggingTimeout,
          clearTimeouts,

          hideCategories,
          setHideCategories,
          hideTopics,
          setHideTopics,
          hideEntities,
          setHideEntities,

          editConceptModalShow,
          setEditConceptModalShow,
          createConceptModalShow,
          setCreateConceptModalShow,
          createTopicModalShow,
          setCreateTopicModalShow,

          addConcept,
          addConcepts,
          getConceptAndRelatedConcepts,
          deleteCategory,
          deleteTopic,
          deleteEntity,

          queueStatisticsTrigger,
          updateQueueStatistics,

          demoArticles,
          selectedDemoArticle,
          setSelectedDemoArticle,

          tagError,
          setTagError,

          articleFormat,
          setArticleFormat,
          channels,
          setChannels,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}

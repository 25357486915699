import { useState } from "react";
import { getTagIcon } from "src/utils/helpers";
import { Typography, Popover, Box, Alert } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const relevanceInfo = [
  {
    content: "90 – 100% Relevance",
    bgcolor: "rgba(87, 227, 158, 0.25)",
  },
  {
    content: "80 –  89% Relevance",
    bgcolor: "rgba(177, 227, 87, 0.25)",
  },
  {
    content: "70 –  79% Relevance",
    bgcolor: "rgb(255, 237, 115, 0.25)",
  },
  {
    content: "60 –  69% Relevance",
    bgcolor: "rgb(255, 173, 117, 0.25)",
  },
  {
    content: "   <  59% Relevance",
    bgcolor: "rgba(255, 133, 117, 0.25)",
  },
];

const entityInfo = [
  { label: "person", type: "person" },
  { label: "place", type: "place" },
  { label: "organisation", type: "organisation" },
  { label: "event", type: "event" },
  { label: "object", type: "object" },
];

const TagInfo = ({ type }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box component="span" sx={{ mr: 0.5, float: "right" }}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlined className="interactive" sx={{ ml: 0.5 }} />
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              maxWidth: 240,
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
        sx={{
          pointerEvents: "none",
        }}
      >
        <Alert severity="info" sx={{ display: "block", px: 1, py: 0 }}>
          <pre style={{ margin: 0, whiteSpace: "wrap" }}>
            {type === "entity" ? (
              <>
                <Box
                  sx={{
                    mb: 2,
                    px: 0.5,
                  }}
                >
                  The weight is based on the number of mentions for entities.
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    px: 0.5,
                  }}
                >
                  The entity types are represented by the following icons:
                </Box>
                {entityInfo.map((item) => (
                  <Box
                    key={item.label}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                      gap: 1.5,
                    }}
                  >
                    {getTagIcon(item.type)} {item.label}
                  </Box>
                ))}
              </>
            ) : (
              <pre style={{ margin: 0, whiteSpace: "wrap" }}>
                <Box
                  sx={{
                    mb: 1,
                    px: 0.5,
                  }}
                >
                  The relevance based on the weight of the tags are represented
                  by the following colors:
                </Box>
                {relevanceInfo.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      py: 0.5,
                      px: 1,
                      bgcolor: item.bgcolor,
                    }}
                  >
                    {item.content}
                  </Box>
                ))}
              </pre>
            )}
          </pre>
        </Alert>
      </Popover>
    </Box>
  );
};

export default TagInfo;

import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Male, Female, QuestionMark } from "@mui/icons-material";

import Nonbinary from "./icons/nonbinary.svg";

const useStyles = makeStyles(() =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
  })
);

interface Gender {
  value: string;
  label: string;
  icon: any;
}

const availableGender: Gender[] = [
  {
    value: "",
    label: "",
    icon: null,
  },
  { value: "male", label: "Male", icon: <Male fontSize="medium" /> },
  {
    value: "female",
    label: "Female",
    icon: <Female fontSize="medium" />,
  },
  {
    value: "non-binary",
    label: "Non-binary",
    icon: (
      <img src={Nonbinary} alt="non-binary" height="18.75px" width="18.75px" />
    ),
  },
  {
    value: "unknown",
    label: "Unknown",
    icon: <QuestionMark fontSize="small" />,
  },
];

interface Props {
  type: string | undefined | null;
  gender: string;
  setGender: (gender: string) => void;
  className: any;
}

const GenderInput = (props: Props) => {
  const classes = useStyles();
  const { gender, setGender, className } = props;

  const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(event.target.value);
  };

  return (
    <TextField
      id="gender-selector"
      autoComplete="off"
      variant="outlined"
      fullWidth
      size="small"
      select
      label="Gender (required)"
      value={gender}
      onChange={handleChangeGender}
      InputLabelProps={{
        shrink: true,
      }}
      className={className}
      InputProps={{
        classes: {
          input: classes.bold,
        },
      }}
      error={!gender}
      helperText={gender ? null : "Select a gender"}
    >
      {availableGender.map((option: Gender) => (
        <MenuItem
          disabled={!option.value ? true : false}
          key={option.value}
          value={option.value}
        >
          {option.icon} {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default GenderInput;

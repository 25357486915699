import React, { useEffect, useState, useContext } from "react";
import { CTX } from "./TagContext";

import { Box, Typography, Popover, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Male, Female } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    genderBar: {
      display: "flex",
      justifyContent: "space-between",
      height: "29.6px",
      backgroundColor: "#fff",
      boxSizing: "border-box",
      flexWrap: "nowrap",
      borderRadius: "0.2em",
      border: "0.8px solid rgba(0, 0, 0, 0.125)",
      overflow: "hidden",
      transition: "all .5s linear",
      cursor: "pointer",
    },
    femaleBar: {
      borderRadius: "0.2em 0 0 0.2em",
      backgroundColor: "#ddd",
      transition: "all .5s linear",
      width: "0px",
      fontWeight: 700,
    },
    maleBar: {
      backgroundColor: "#eee",
      borderRadius: "0 0.2em 0.2em 0",
      transition: "all .5s linear",
      width: "0px",
      fontWeight: 700,
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      maxWidth: "33%",
      minWidth: "300px",
      padding: theme.spacing(2),
    },
  })
);

const GenderDisplayer = () => {
  const classes = useStyles();
  const { genderData, resetGenderData, editorText }: any = useContext(CTX);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updateDisplay = () => {
    let femaleBar: any = document.getElementById("female-bar");
    let maleBar: any = document.getElementById("male-bar");
    const totalScore = genderData.maleScore + genderData.femaleScore;
    const { femaleProportion, maleProportion } =
      totalScore > 0
        ? (() => {
            let rawFemale = (genderData.femaleScore / totalScore) * 100;
            let rawMale = (genderData.maleScore / totalScore) * 100;
            let female = Math.round(rawFemale);
            let male = Math.round(rawMale);
            const total = female + male;
            if (total !== 100) {
              female > male ? (female -= total - 100) : (male -= total - 100);
            }
            return { femaleProportion: female, maleProportion: male };
          })()
        : { femaleProportion: 0, maleProportion: 0 };

    femaleBar.style.width = femaleProportion + "%";
    femaleBar.innerHTML = femaleProportion >= 3 ? femaleProportion + "%" : "";
    maleBar.style.width = maleProportion + "%";
    maleBar.innerHTML = maleProportion >= 3 ? maleProportion + "%" : "";
  };

  useEffect(updateDisplay, [genderData]);

  useEffect(() => {
    if (!editorText) {
      resetGenderData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorText]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="overline"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <Female />
            Women:
            <span style={{ fontWeight: 700 }}>
              {genderData.uniqueFemaleEntities}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="overline"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <Male /> Men:
            <span style={{ fontWeight: 700 }}>
              {genderData.uniqueMaleEntities}
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        className={classes.genderBar}
        aria-owns={openPopover ? "gender-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box
          id="female-bar"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.femaleBar}
        ></Box>
        <Box
          id="male-bar"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.maleBar}
        ></Box>
      </Box>
      <Popover
        id="gender-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="overline">Female mentions: </Typography>
          <span className="font-weight-bold">{genderData.femaleScore}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="overline">Male mentions: </Typography>
          <span className="font-weight-bold">{genderData.maleScore}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="overline">Non-binary mentions: </Typography>
          <span className="font-weight-bold">{genderData.nonbinaryScore}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="overline">Unknown mentions: </Typography>
          <span className="font-weight-bold">{genderData.unknownScore}</span>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderTop="1px solid rgba(0,0,0,0.125)"
        >
          <Typography variant="overline">Total: </Typography>
          <span style={{ fontWeight: 700 }}>
            {genderData.femaleScore +
              genderData.maleScore +
              genderData.nonbinaryScore +
              genderData.unknownScore}
          </span>
        </Box>
        <Box borderTop="1px solid rgba(0,0,0,0.125)">
          {genderData.malePeople?.length > 0 && (
            <Box my={0.5}>
              <Typography variant="overline">Male persons:</Typography>
              <Box mt={-0.5} fontSize="80%" fontWeight={700}>
                {genderData.malePeople?.join(", ")}
              </Box>
            </Box>
          )}
          {genderData.femalePeople?.length > 0 && (
            <Box my={0.5}>
              <Typography variant="overline">Female persons:</Typography>
              <Box mt={-0.5} fontSize="80%" fontWeight={700}>
                {genderData.femalePeople?.join(", ")}
              </Box>
            </Box>
          )}
          {genderData.nonbinaryPeople?.length > 0 && (
            <Box my={0.5}>
              <Typography variant="overline">Non-binary persons:</Typography>
              <Box mt={-0.5} fontSize="80%" fontWeight={700}>
                {genderData.nonbinaryPeople?.join(", ")}
              </Box>
            </Box>
          )}
          {genderData.unknownPeople?.length > 0 && (
            <Box my={0.5}>
              <Typography variant="overline">Unknown persons:</Typography>
              <Box mt={-0.5} fontSize="80%" fontWeight={700}>
                {genderData.unknownPeople?.join(", ")}
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default GenderDisplayer;

import gql from "graphql-tag";
const searchArticles = gql`
  mutation ($searchString: String!, $returnSize: Int) {
    searchArticlesMutation(query: $searchString, returnSize: $returnSize) {
      uuid
      headline
      preamble
      body
      channels
      dateline
      groupAndGroupCluster
      concepts {
        title
        type
        uuid
      }
    }
  }
`;

export default searchArticles;

import { useState, useContext } from "react";
import { getTagIcon } from "src/utils/helpers";
import { useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";

import {
  Check,
  Clear,
  Close,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  Switch,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import EDIT_CONCEPT from "../../mutations/EDIT_CONCEPT";
import DELETE_CONCEPT from "../../mutations/DELETE_CONCEPT";

interface IProps {
  open: boolean;
  concepts: any[];
  onClose: () => void;
  onRemove?: (concept: any) => void;
  onClear?: () => void;
}

const BulkEditModal = ({ concepts, onRemove, onClear, ...rest }: IProps) => {
  const {
    superAdminMode,
    isExtendedAdmin,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
  }: any = useContext(CTX);

  const [editConcept] = useMutation(EDIT_CONCEPT);
  const [deleteConcept] = useMutation(DELETE_CONCEPT);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  interface IStatus {
    pubStatus: boolean;
    ignoreStatus: boolean;
  }

  const [enabledStatus, setEnabledStatus] = useState<IStatus>({
    pubStatus: false,
    ignoreStatus: false,
  });
  const [newStatus, setNewStatus] = useState<IStatus>({
    pubStatus: concepts.some((c) => c.pubStatus === false) ? false : true,
    ignoreStatus: concepts.some((c) => c.ignore === false) ? false : true,
  });

  const handleSubmit = () => {
    setShowSnackbar(false);
    setIsLoading(true);

    const reqs = concepts.map((concept) => {
      let param: any = {
        superAdmin: superAdminMode,
        extendedAdmin: isExtendedAdmin,
        uuid: concept.uuid,
        title: concept.title,
        type: concept.type,
        shortDescription: concept.shortDescription,
        global: concept.global,
        language: concept.language,
      };
      if (enabledStatus.pubStatus) {
        param.pubStatus = newStatus.pubStatus;
        concept.pubStatus = newStatus.pubStatus;
      }
      if (enabledStatus.ignoreStatus) {
        param.ignore = newStatus.ignoreStatus;
        concept.ignore = newStatus.ignoreStatus;
      }
      return editConcept({
        variables: param,
      });
    });

    Promise.all(reqs)
      .then((data: any) => {
        setSnackbarMessage("Successfully edited concepts.");
        setSnackbarError(false);
        setShowSnackbar(true);
        rest.onClose();
      })
      .catch((e) => {
        setSnackbarMessage(e);
        setSnackbarError(true);
        setShowSnackbar(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    setShowSnackbar(false);
    setIsLoading(true);
    const reqs = concepts.map((concept) => {
      let param: any = {
        superAdmin: superAdminMode,
        extendedAdmin: isExtendedAdmin,
        uuid: concept.uuid,
        title: concept.title,
        type: concept.type,
        shortDescription: concept.shortDescription,
        global: concept.global,
        language: concept.language,
      };
      return deleteConcept({
        variables: param,
      });
    });

    Promise.all(reqs)
      .then((data: any) => {
        setShowSnackbar(true);
        setSnackbarError(false);
        setSnackbarMessage("Successfully deleted concepts");
        concepts.forEach((c) => onRemove && onRemove(c));
        rest.onClose();
        let inputField = document.getElementById("conceptSearchInput");
        setTimeout(() => {
          if (inputField) {
            inputField.dispatchEvent(new Event("input", { bubbles: true }));
          }
        }, 250);
      })
      .catch(() => {
        setShowSnackbar(true);
        setSnackbarError(true);
        setSnackbarMessage("Failed to delete concepts");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" {...rest}>
      <DialogTitle>Edit concepts</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <Typography
          sx={{ pb: 1, gap: 0.5, display: "flex", alignItems: "center" }}
        >
          {concepts.length} selected
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              onClear && onClear();
              rest.onClose();
            }}
          >
            <Clear />
          </IconButton>
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <List
              sx={{
                overflowX: "hidden",
                overflowY: "auto",
                height: "400px",
                maxHeight: "400px",
                borderRight: "1px solid rgba(0,0,0,0.125)",
              }}
            >
              {concepts.map((concept) => (
                <ListItem
                  key={concept.uuid}
                  sx={{
                    py: 0.5,
                    px: 0,
                    alignItems: "center",
                    gap: 1,
                    pr: 0.5,
                  }}
                >
                  {getTagIcon(concept.type)}
                  <Tooltip
                    title={`${concept.title}${
                      concept.shortDescription &&
                      " - " + concept.shortDescription
                    }`}
                    placement="bottom-start"
                  >
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: 700 }}>{concept.title}</span>
                      {concept.shortDescription && (
                        <span> - {concept.shortDescription}</span>
                      )}
                    </Typography>
                  </Tooltip>
                  {onRemove && (
                    <Tooltip
                      title={`Deselect ${concept.title}`}
                      placement="bottom"
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          onRemove(concept);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} sx={{ px: 2, py: 1 }}>
            <Box sx={{ display: "flex", gap: 2, pb: 2 }}>
              <Checkbox
                checked={enabledStatus.pubStatus}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEnabledStatus({ ...enabledStatus, pubStatus: true });
                  } else {
                    setEnabledStatus({ ...enabledStatus, pubStatus: false });
                  }
                }}
                sx={{ p: 0 }}
              />
              <TextField
                disabled={!enabledStatus.pubStatus}
                autoComplete="off"
                label="Publication status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                value={newStatus.pubStatus ? "On" : "Off"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={newStatus.pubStatus ? "Toggle off" : "Toggle on"}
                        placement="top"
                      >
                        <Switch
                          edge="end"
                          disabled={!enabledStatus.pubStatus}
                          checked={newStatus.pubStatus}
                          onChange={() => {
                            setNewStatus({
                              ...newStatus,
                              pubStatus: !newStatus.pubStatus,
                            });
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2, pb: 2 }}>
              <Checkbox
                checked={enabledStatus.ignoreStatus}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEnabledStatus({ ...enabledStatus, ignoreStatus: true });
                  } else {
                    setEnabledStatus({ ...enabledStatus, ignoreStatus: false });
                  }
                }}
                sx={{ p: 0 }}
              />
              <TextField
                disabled={!enabledStatus.ignoreStatus}
                autoComplete="off"
                label="Ignore in autotagging"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                value={newStatus.ignoreStatus ? "On" : "Off"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          newStatus.ignoreStatus ? "Toggle off" : "Toggle on"
                        }
                        placement="top"
                      >
                        <Switch
                          edge="end"
                          disabled={!enabledStatus.ignoreStatus}
                          checked={newStatus.ignoreStatus}
                          onChange={() => {
                            setNewStatus({
                              ...newStatus,
                              ignoreStatus: !newStatus.ignoreStatus,
                            });
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            disabled={confirmDelete}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setConfirmDelete(true);
            }}
            sx={{ gap: 1 }}
          >
            <Delete />
            Delete selected ({concepts.length})
          </Button>
          {confirmDelete && (
            <Button
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              sx={{ gap: 1 }}
            >
              <DeleteForever />
              Confirm
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {isLoading && <CircularProgress size="1.5em" />}
          <Button
            disabled={isLoading}
            variant="contained"
            color="secondary"
            onClick={rest.onClose}
            sx={{ gap: 1 }}
          >
            <Close /> Close
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ gap: 1 }}
          >
            <Check /> Save changes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BulkEditModal;

import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Alert, Box, CircularProgress } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";

import GET_CONCEPT_USAGE_OVER_TIME from "src/queries/GET_CONCEPT_USAGE_OVER_TIME";

interface IProps {
  uuid: string;
  startDate: Date;
  endDate: Date;
}

const ConceptsOverTimeBarChart = (props: IProps) => {
  const { uuid, startDate, endDate } = props;

  const [getConceptUsageOverTime, { loading, error }] = useLazyQuery(
    GET_CONCEPT_USAGE_OVER_TIME,
    {
      variables: {
        query: {
          uuid: uuid,
          startDate: startDate,
          endDate: endDate,
        },
      },
      onCompleted: (data) => {
        data = data.getConceptUsageOverTime;
        const { uuid, title, type, usage, result } = data;
        const conceptUsageOverTime = result?.map((d: any) => ({
          date: d.date,
          [title]: d.usage ?? 0,
        }));
        setData({ uuid, title, type, usage, conceptUsageOverTime });
      },
      onError: (err) => {},
    }
  );

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (startDate && endDate) getConceptUsageOverTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <Box
        sx={{ p: 4, display: "flex", width: "100%", justifyContent: "center" }}
      >
        <Alert severity="error">Failed to load Concept usage chart</Alert>
      </Box>
    );

  if (loading)
    return (
      <Box
        sx={{ p: 4, display: "flex", width: "100%", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <ResponsiveBar
      data={data.conceptUsageOverTime}
      keys={
        data.conceptUsageOverTime?.length > 0
          ? Object.keys(data.conceptUsageOverTime[0]).filter(
              (key) => key !== "date"
            )
          : []
      }
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
      padding={0.05}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "set2" }}
      layout={"vertical"}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 40,
        legend: "Date",
        legendPosition: "middle",
        legendOffset: 60,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Usage",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
    />
  );
};

export default ConceptsOverTimeBarChart;

import { useState, useEffect, useRef, useMemo, useContext } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { CTX } from "src/utils/ContextStore";
import { getTagIcon, useCurrentHeight } from "src/utils/helpers";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
  LinearProgress,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Alert,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import { Search, Close, FilterAlt } from "@mui/icons-material";

import DatasetPicker from "src/components/PanelComponents/DatasetPicker";
import CMConceptModal from "src/components/ConceptManagement/CMConceptModal";
import ExportTsvButton from "src/components/ConceptManagement/ConceptExplorer/ExportTsvButton";

import GET_TAXONOMY from "src/queries/GET_TAXONOMY";

const TaxonomyExplorer = () => {
  const classes = useStyles();
  const { activeEndpoint } = useContext(CTX);

  const typeOptions = ["category", "topic"];

  const headCells: IHeadCell[] = [
    {
      id: "title",
      label: "Title",
      numeric: false,
      width: undefined,
      sx: { fontWeight: 700 },
    },
    {
      id: "type",
      label: "Type",
      numeric: false,
      width: undefined,
      sx: { fontWeight: 700 },
    },
    {
      id: "uuid",
      label: "UUID",
      numeric: false,
      width: "38ch",
      sx: { fontWeight: 700 },
    },
    {
      id: "shortDescription",
      label: "Short Description",
      numeric: false,
      width: "40ch",
    },
    {
      id: "broader",
      label: "Broader",
      numeric: false,
      width: "35ch",
    },
  ];

  const [data, setData] = useState<any[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IData>("title");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(250);
  const [searchString, setSearchString] = useState<string>("");
  const [filterType, setFilterType] = useState<Array<string>>([]);
  const [selectedConcept, setSelectedConcept] = useState<any>(null);

  const filteredDataLength = useRef<number>(0);

  const [getTaxonomy, { loading, error }] = useLazyQuery(GET_TAXONOMY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getTaxonomy) {
        const joinedData = data.getTaxonomy.categories.concat(
          data.getTaxonomy.topics
        );
        setData(joinedData);
      }
    },
    onError: (err) => {},
  });

  const visibleRows = useMemo(() => {
    let rowsJSON: Array<any> = [];
    if (searchString || filterType.length > 0) {
      rowsJSON = data.filter(
        (row: any) =>
          (row.title?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.uuid?.includes(searchString) ||
            row.broader?.includes(searchString)) &&
          (filterType.length < 1 || filterType.includes(row.type))
      );
    } else {
      rowsJSON = data.filter((row: any) => row.title);
    }
    filteredDataLength.current = rowsJSON.length;
    const sortedData = stableSort(rowsJSON, getComparator(order, orderBy));
    if (loading) {
      //exportData.current = sortedData;
    }
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchString,
    filterType,
    loading,
  ]);

  const onTypeFilter = (types: string[]) => {
    setFilterType(types);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const onClearSearch = () => {
    setSearchString("");
  };

  const handleChangePage: any = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const table = document.getElementById("taxonomy-explorer-table");
    if (table) table.scrollTop = 0;
  }, [page]);

  useEffect(() => {
    setData([]);
    setPage(0);
    getTaxonomy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1.5,
          gap: 1,
          borderBottom: "1px solid rgba(0,0,0,0.125)",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <ExportTsvButton
            disabled={loading || data.length < 1}
            data={data}
            fileName={`${moment().format("YYYYMMDD")}_${
              activeEndpoint?.settingsName
            }_taxonomy`}
            isFetchingExportData={isExporting}
            setIsFetchingExportData={setIsExporting}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <DatasetPicker />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{ display: "flex", width: "100%", alignItems: "center", gap: 2 }}
        >
          <TextField
            disabled={loading}
            autoComplete="off"
            size="small"
            label={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",

                  gap: 0.5,
                }}
              >
                <Search /> Search
              </Box>
            }
            value={searchString}
            onChange={onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: searchString ? "visible" : "hidden",
                    }}
                    size="small"
                    onClick={onClearSearch}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "20%" }}
          />

          <Autocomplete
            disabled={loading}
            multiple
            limitTags={2}
            size="small"
            options={typeOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            ChipProps={{ sx: { fontWeight: 700 } }}
            ListboxProps={{
              sx: { paddingTop: "0px", paddingBottom: "0px" },
            }}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} sx={{ fontWeight: 700, gap: 1 }}>
                {getTagIcon(option)}
                {option}
              </MenuItem>
            )}
            sx={{ width: "24%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                label={
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <FilterAlt /> Type filter
                  </Box>
                }
              />
            )}
            onChange={(e, val) => {
              onTypeFilter(val);
            }}
          />
        </Box>

        <CMConceptModal
          show={selectedConcept}
          onHide={() => setSelectedConcept(null)}
          concept={selectedConcept}
        />

        <Paper
          elevation={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {loading ? (
            <LinearProgress />
          ) : error ? (
            <Alert severity="error">{error.message}</Alert>
          ) : null}

          <TableContainer
            id="taxonomy-explorer-table"
            sx={{
              position: "relative",
              overflowY: "auto",
              height: `${useCurrentHeight() - 262}px`,
            }}
          >
            <Table stickyHeader size="small">
              <EnhancedTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {visibleRows.map((row: any, i: number) => (
                  <StyledTableRow
                    key={i}
                    hover
                    tabIndex={-1}
                    onClick={() => {
                      setSelectedConcept({ ...row });
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    {headCells.map((cell: IHeadCell, i: number) => {
                      const key: any = cell.id;
                      const width = cell.width || "auto";

                      return (
                        <StyledTableCell key={i} width={width}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: cell.numeric ? "right" : "left",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              gap: 1.5,
                            }}
                          >
                            <Tooltip
                              disableInteractive
                              title={
                                key === "shortDescription"
                                  ? row[key]
                                  : undefined
                              }
                              arrow
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -8],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  maxWidth: width,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  ...cell.sx,
                                }}
                              >
                                {key === "type" &&
                                  getTagIcon(row[key], undefined, undefined, {
                                    mr: 0.5,
                                  })}
                                {row[key]}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[250, 500, 1000]}
            count={filteredDataLength.current}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            sx={{
              width: "100%",
              overflowX: "hidden",
              borderTop: "1px solid rgba(0, 0, 0, 0.125)",
            }}
            classes={{
              displayedRows: classes.paginationLabel,
              selectLabel: classes.paginationLabel,
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  paginationLabel: { marginBottom: 0 },
});

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E8EEF2",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IData {
  title: string;
  type: string;
  shortDescription: string;
  broader: string;
  uuid: string;
}

interface IHeadCell {
  id: keyof IData;
  label: string;
  numeric: boolean;
  width?: string;
  sx?: any;
}

interface EnhancedTableProps {
  headCells: readonly IHeadCell[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  order: Order;
  orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: "#DCDCDD",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 700,
              backgroundColor: "#DCDCDD",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TaxonomyExplorer;

import {
  TextField,
  InputAdornment,
  Switch,
  ListItem,
  Tooltip,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles, withStyles } from "@mui/styles";

const TypeSwitch = withStyles({
  switchBase: {
    color: "#DCDCDD",
    "&$checked": {
      color: "#56E39F",
    },
    "&$checked + $track": {
      backgroundColor: "RGBA(87, 227, 158, 0.8)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    inactiveType: {
      color: "#fff",
      backgroundColor: "#DCDCDD",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    activeType: {
      color: "#fff",
      backgroundColor: "#56E39F",
      cursor: "pointer",
      marginLeft: "6px",
      height: "36px",
      width: "36px",
      "&:hover": {
        opacity: 0.8,
      },
    },
    listRoot: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    buttonIcon: {
      marginLeft: "4px",
    },
    typePanel: {
      display: "flex",
    },
    aliasChip: {
      height: "2em",
      borderRadius: "1em",
    },
  })
);

const BooleanSwitch = (props: any) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.root}>
      <TextField
        autoComplete="off"
        disabled={props.viewOnly}
        label={<span className="font-weight-bold">{props.label}</span>}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        size="small"
        value={props.value ? "On" : "Off"}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={props.value ? "Toggle off" : "Toggle on"}
                placement="top"
              >
                <TypeSwitch
                  disabled={props.viewOnly}
                  edge="end"
                  checked={props.value}
                  onChange={() => {
                    props.setChange(!props.value);
                  }}
                />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </ListItem>
  );
};

export default BooleanSwitch;

import gql from "graphql-tag";

const DELETE_CONCEPT = gql`
  mutation (
    $superAdmin: Boolean
    $extendedAdmin: Boolean
    $uuid: String!
    $global: Boolean
    $language: String
  ) {
    deleteConcept(
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
      uuid: $uuid
      global: $global
      language: $language
    ) {
      response
      error
    }
  }
`;

export default DELETE_CONCEPT;
